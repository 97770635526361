import React, { useState, useEffect, useContext, useRef } from "react";

//MUI componets
import { Grid, Tooltip, Button, CircularProgress, Radio } from "@material-ui/core/";

//Custom Portal Components
import SearchBox from "../common/SearchBox";
import ErrorInFetch from "../common/ErrorInFetch";
import EntitiesTableComponent from "./EntitiesTableComponent";
import EntitiesModal from "./Modals/EntitiesModal";
import CreateNewEntityModal from "./Modals/CreateNewEntityModal";
import AppContext from "../../Context/AppContext";
import { actionsPLNManagement } from "../DataFunctions";
import RefreshButton from "../common/RefreshButton";
import SearchNoResults from "../common/SearchNoResults";

//Icons
import AgregarIcono from "../../Icons/AgregarIcono";
import { ReactComponent as TrashCan } from "../../Icons/trashcanIcon.svg";
import EditarIcono from "../../Icons/EditarIcono";

//Styles
import "./styles/EditEntitiesTable.css";

//External libraries
import Swal from "sweetalert2";

const EditEntitiesTable = (props) => {
    const [selectedEntity, setSelectedEntity] = useState("");
    const [openEntitiesModal, setOpenEntitiesModal] = useState(false);
    const [createNewEntity, setCreateNewEntity] = useState(false);
    const [currentEntity, setCurrentEntity] = useState({});
    const [searchQuery, setSearchQuery] = useState("");
    const [searchHasNoResults, setSearchHasNoResults] = useState(false);

    //ref
    const initialEnitities = useRef([]);

    //Context
    const { updateContextAttribute, getStorageData } = useContext(AppContext);

    const defaultNewEntity = useRef({
        name: "NUEVO",
        entityType: "Entity Extractor",
    });

    const storageData = getStorageData(["exclusiveServices", "appId"]);

    const {
        entityList,
        refreshPLNData,
        loading,
        errorInFetch,
        entityListNames,
        setEntityList,
        appName,
        PLNtakenNamesList,
    } = props;

    useEffect(() => {
        getSelectedEntity(selectedEntity);
        initialEnitities.current = entityList;
    }, [selectedEntity]);

    const refreshFunction = () => {
        clearState();
        refreshPLNData();
    };

    const clearState = () => {
        setSelectedEntity("");
        setOpenEntitiesModal(false);
        setCreateNewEntity(false);
        setCurrentEntity({});
        setSearchQuery("");
        setSearchHasNoResults(false);
    };

    const getSelectedEntity = (selectedEntity) => {
        let currentEntity = defaultNewEntity.current;
        if (selectedEntity) {
            currentEntity = entityList.find((entity) => entity.name === selectedEntity);
        }
        setCurrentEntity(currentEntity);
    };

    const saveNewEntity = async (entityName, entityType, regexPattern) => {
        let alert = await Swal.fire({
            text: "¿Deseas guardar los cambios?",
            showDenyButton: true,
            confirmButtonText: "Si",
            denyButtonText: `No`,
            confirmButtonColor: "#27315d",
            denyButtonColor: "#27315d",
        });
        //Cancelamos el eliminado del usuario
        if (alert.isDenied || alert.isDismissed) {
            return;
        }
        updateContextAttribute("loadingDialog", true);
        updateContextAttribute("LoadingMessage", "Creando entidad...");
        let data = {};
        let apiFunction = "";
        switch (entityType) {
            case "Entity Extractor":
                data = {
                    entityName: entityName,
                };
                apiFunction = "createEntity";
                break;
            case "Regex Entity Extractor":
                data = {
                    entityName: entityName,
                    regex: regexPattern,
                };
                apiFunction = "createEntityRegex";
                break;
            case "Closed List Entity Extractor":
                data = {
                    entityName: entityName,
                    subList: [],
                };
                apiFunction = "createEntityClosedList";
                break;
            default:
                let alertError = {
                    open: true,
                    severity: "error", //success, error, warning, info
                    message: "Ocurrió un error al guardar los datos",
                };
                updateContextAttribute("alert", alertError);
                return;
        }
        if (storageData.exclusiveServices.processNaturalLenguage && storageData.appId) {
            data["appID"] = storageData.appId;
        }
        let res = await actionsPLNManagement(data, apiFunction);
        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
        switch (responseCode) {
            case 0:
                let alertSuccess = {
                    open: true,
                    severity: "success", //success, error, warning, info
                    message: `La entidad fue agregada correctamente.`,
                };
                updateContextAttribute("alert", alertSuccess);
                refreshFunction();
                setCreateNewEntity(false);
                break;

            default:
                let alertError = {
                    open: true,
                    severity: "error", //success, error, warning, info
                    message: "Ocurrió un error al guardar los datos",
                };
                updateContextAttribute("alert", alertError);
                break;
        }
        updateContextAttribute("loadingDialog", false);
        updateContextAttribute("LoadingMessage", "");
    };

    const deleteEntityFather = async (entityType) => {
        let alert = await Swal.fire({
            text: "¿Deseas eliminar esta entidad?",
            showDenyButton: true,
            confirmButtonText: "Si",
            denyButtonText: `No`,
            confirmButtonColor: "#27315d",
            denyButtonColor: "#27315d",
        });
        //Cancelamos el eliminado del usuario
        if (alert.isDenied || alert.isDismissed) {
            return;
        }
        let entityId = currentEntity.id;
        let data = {};
        let apiFunction = "";
        switch (entityType) {
            case "Regex Entity Extractor":
                data = {
                    regexEntityId: entityId,
                };
                apiFunction = "deleteEntityRegex ";
                break;
            case "Entity Extractor":
                data = {
                    entityId: entityId,
                };
                apiFunction = "deleteEntity";
                break;
            case "Closed List Entity Extractor":
                data = {
                    clEntityId: entityId,
                };
                apiFunction = "deleteEntityClosedList";
                break;
            default:
                let alertError = {
                    open: true,
                    severity: "error", //success, error, warning, info
                    message: "Ocurrió un error al eliminar los datos",
                };
                updateContextAttribute("alert", alertError);
                return;
        }

        updateContextAttribute("loadingDialog", true);
        updateContextAttribute("LoadingMessage", "Eliminado entidad...");
        if (storageData.exclusiveServices.processNaturalLenguage && storageData.appId) {
            data["appID"] = storageData.appId;
        }
        let res = await actionsPLNManagement(data, apiFunction);
        updateContextAttribute("loadingDialog", false);
        updateContextAttribute("LoadingMessage", "");
        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
        switch (responseCode) {
            case 0:
                let alertSuccess = {
                    open: true,
                    severity: "success", //success, error, warning, info
                    message: `La entidad fue eliminada correctamente.`,
                };
                updateContextAttribute("alert", alertSuccess);
                refreshFunction();
                break;
            default:
                let alertError = {
                    open: true,
                    severity: "error", //success, error, warning, info
                    message: "Ocurrió un error al guardar los datos",
                };
                updateContextAttribute("alert", alertError);
                break;
        }
    };

    const searchEntity = (searchText) => {
        setSearchHasNoResults(false);
        setSearchQuery(searchText);
        if (!searchText) {
            setEntityList(initialEnitities.current);
            return;
        }
        let filteredEntities = entityList.filter((entity) =>
            entity.name.toLowerCase().includes(searchText.toLowerCase())
        );
        setEntityList(filteredEntities);
        if (filteredEntities.length === 0) {
            setSearchHasNoResults(true);
        }
    };

    return (
        <React.Fragment>
            <h1>Entidades {appName ? `- ${appName}` : ""}</h1>
            {/* =============== Modales ============= */}
            <EntitiesModal
                openEntitiesModal={openEntitiesModal}
                setOpenEntitiesModal={setOpenEntitiesModal}
                currentEntity={currentEntity}
                refreshFunction={refreshFunction}
                PLNtakenNamesList={PLNtakenNamesList}
            />
            <CreateNewEntityModal
                setCreateNewEntity={setCreateNewEntity}
                createNewEntity={createNewEntity}
                saveNewEntity={saveNewEntity}
                entityListNames={entityListNames}
                PLNtakenNamesList={PLNtakenNamesList}
            />

            {/* ============== Tabla de entidades ==============  */}
            <Grid container>
                <header className="edit-intents-actions">
                    <Tooltip title="Crear entidad">
                        <Button
                            disabled={loading}
                            onClick={() => {
                                setSelectedEntity("");
                                setCreateNewEntity(true);
                            }}
                            startIcon={<AgregarIcono width="15px" height="15px" />}
                            size="small"
                        >
                            Crear
                        </Button>
                    </Tooltip>
                    {/* <Tooltip title="Editar entidad">
                        <Button 
                            disabled={!Boolean(selectedEntity)}
                            onClick={() => {
                                setOpenEntitiesModal(true)
                                setCreateNewEntity(false)
                            }} 
                            startIcon={<EditarIcono width="20px" height="20px" />} 
                            size="small" 
                        > 
                            Editar 
                        </Button>
                    </Tooltip> */}
                    <Tooltip title="Eliminar entidad">
                        <Button
                            disabled={!selectedEntity}
                            onClick={() => deleteEntityFather(currentEntity.entityType)}
                            startIcon={<TrashCan width="15px" height="15px" />}
                            size="small"
                        >
                            {" "}
                            Eliminar{" "}
                        </Button>
                    </Tooltip>
                    <RefreshButton refreshFunction={refreshFunction} />
                    <SearchBox
                        searchFunction={(searchQuery) => searchEntity(searchQuery)}
                        searchQuery={searchQuery}
                    />
                </header>
                <main className="intents-table">
                    <header className="intents-table-header">
                        <div className="radio-header"></div>
                        <h2 className="entities-name-header">Nombre</h2>
                        <h2 className="entities-type-header">Tipo</h2>
                    </header>
                    <ErrorInFetch errorInFetch={errorInFetch} refreshFunction={refreshFunction} />
                    {loading ? (
                        <div className="center-loading-icon">
                            <CircularProgress />
                        </div>
                    ) : (
                        entityList.map((entity) => (
                            <EntitiesTableComponent
                                {...entity}
                                selectedEntity={selectedEntity}
                                setSelectedEntity={setSelectedEntity}
                            />
                        ))
                    )}
                    {entityList.length === 0 && !loading && (
                        <div className="empty-intents-table"></div>
                    )}
                    {searchHasNoResults && <SearchNoResults searchQuery={searchQuery} />}
                </main>
            </Grid>
        </React.Fragment>
    );
};

export default EditEntitiesTable;
