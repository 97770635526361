import React, { useState, useEffect, useContext, useRef } from "react";

//Custom portal components
import PLNleftMenuBar from "../components/PLNpageComponents/PLNleftMenuBar";
import EditIntentsTable from "../components/PLNpageComponents/EditIntentsTable";
import TestPLNTraining from "../components/PLNpageComponents/TestPLNTraining";
import EditEntitiesTable from "../components/PLNpageComponents/EditEntitiesTable";
import { actionsPLNManagement } from "../components/DataFunctions";
import AppContext from "../Context/AppContext";
import Button from "../components/common/CustomButton";
import ConversationApps from "../components/PLNpageComponents/ConversationApps";
import ReviewEndpointUtterances from "../components/PLNpageComponents/ReviewEndpointUtterances";

//Material UI components
import { Button as MUIButton } from "@material-ui/core/";

//Icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

//Styles
import "./StyledPages/PLNpage.css";

export default function PermanentDrawerLeft() {
    //Context
    const { sleep, updateContextAttribute, getStorageData, matchesUpMd, readOnly } =
        useContext(AppContext);
    let storageData = getStorageData(["appId", "appName", "exclusiveServices", "PLNModule"]);

    //State
    const [PLNComponent, setPLNComponent] = useState("Intenciones");
    const [loading, setLoading] = useState(false);
    const [errorInFetch, setErrorInFetch] = useState(false);
    const [intentsList, setIntentsList] = useState([]);
    const [entityList, setEntityList] = useState([]);
    const [stateAppId, setAppId] = useState(storageData.appId);
    const [appName, setAppName] = useState(
        storageData.appName ?? storageData.PLNModule.defaultAppName
    );
    const [changeAppHasChanged, setChangeAppHasChanged] = useState(false);
    const [tempAppId, setTempAppId] = useState("");
    const [PLNtakenNamesList, setPLNtakenNamesList] = useState([]);
    const [needsTraining, setNeedsTraining] = useState(false);
    const [openPLNmenu, setOpenPLNmenu] = useState(false);

    //wait for the app to load then change name
    const tempAppName = useRef("");

    const isMounted = useRef(true);

    //DidMount
    useEffect(() => {
        isMounted.current = true;
        const remeberAuthCredentials = localStorage.getItem("remeberAuthCredentials");
        if (remeberAuthCredentials) {
            localStorage.setItem("appId", stateAppId);
            localStorage.setItem("appName", appName);
        } else {
            sessionStorage.setItem("appId", stateAppId);
            sessionStorage.setItem("appName", appName);
        }
        refreshPLNData();
        return () => {
            isMounted.current = false;
            document.body.classList.remove("hide-overflow");
        };
    }, [stateAppId, appName]);

    useEffect(() => {
        decideHideOverflow();
    }, [loading, errorInFetch, intentsList, entityList]);

    const clearState = () => {
        setPLNtakenNamesList([]);
    };

    const refreshPLNData = async () => {
        setLoading(true);
        document.body.classList.add("hide-overflow");
        clearState();
        //Si el componente no esta montado cancelamos las operaciones
        if (!isMounted.current) return;
        await sleep(1000);
        //console.time("getIntentsList");
        let intentsList = await getIntentsList();
        //console.timeEnd("getIntentsList");
        await sleep(300);
        //console.time("getEntintyList");
        let entityList = await getEntintyList();
        //console.timeEnd("getEntintyList");
        await sleep(300);
        //console.time("getTrainStatus");
        let needsTraining = await getNeedsTraining();
        //console.timeEnd("getTrainStatus");

        let jobId = localStorage.getItem("jobId") || "";
        if (jobId !== "") {
            let { status, percentComplete } = await getTrainStatus(jobId);
            updateContextAttribute("loadingDialog", true);
            updateContextAttribute(
                "LoadingMessage",
                `Entrenando PLN
                ...entrenamiento puede tardar entre 15 minutos y 1 hora
                `
            );

            //if the status is not succeeded, we will ask for the status every 2 seconds
            while (status !== "succeeded") {
                await sleep(2000);
                status = await getTrainStatus(jobId);
            }
            //Remove the jobId from the storage
            localStorage.removeItem("jobId");
            refreshPLNData();
            let alertSuccess = {
                open: true,
                severity: "success", //success, error, warning, info
                message: "El portal de PLN fue entrenado de manera correcta",
            };
            updateContextAttribute("alert", alertSuccess);
            updateContextAttribute("loadingDialog", false);
            updateContextAttribute("LoadingMessage", "");
        }

        setLoading(false);
        document.body.classList.remove("hide-overflow");

        if (!isMounted.current) return;

        //si alguna de los servicios falló, se muestra un mensaje de error
        if (!intentsList || !entityList) return;

        let intentListNames = intentsList.map((intent) => {
            return { name: intent.intentName, id: intent.intentId };
        });
        let entityListNames = getEntityListNames(entityList);

        //merge intentListNames with entityListNames
        let PLNtakenNamesList = intentListNames.concat(entityListNames);
        setPLNtakenNamesList(PLNtakenNamesList);
        setNeedsTraining(needsTraining);
        setIntentsList(intentsList);
        setEntityList(entityList);

        setErrorInFetch(false);
    };

    const getEntityListNames = (entityList) => {
        let entityListNames = [];
        for (let entity of entityList) {
            const { entityType = "", readableType = "", children = [] } = entity;
            entityListNames.push({ name: entity.name, id: entity.id });
            if (entityType !== "Entity Extractor" && readableType !== "Child Entity Extractor")
                continue;
            if (children.length > 0) {
                entityListNames.push(...getEntityListNames(children));
            }
        }
        return entityListNames;
    };

    const extractNames = (obj) => {
        const names = [obj.name];
        if (obj.children) {
            names.push(...obj.children.flatMap(extractNames));
        }
        return names;
    };

    const getTrainStatus = async (jobId) => {
        if (!isMounted.current) return;
        let data = {
            jobId: jobId,
        };
        if (storageData.exclusiveServices.processNaturalLenguage && stateAppId) {
            data = {
                appID: stateAppId,
            };
        }
        let res = await actionsPLNManagement(data, "trainStatus");
        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
        switch (responseCode) {
            case 0:
                let status = res.data.data.status;
                return status;
            default:
                setErrorInFetch(true);
                return null;
        }
    };

    const getNeedsTraining = async () => {
        if (!isMounted.current) return;
        let data = null;
        if (storageData.exclusiveServices.processNaturalLenguage && stateAppId) {
            data = {
                appID: stateAppId,
            };
        }
        let res = await actionsPLNManagement(data, "getStatus");
        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
        switch (responseCode) {
            case 0:
                let status = res.data.data.trainingStatus;
                return status === "NeedsTraining";
            default:
                setErrorInFetch(true);
                return null;
        }
    };

    const getEntintyList = async () => {
        if (!isMounted.current) return;
        let data = null;
        if (storageData.exclusiveServices.processNaturalLenguage && stateAppId) {
            data = {
                appID: stateAppId,
            };
        }
        let res = await actionsPLNManagement(data, "getEntityList");
        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; //-> si no hay codigo de respuesta lo mandamos al default
        switch (responseCode) {
            case 0:
                let entityListCopy = JSON.parse(JSON.stringify(res.data.data));

                //For each element push a parents = [] and level = 0
                entityListCopy.forEach((entity) => {
                    entity.parents = [];
                    entity.level = 0;
                });

                return entityListCopy;
            default:
                setErrorInFetch(true);
                return null;
        }
    };

    const getIntentsList = async () => {
        if (!isMounted.current) return;
        let data = null;
        if (storageData.exclusiveServices.processNaturalLenguage && stateAppId) {
            data = {
                appID: stateAppId,
            };
        }
        const res = await actionsPLNManagement(data, "getIntentList");
        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; //-> si no hay codigo de respuesta lo mandamos al default
        switch (responseCode) {
            case 0:
                let intentsList = res.data.data;
                return intentsList;
            default:
                setErrorInFetch(true);
                return null;
        }
    };

    const trainPLNpage = async () => {
        updateContextAttribute("loadingDialog", true);
        updateContextAttribute("LoadingMessage", "Entrenado PLN...");
        let data = null;
        if (storageData.exclusiveServices.processNaturalLenguage && stateAppId) {
            data = {
                appID: stateAppId,
            };
        }
        let res = await actionsPLNManagement(data, "train");
        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
        switch (responseCode) {
            case 0:
                //we will ask for the status of the train status every 2 seconds until the status is Success or Error
                let jobId = res.data.data;
                //Save to storage the jobId
                localStorage.setItem("jobId", jobId);
                if (jobId === "InProgress") {
                    jobId = localStorage.getItem("jobId");
                }
                let { status, percentComplete } = await getTrainStatus(jobId);
                updateContextAttribute(
                    "LoadingMessage",
                    `Entrenando PLN
                ...entrenamiento puede tardar entre 15 minutos y 1 hora
                `
                );
                //if the status is not succeeded, we will ask for the status every 2 seconds
                while (status !== "succeeded") {
                    await sleep(2000);
                    status = await getTrainStatus(jobId);
                }
                //Remove the jobId from the storage
                localStorage.removeItem("jobId");
                refreshPLNData();
                let alertSuccess = {
                    open: true,
                    severity: "success", //success, error, warning, info
                    message: "El portal de PLN fue entrenado de manera correcta",
                };
                updateContextAttribute("alert", alertSuccess);
                updateContextAttribute("loadingDialog", false);
                updateContextAttribute("LoadingMessage", "");

                break;
            case 2:
                let alertEmptyIntent = {
                    open: true,
                    severity: "error", //success, error, warning, info
                    message: "No se puede entrenar una aplicación con intenciones vacías.",
                };
                updateContextAttribute("alert", alertEmptyIntent);
                break;
            default:
                let alertError = {
                    open: true,
                    severity: "error", //success, error, warning, info
                    message: "Hubo un error al entrenar el portal de PLN",
                };
                updateContextAttribute("alert", alertError);
                break;
        }
        updateContextAttribute("loadingDialog", false);
        updateContextAttribute("LoadingMessage", "");
    };

    const publishPLNpage = async () => {
        updateContextAttribute("loadingDialog", true);
        updateContextAttribute("LoadingMessage", "Publicando el portal PLN...");
        let data = {
            enviroment: "production",
        };
        if (storageData.exclusiveServices.processNaturalLenguage && stateAppId) {
            data = {
                ...data,
                appID: stateAppId,
            };
        }
        let res = await actionsPLNManagement(data, "publishOnEnviroment");
        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
        updateContextAttribute("loadingDialog", false);
        updateContextAttribute("LoadingMessage", "");
        switch (responseCode) {
            case 0:
                let alertSuccess = {
                    open: true,
                    severity: "success", //success, error, warning, info
                    message: "El portal de PLN fue publicado de manera correcta",
                };
                updateContextAttribute("alert", alertSuccess);
                break;
            case 2:
                let alertEmptyIntent = {
                    open: true,
                    severity: "error", //success, error, warning, info
                    message: "No se puede publicar una aplicación con intenciones vacías.",
                };
                updateContextAttribute("alert", alertEmptyIntent);
                break;
            default:
                let alertError = {
                    open: true,
                    severity: "error", //success, error, warning, info
                    message: "Hubo un error al publicar el portal de PLN",
                };
                updateContextAttribute("alert", alertError);
                break;
        }
    };

    const loadSelectedApp = async () => {
        updateContextAttribute("loadingDialog", true);
        updateContextAttribute("LoadingMessage", "Cargando aplicación...");
        setAppId(tempAppId);
        setAppName(tempAppName.current);
        //sleep 500ms to wait for the app name to be set
        await sleep(500);
        setPLNComponent("Intenciones");
        updateContextAttribute("loadingDialog", false);
        updateContextAttribute("LoadingMessage", "");
    };

    const decideHideOverflow = () => {
        if (loading || errorInFetch || (intentsList.length < 5 && entityList.length < 5)) {
            return document.body.classList.add("hide-overflow");
        }
        return document.body.classList.remove("hide-overflow");
    };

    const intentsTableProps = {
        loading,
        setLoading,
        errorInFetch,
        setErrorInFetch,
        entityList,
        intentsList,
        refreshPLNData,
        setIntentsList,
        appName,
        stateAppId,
        PLNtakenNamesList,
    };

    const entitiesTableProps = {
        entityList,
        setEntityList,
        refreshPLNData,
        loading,
        errorInFetch,
        appName,
        PLNtakenNamesList,
    };

    const getCurrentPLNcomponent = () => {
        switch (PLNComponent) {
            case "Intenciones":
                return (
                    <EditIntentsTable
                        //props
                        {...intentsTableProps}
                    />
                );
            case "Entidades":
                return (
                    <EditEntitiesTable
                        //props
                        {...entitiesTableProps}
                    />
                );
            case "Probar el entrenamiento":
                return <TestPLNTraining entityList={entityList} />;
            case "Apps de conversación":
                return (
                    <ConversationApps
                        //props
                        tempAppId={tempAppId}
                        setTempAppId={setTempAppId}
                        setChangeAppHasChanged={setChangeAppHasChanged}
                        changeAppHasChanged={changeAppHasChanged}
                        tempAppName={tempAppName}
                    />
                );
            case "Sugerencias para el entrenamiento":
                return (
                    <ReviewEndpointUtterances
                        //props
                        intentsList={intentsList}
                        entityList={entityList}
                    />
                );
            default:
                return <div>{PLNComponent}</div>;
        }
    };

    const chooseFooterButtons = () => {
        switch (PLNComponent) {
            case "Apps de conversación":
                return (
                    <Button
                        disabled={!changeAppHasChanged || loading}
                        onClick={() => loadSelectedApp()}
                    >
                        Cargar aplicación
                    </Button>
                );

            default:
                return (
                    !readOnly && (
                        <>
                            <Button
                                disabled={loading || errorInFetch || !needsTraining}
                                onClick={() => trainPLNpage()}
                            >
                                Entrenar PLN
                            </Button>
                            <Button
                                variant="extended"
                                className={
                                    loading || errorInFetch
                                        ? "disabled-publish-pln-btn"
                                        : "publish-pln-btn"
                                }
                                onClick={() => publishPLNpage()}
                            >
                                Publicar
                            </Button>
                        </>
                    )
                );
        }
    };

    return (
        <div className="PLN-main-page">
            {!matchesUpMd && (
                <MUIButton
                    className="open-mobile-pln-menu-btn"
                    onClick={() => {
                        setOpenPLNmenu(!openPLNmenu);
                    }}
                    endIcon={<ArrowForwardIosIcon width="20px" height="20px" />}
                >
                    Entrenamiento
                </MUIButton>
            )}
            <PLNleftMenuBar
                setOpenPLNmenu={setOpenPLNmenu}
                openPLNmenu={openPLNmenu}
                loading={loading}
                setPLNComponent={setPLNComponent}
            />
            <main className="PLN-main-content-table">
                {getCurrentPLNcomponent()}
                {decideHideOverflow()}
            </main>
            <footer className="PLN-footer-page">{chooseFooterButtons()}</footer>
        </div>
    );
}
