import React, { memo } from "react";
//MUI components
import { Grid, Tooltip, Button, TextField } from "@material-ui/core";

import IconButton from "@material-ui/core/IconButton";

//Custom Portal Components
import IntentExample from "./IntentExample";

//Icons
import { ReactComponent as TrashCan } from "../../../Icons/trashcanIcon.svg";
import FileCopyIcon from "@material-ui/icons/FileCopy";

const IntentExamplesTable = (props) => {
    const {
        exampleList,
        handleSelection,
        createIntentErrorHelper,
        selectedWordsIds,
        selectedExampleIndex,
        handleOpenClickEntityMenu,
        removeExampleContent,
        entityList,
        findEntityInfo,
        copyExampleToClipboard,
    } = props;

    return (
        <Grid container>
            <main className="intent-example-main-container">
                {exampleList.map((exampleObj, index) => (
                    <div>
                        <div className="intent-example-main">
                            <IntentExample
                                exampleList={exampleList}
                                exampleObj={exampleObj}
                                handleSelection={handleSelection}
                                exampleIndex={index}
                                selectedWordsIds={selectedWordsIds}
                                selectedExampleIndex={selectedExampleIndex}
                                handleOpenClickEntityMenu={handleOpenClickEntityMenu}
                                entityList={entityList}
                                findEntityInfo={findEntityInfo}
                                createIntentErrorHelper={createIntentErrorHelper}
                            />
                            <div className="intent-example-utility">
                                <Tooltip title="Eliminar Entidad">
                                    <Grid container style={{ margin: "5px" }}>
                                        <IconButton
                                            color="secondary"
                                            size="small"
                                            onClick={() => removeExampleContent(index)}
                                        >
                                            <TrashCan width="20px" height="20px" />
                                        </IconButton>
                                    </Grid>
                                </Tooltip>
                                <Tooltip title="Copiar Entidad">
                                    <Grid container style={{ margin: "5px" }}>
                                        <IconButton
                                            color="primary"
                                            size="small"
                                            onClick={() => copyExampleToClipboard(index)}
                                        >
                                            <FileCopyIcon width="15px" height="15px" />
                                        </IconButton>
                                    </Grid>
                                </Tooltip>
                            </div>
                        </div>
                        <hr />
                    </div>
                ))}
            </main>
        </Grid>
    );
};

const propAreEqual = (prevProps, nextProps) => {
    return (
        prevProps.exampleList === nextProps.exampleList &&
        prevProps.selectedWordsIds === nextProps.selectedWordsIds &&
        prevProps.selectedExampleIndex === nextProps.selectedExampleIndex
    );
};

export default memo(IntentExamplesTable, propAreEqual);
