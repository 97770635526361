import React from "react";

//MUI components
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import { Grid, Menu } from "@material-ui/core";

//Custom portal components
import IntentTreeChildren from "./IntentTreeChildren";

const IntentDragMouseMenu = (props) => {
    const { mousePosition, handleCloseEntityMenu, entityList, createEntityPhrase } = props;

    return (
        <>
            <Menu
                keepMounted
                open={mousePosition.mouseY !== null}
                onClose={handleCloseEntityMenu}
                anchorReference="anchorPosition"
                anchorPosition={
                    mousePosition.mouseY !== null && mousePosition.mouseX !== null
                        ? { top: mousePosition.mouseY, left: mousePosition.mouseX }
                        : undefined
                }
            >
                <div className="select-entity-menu">
                    <h3>Selecciona una entidad</h3>
                    <div className="entity-list">
                        {entityList.map((entity, index) => (
                            <Grid key={index} container className="entity-menu-container">
                                <label
                                    onClick={() =>
                                        createEntityPhrase(
                                            entity.name,
                                            entity.parents,
                                            entity.level
                                        )
                                    }
                                    className="entity-label"
                                >
                                    {entity.name}
                                </label>
                                {entity.entityType === "Entity Extractor" && (
                                    <TreeView
                                        className="entity-tree"
                                        defaultCollapseIcon={<ExpandMoreIcon />}
                                        defaultExpandIcon={<ChevronRightIcon />}
                                    >
                                        <TreeItem className="entity-tree-item" nodeId="0" label="">
                                            {
                                                // Children 1 level
                                                entity?.children?.map((child, index) => (
                                                    <IntentTreeChildren
                                                        index={index}
                                                        entityChild={child}
                                                        createEntityPhrase={createEntityPhrase}
                                                    >
                                                        <TreeView
                                                            className="entity-tree"
                                                            defaultCollapseIcon={<ExpandMoreIcon />}
                                                            defaultExpandIcon={<ChevronRightIcon />}
                                                        >
                                                            <TreeItem
                                                                className="entity-tree-item"
                                                                nodeId="0"
                                                                label=""
                                                            >
                                                                {
                                                                    // Children 2 level
                                                                    child?.children?.map(
                                                                        (child2, index) => (
                                                                            <IntentTreeChildren
                                                                                index={index}
                                                                                entityChild={child2}
                                                                                createEntityPhrase={
                                                                                    createEntityPhrase
                                                                                }
                                                                            >
                                                                                <TreeView
                                                                                    className="entity-tree"
                                                                                    defaultCollapseIcon={
                                                                                        <ExpandMoreIcon />
                                                                                    }
                                                                                    defaultExpandIcon={
                                                                                        <ChevronRightIcon />
                                                                                    }
                                                                                >
                                                                                    <TreeItem
                                                                                        className="entity-tree-item"
                                                                                        nodeId="0"
                                                                                        label=""
                                                                                    >
                                                                                        {
                                                                                            // Children 3 level
                                                                                            child2?.children?.map(
                                                                                                (
                                                                                                    child3,
                                                                                                    index
                                                                                                ) => (
                                                                                                    <IntentTreeChildren
                                                                                                        index={
                                                                                                            index
                                                                                                        }
                                                                                                        entityChild={
                                                                                                            child3
                                                                                                        }
                                                                                                        createEntityPhrase={
                                                                                                            createEntityPhrase
                                                                                                        }
                                                                                                    >
                                                                                                        <TreeView
                                                                                                            className="entity-tree"
                                                                                                            defaultCollapseIcon={
                                                                                                                <ExpandMoreIcon />
                                                                                                            }
                                                                                                            defaultExpandIcon={
                                                                                                                <ChevronRightIcon />
                                                                                                            }
                                                                                                        >
                                                                                                            <TreeItem
                                                                                                                className="entity-tree-item"
                                                                                                                nodeId="0"
                                                                                                                label=""
                                                                                                            >
                                                                                                                {
                                                                                                                    // Children 4 level
                                                                                                                    child3?.children?.map(
                                                                                                                        (
                                                                                                                            child4,
                                                                                                                            index
                                                                                                                        ) => (
                                                                                                                            <IntentTreeChildren
                                                                                                                                index={
                                                                                                                                    index
                                                                                                                                }
                                                                                                                                entityChild={
                                                                                                                                    child4
                                                                                                                                }
                                                                                                                                createEntityPhrase={
                                                                                                                                    createEntityPhrase
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {/* ya no es posible tener mas hijos */}
                                                                                                                            </IntentTreeChildren>
                                                                                                                        )
                                                                                                                    )
                                                                                                                }
                                                                                                            </TreeItem>
                                                                                                        </TreeView>
                                                                                                    </IntentTreeChildren>
                                                                                                )
                                                                                            )
                                                                                        }
                                                                                    </TreeItem>
                                                                                </TreeView>
                                                                            </IntentTreeChildren>
                                                                        )
                                                                    )
                                                                }
                                                            </TreeItem>
                                                        </TreeView>
                                                    </IntentTreeChildren>
                                                ))
                                            }
                                        </TreeItem>
                                    </TreeView>
                                )}
                            </Grid>
                        ))}
                    </div>
                </div>
            </Menu>
        </>
    );
};

export default IntentDragMouseMenu;
