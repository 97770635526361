import React, { useEffect, useState, useContext, useRef } from "react";

//MUI components
import {
    Dialog as MuiDialog,
    DialogTitle as MuiDialogTitle,
    DialogContent as MuiDialogContent,
    DialogActions,
    IconButton,
    TextField,
    Grid,
    CircularProgress,
} from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";

//Custom portal components
import CustomButton from "../../common/CustomButton";
import IntentExamplesTable from "./IntentExamplesTable";
import { errorHelper } from "../../IntebotCatalog";
import { actionsPLNManagement } from "../../DataFunctions";
import AppContext from "../../../Context/AppContext";
import IntentClickTagMenu from "./IntentClickTagMenu";
import IntentDragMouseMenu from "./IntentDragMouseMenu";
import IntentExampleTableHeader from "./IntentExampleTableHeader";
import SearchBox from "../../common/SearchBox";
import SearchNoResults from "../../common/SearchNoResults";

//Icons
import CloseIcon from "@material-ui/icons/Close";

//Styles
import "../styles/intentsModal.css";

//External libraries
import Swal from "sweetalert2"; //https://sweetalert2.github.io
import RefreshButton from "../../common/RefreshButton";

//Footer styles
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        width: "100%",
        backgroundColor: "white",
        padding: theme.spacing(2),
        margin: "0",
        alignItems: "center",
        justifyContent: "center",
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    },
}));

//==== Dialog Styles =====
const Dialog = withStyles((theme) => ({
    root: {
        marginTop: "56px",
        [theme.breakpoints.up("md")]: {
            marginTop: "64px",
        },
    },
}))(MuiDialog);

const DialogTitle = withStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #e3e3e3",
        padding: "0px 20px",
    },
}))(MuiDialogTitle);

const DialogContent = withStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        padding: "23px",
    },
}))(MuiDialogContent);

//Modal Component
const IntentModal = (props) => {
    //State
    const [menuAnchor, setMenuAnchor] = useState(null);
    const [tempIntent, setTempIntent] = useState({}); //Objeto para guardar los cambios temporales
    const [newExampleContent, setNewExampleContent] = useState(""); //Caja de texto para agregar un nuevo ejemplo
    const [createIntentErrors, setCreateIntentErrors] = useState({
        exampleContent: false,
        intentName: false,
    });
    const [createIntentErrorHelper, setCreateIntentErrorHelper] = useState({
        exampleContent: errorHelper.removeError,
        intentName: errorHelper.removeError,
        intentExample: "",
    });

    const initialTempIntent = useRef({});

    const [examplesHasChanges, setExamplesHasChanges] = useState(false);
    const [intentNameHasChanges, setIntentNameHasChanges] = useState(false);
    const [featuresHasChanges, setFeaturesHasChanges] = useState(false);
    const [selectedEntityUniqid, setSelectedEntityUniqid] = useState(null);
    const [selectedEntityName, setSelectedEntityName] = useState("");

    const [updateIntentName, setUpdateIntentName] = useState({});

    const [selectedWordsIds, setSelectedWordsIds] = useState([]); //Palabras seleccionadas con el mouse para darles el estilo de seleccionado
    const [selectedExampleIndex, setSelectedExampleIndex] = useState(null); //Indice del último ejemplo modificado
    const [selectedExampleType, setSelectedExampleType] = useState(null); //Tipo de ejemplo modificado [suggest, label]
    const [mousePosition, setMousePosition] = useState({ mouseX: null, mouseY: null }); //Posición del mouse para abrir el menú de entidades
    //Examples
    const [examplesToUpdate, setExamplesToUpdate] = useState([]); //Ejemplos que se van a actualizar al servicio de PLN
    const [examplesToDelete, setExamplesToDelete] = useState([]); //Ejemplos que se van a eliminar al servicio de PLN
    //Features
    const [featuresToAdd, setFeaturesToAdd] = useState([]); //Features que se van a actualizar al servicio de PLN
    const [featuresToDelete, setFeaturesToDelete] = useState([]); //Features que se van a eliminar al servicio de PLN
    const [exampleList, setExampleList] = useState([]); //Lista de ejemplos del intento
    const [loadingExamples, setLoadingExamples] = useState(false); //Loading de los ejemplos
    const [searchQuery, setSearchQuery] = useState(""); //Query para buscar ejemplos
    const [errorInFetch, setErrorInFetch] = useState(false); //Error en el fetch de los ejemplos
    const [searchHasNoResults, setSearchHasNoResults] = useState(false); //Error en el fetch de los ejemplos

    const [pagination, setPagination] = useState({
        pages: 0,
        currentPage: 1,
        itemsPerPage: 50,
    });

    //Ref
    const prevFeatures = useRef([]); //Referencia a las features del intento para comparar si hubo cambios

    //destructuring props
    const {
        openIntentModal,
        setOpenIntentModal,
        currentIntent,
        entityList,
        refreshPLNData,
        PLNtakenNamesList,
        setSelectedIntent,
        stateAppId,
    } = props;

    const { intentName, features } = tempIntent;

    const classes = useStyles();
    const { updateContextAttribute, getStorageData, sleep } = useContext(AppContext);
    const storageData = getStorageData(["exclusiveServices", "appId"]);

    const clearState = () => {
        setExamplesHasChanges(false);
        setFeaturesHasChanges(false);
        setIntentNameHasChanges(false);
        //Actualizamos el estado de los ejemplos a actualizar
        setExamplesToUpdate([]);
        //Actualizamos el estado de los ejemplos a eliminar
        setExamplesToDelete([]);
        //Actualizamos el estado de las features a agregar
        setFeaturesToAdd([]);
        //Actualizamos el estado de las features a eliminar
        setFeaturesToDelete([]);
        setSelectedIntent("");
        setExampleList([]);
        setTempIntent({});
        setNewExampleContent("");
        setCreateIntentErrors({
            exampleContent: false,
            intentName: false,
        });
        setCreateIntentErrorHelper({
            exampleContent: errorHelper.removeError,
            intentName: errorHelper.removeError,
            intentExample: "",
        });
        setSelectedWordsIds([]);
        setSelectedExampleIndex(null);
        setSelectedExampleType(null);
        setMousePosition({ mouseX: null, mouseY: null });
        setMenuAnchor(null);
        setUpdateIntentName({});
        setSearchQuery("");
        setSearchHasNoResults(false);
        setErrorInFetch(false);
        setLoadingExamples(false);
        setPagination({
            pages: 0,
            currentPage: 1,
            itemsPerPage: 50,
        });
    };

    useEffect(() => {
        let currentIntentCopy = JSON.parse(JSON.stringify(currentIntent));
        initialTempIntent.current = currentIntentCopy;
        setTempIntent(currentIntentCopy);
    }, [currentIntent]);

    useEffect(() => {
        getCurrentIntentExamples(pagination);
    }, [openIntentModal]);

    //Executada cadavez que el search query cambia
    useEffect(() => {
        setLoadingExamples(true);
        setSearchHasNoResults(false);
        //Esperamos a que el usuario termine de escribir
        const awaitUserTyping = setTimeout(() => {
            if (searchQuery === "") {
                getCurrentIntentExamples(pagination);
            } else {
                searchExampleInList(searchQuery);
            }
        }, 1500);
        return () => clearTimeout(awaitUserTyping);
    }, [searchQuery]);

    const getCurrentIntentExamples = async (pagination) => {
        if (!currentIntent.intentId) return;

        let data = {
            intentId: currentIntent.intentId,
            appID: stateAppId,
        };
        setLoadingExamples(true);
        let res = await actionsPLNManagement(
            data,
            `getExampleList&page=${pagination.currentPage}&limit=${pagination.itemsPerPage}`
        );
        setLoadingExamples(false);
        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
        switch (responseCode) {
            case 0:
                let exampleList = processExampleList(res?.data?.data?.exampleList || []);
                let pages = res.data.data.totalPages || 0;
                let currentPage = res.data.data.page || 1;
                setPagination({ ...pagination, pages: pages, currentPage: currentPage });
                setExampleList(exampleList);
                break;

            default:
                break;
        }
    };

    const processExampleList = (exampleList) => {
        for (const exampleObj of exampleList) {
            let { examples, tokenizedText } = exampleObj;
            examples.forEach((entity, index) => {
                const { entityName, startIndex, length, text, modelType } = entity;

                //create a uniqid using substring of the entity name and the index and a random number
                let uniqid = entityName.substring(0, 3) + index + Math.floor(Math.random() * 1000);
                entity["uniqid"] = uniqid;

                if (["Regex Entity Extractor", "List Entity Extractor"].includes(modelType)) {
                    let endTokenIndex;
                    let startTokenIndex;
                    let entityPhrase;
                    // Calculate the end index from the start index and the length of the entity
                    let endIndex = startIndex + length;

                    //Search the start token
                    let sumWordLength = 0;
                    // Loop through each word in the tokenized text array
                    for (let index = 0; index < tokenizedText.length; index++) {
                        const word = tokenizedText[index];
                        // Add the length of the word and a space to the sum
                        let wordLength = word.length + 1;
                        sumWordLength += wordLength;
                        // If the sum is greater than the start index, we have found the start token
                        if (sumWordLength > startIndex) {
                            startTokenIndex = index;
                            break;
                        }
                    }
                    //Buscamos el token final
                    let sumWordLength2 = 0;
                    for (let index = 0; index < tokenizedText.length; index++) {
                        const word = tokenizedText[index];
                        let wordLength = word.length + 1;
                        sumWordLength2 += wordLength;
                        // If the sum is greater than the start index, we have found the start token
                        if (sumWordLength2 > endIndex) {
                            endTokenIndex = index;
                            break;
                        }
                    }
                    entityPhrase = {
                        phrase: text,
                        startTokenIndex: startTokenIndex,
                        endTokenIndex: endTokenIndex,
                        entityName: entityName,
                        modelType: modelType,
                    };
                    examples[index] = entityPhrase;
                }
            });
        }
        exampleList.forEach((exampleObj) => {
            const { examples = [], tokenizedText } = exampleObj;
            examples.forEach((example, index) => {
                const { startTokenIndex, endTokenIndex } = example;
                example["phrase"] = tokenizedText
                    .slice(startTokenIndex, endTokenIndex + 1)
                    .join(" "); //-> obtenemos la frase de los indices de los tokens;
                example["entityId"] = index;
            });
        });
        return exampleList;
    };

    const searchExampleInList = async (searchQuery) => {
        if (!searchQuery) {
            getCurrentIntentExamples(pagination);
            return;
        }

        let data = {
            intentId: currentIntent.intentId,
            searchQuery: searchQuery,
            appID: stateAppId,
        };
        setLoadingExamples(true);
        let res = await actionsPLNManagement(data, `filterExamples`);
        setLoadingExamples(false);
        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
        switch (responseCode) {
            case 0:
                let exampleList = processExampleList(res?.data?.data?.exampleList || []);
                let pages = res.data.data.totalPages || 0;
                let currentPage = res.data.data.page || 1;
                setPagination({ ...pagination, pages: pages, currentPage: currentPage });
                setExampleList(exampleList);
                if (exampleList.length === 0) {
                    setSearchHasNoResults(true);
                }
                break;

            default:
                Swal.fire({
                    title: "Error",
                    text: "Ocurrió un error al buscar los ejemplos, por favor intenta de nuevo",
                    icon: "error",
                });
                setSearchQuery("");

                break;
        }
    };

    const handleIntentForm = (targetName, targetValue, targetReason) => {
        let createIntentErrorsCopy = { ...createIntentErrors };
        let createIntentErrorHelperCopy = { ...createIntentErrorHelper };
        switch (targetName) {
            case "exampleContent":
                setNewExampleContent(targetValue);
                if (targetValue.length > 450) {
                    createIntentErrorsCopy[targetName] = true;
                    createIntentErrorHelperCopy[targetName] = errorHelper.excidedLength450;
                    break;
                }
                createIntentErrorsCopy[targetName] = false;
                createIntentErrorHelperCopy[targetName] = errorHelper.removeError;
                break;
            case "featuresList":
                if (targetReason === "select-option") {
                    //Sacamos el ultimo valor agregado del targetValue
                    let newFeature = targetValue.pop();
                    let dataToAdd = {
                        modelName: newFeature,
                        intentId: tempIntent.intentId,
                    };
                    let featureObj = {
                        featureName: newFeature,
                        newFeature: true,
                    };
                    let featuresCopy = [...features];
                    let featuresToAddCopy = [...featuresToAdd];
                    featuresCopy.push(featureObj);
                    setTempIntent({ ...tempIntent, features: featuresCopy });
                    featuresToAddCopy.push(dataToAdd);
                    setFeaturesToAdd(featuresToAddCopy);
                    setFeaturesHasChanges(true);
                    break;
                }
                if (targetReason === "remove-option") {
                    //compare targetValue Array with prevFeatures.current Array and get the difference
                    let featuresCopy = [...features];
                    let featuresToDeleteCopy = [...featuresToDelete];
                    prevFeatures.current = featuresCopy;
                    let featuresToRemove = prevFeatures.current.filter(
                        (feature) => !targetValue.includes(feature.featureName || feature.modelName)
                    )[0];
                    if (featuresToRemove.newFeature) {
                        //Lo quitamos de la lista de features a agregar
                        let featuresToAddCopy = [...featuresToAdd];
                        let index = featuresToAddCopy.findIndex(
                            (feature) => feature.featureName === featuresToRemove.featureName
                        );
                        featuresToAddCopy.splice(index, 1);
                        setFeaturesToAdd(featuresToAddCopy);
                    } else {
                        //Lo agregamos a la lista de features a eliminar
                        let dataToRemove = {
                            featureName: featuresToRemove.featureName,
                            intentId: tempIntent.intentId,
                        };
                        featuresToDeleteCopy.push(dataToRemove);
                        setFeaturesToDelete(featuresToDeleteCopy);
                    }
                    //lo quitamos de la lista de features del state
                    let index = featuresCopy.findIndex(
                        (feature) => feature.featureName === featuresToRemove.featureName
                    );
                    featuresCopy.splice(index, 1);
                    setTempIntent({ ...tempIntent, features: featuresCopy });
                    setFeaturesHasChanges(true);
                    break;
                }
                break;
            case "intentName":
                //Evitamos que el cliente ponga espacios
                targetValue = targetValue.replace(/ /g, "");
                let nameToUpdate = {
                    intentName: targetValue,
                    intentId: tempIntent.intentId,
                };
                //Guardamos
                setUpdateIntentName(nameToUpdate);
                setTempIntent({ ...tempIntent, intentName: targetValue });
                setIntentNameHasChanges(true);

                //remove current intent name from intentListNames
                let PLNtakenNamesListCopy = [...PLNtakenNamesList];
                let index = PLNtakenNamesListCopy.findIndex(
                    (takenNameObj) => takenNameObj.id === currentIntent.intentId
                );
                PLNtakenNamesListCopy.splice(index, 1);
                let duplicateName = PLNtakenNamesListCopy.filter(
                    (takenNameObj) => takenNameObj.name.toLowerCase() === targetValue.toLowerCase()
                );

                //Validamos si el nombre del intent ya existe
                if (duplicateName.length > 0) {
                    createIntentErrorsCopy.intentName = true;
                    createIntentErrorHelperCopy.intentName = errorHelper.duplicateName;
                    break;
                }

                //check empty field
                if (!targetValue) {
                    createIntentErrorsCopy[targetName] = true;
                    createIntentErrorHelperCopy[targetName] = errorHelper.emptyField;
                    break;
                }

                createIntentErrorsCopy.intentName = false;
                createIntentErrorHelperCopy.intentName = errorHelper.removeError;

                break;
            default:
                break;
        }
        setCreateIntentErrors(createIntentErrorsCopy);
        setCreateIntentErrorHelper(createIntentErrorHelperCopy);
    };

    //*************FUNCION QUE ESTA ACTUALMENTE EN PRODUCCION
    // const handleSelection = (exampleIndex, event) => {
    //     let selectedWordsId = []
    //     const selection = window.getSelection();

    //     //obtenemos el primer <span> de la selección
    //     try {
    //         var range = selection.getRangeAt(0);
    //     } catch (error) {
    //         return
    //     }
    //     const startTokenIndex = parseInt(range.startContainer.parentElement.id);
    //     //obtenemos el ultimo <span> de la selección
    //     const endTokenIndex = parseInt(selection.focusNode.parentElement.id);

    //     //si el token no fue seleccionado de manera correcta cancelamos la selección
    //     if(isNaN(endTokenIndex) || isNaN(startTokenIndex) ){
    //         selection.removeAllRanges()
    //         return
    //     }

    //     //buscamos los indices de las palabras seleccionadas
    //     for (let i = startTokenIndex; i <= endTokenIndex; i++) {
    //         selectedWordsId.push(i);
    //     }
    //     setSelectedWordsIds(selectedWordsId)
    //     setSelectedExampleIndex(exampleIndex)
    //     handleOpenEntityMenu(event, selection);
    // }

    const handleSelection = (exampleIndex, event) => {
        const selection = window.getSelection();
        let selectionString = selection.toString();
        //dividimos el selectionString por \n
        let selectionArray = selectionString.split(/\r?\n/);
        let wordsToRemove = [];
        //elimina todas las palabras de la seleccón que empiezan con "[" y terminan con "]" del array de selectionString y también las que empiezan con ":" y terminan con ":"
        for (let index = 0; index < selectionArray.length; index++) {
            const selectedWord = selectionArray[index];

            //Creamos el regex para buscar palabras entre corchetes [test]
            const testRegexBrackets = new RegExp(/\[.*?\]/g);
            //Creamos el regex para buscar palabras entre dos puntos :test:
            const testWordBetweenColons = new RegExp(/:[\w\s]+:/g);

            //Validamos si la palabra esta entre corchetes o entre dos puntos
            let isWordInBrackets = testRegexBrackets.test(selectedWord);
            let isWordInColons = testWordBetweenColons.test(selectedWord);

            if (isWordInBrackets || isWordInColons) {
                //Agregamos el indice de la palabra a eliminar
                wordsToRemove.push(index);
            }
        }

        //Eliminamos las palabras que estan entre corchetes o entre dos puntos por un string vacio
        wordsToRemove.forEach((wordIndex) => {
            selectionArray.splice(wordIndex, 1, "");
        });

        //eliminiamos los elementos vacios del array
        selectionArray = selectionArray.filter((word) => word !== "");

        //Creamos la selección de spans
        try {
            var range = selection.getRangeAt(0);
        } catch (error) {
            return;
        }
        //el primer token lo obtenemos el primer <span> de la selección
        const startTokenIndex = parseInt(range.startContainer.parentElement.id);
        //el endToken lo calculamos empezando por el primer token y sumando la cantidad de tokens seleccionados **Nota: "selection.focusNode.parentElement.id" se utiliza en firefox y en chrome se calcula con la cantidad de tokens seleccionados
        const endTokenIndex = navigator.userAgent.includes("Firefox")
            ? parseInt(selection.focusNode.parentElement.id)
            : startTokenIndex + selectionArray.length - 1;

        //si el token no fue seleccionado de manera correcta cancelamos la selección
        if (isNaN(endTokenIndex) || isNaN(startTokenIndex)) {
            selection.removeAllRanges();
            return;
        }
        let selectedWordsId = [];
        //buscamos los indices de las palabras seleccionadas
        for (let i = startTokenIndex; i <= endTokenIndex; i++) {
            selectedWordsId.push(i);
        }
        if (selectedWordsId.length === 0) {
            selection.removeAllRanges();
            return;
        }

        setSelectedWordsIds(selectedWordsId);
        setSelectedExampleIndex(exampleIndex);
        handleOpenEntityMenu(event, selection);
    };

    const handleOpenEntityMenu = (event, selection) => {
        event.preventDefault();
        selection.removeAllRanges();
        setMousePosition({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
        });
    };

    const handleCloseEntityMenu = () => {
        setMousePosition({ mouseX: null, mouseY: null });
        setMenuAnchor(null);
        setSelectedWordsIds([]);
    };

    const addNewExampleContent = async (newExampleContent) => {
        let exampleListCopy = JSON.parse(JSON.stringify(exampleList));
        newExampleContent = newExampleContent.toLowerCase().trim();
        if (!newExampleContent) {
            let createIntentErrorsCopy = { ...createIntentErrors };
            let createIntentErrorHelperCopy = { ...createIntentErrorHelper };
            createIntentErrorsCopy["exampleContent"] = true;
            createIntentErrorHelperCopy["exampleContent"] = errorHelper.emptyField;
            setCreateIntentErrors(createIntentErrorsCopy);
            setCreateIntentErrorHelper(createIntentErrorHelperCopy);
            return;
        }

        //check ig the newExampleContent already exists
        let exampleExists = exampleListCopy.filter(
            (example) => example.text.toLowerCase().trim() === newExampleContent
        );
        if (exampleExists.length > 0) {
            let createIntentErrorsCopy = { ...createIntentErrors };
            let createIntentErrorHelperCopy = { ...createIntentErrorHelper };
            createIntentErrorsCopy["exampleContent"] = true;
            createIntentErrorHelperCopy["exampleContent"] = errorHelper.duplicateExample;
            setCreateIntentErrors(createIntentErrorsCopy);
            setCreateIntentErrorHelper(createIntentErrorHelperCopy);
            return;
        }

        //dividimos el  newExampleContent en signos de puntuacion y palabras
        const splitRegex = /([^\wáéíóúÁÉÍÓÚüÜñÑ]|\s+)/g;
        //creamos el arrgelo de palabras tokenizadas y quitamos los espacios en blanco
        let tokenizedText = newExampleContent
            .split(splitRegex)
            .filter((word) => word.trim() !== "")
            .map((word) => word.trim());
        let exampleObj = {
            text: newExampleContent,
            tokenizedText: tokenizedText,
            examples: [],
            newExample: true,
            id: exampleListCopy.length + 1,
        };
        exampleListCopy.unshift(exampleObj);
        getSuggestions(newExampleContent, tokenizedText, exampleListCopy, exampleObj);
    };

    const findEntityInfo = (entityName) => {
        for (let index = 0; index < entityList.length; index++) {
            const entity = entityList[index];
            if (entity.name === entityName) {
                return entity;
            }
            if (!entity.hasOwnProperty("children")) continue;
            for (let index = 0; index < entity.children.length; index++) {
                const child = entity.children[index];
                if (child.name === entityName) {
                    return child;
                }
                for (let index = 0; index < child.children.length; index++) {
                    const child2 = child.children[index];
                    if (child2.name === entityName) {
                        return child2;
                    }
                    for (let index = 0; index < child2.children.length; index++) {
                        const child3 = child2.children[index];
                        if (child3.name === entityName) {
                            return child3;
                        }
                        for (let index = 0; index < child3.children.length; index++) {
                            const child4 = child3.children[index];
                            if (child4.name === entityName) {
                                return child4;
                            }
                        }
                    }
                }
            }
        }
        return {};
    };

    const removeChildInsideParent = (child, parent, exampleObj) => {
        const { name } = child;
        const { startTokenIndex, endTokenIndex } = parent;
        let childTagExists = exampleObj.examples.filter((entity) => entity.entityName === name);
        for (let index = 0; index < childTagExists.length; index++) {
            const childTag = childTagExists[index];
            let childInsideParent =
                startTokenIndex <= childTag.startTokenIndex &&
                endTokenIndex >= childTag.endTokenIndex;
            if (childInsideParent) {
                let childTagIndex = exampleObj.examples.findIndex(
                    (entity) => entity.uniqid === childTag.uniqid
                );
                exampleObj.examples.splice(childTagIndex, 1);
            }
        }
        return exampleObj;
    };

    const removeEntityPhrase = (selectedEntityUniqid) => {
        let exampleListCopy = JSON.parse(JSON.stringify(exampleList));
        let exampleObj = exampleListCopy[selectedExampleIndex];
        let selectedEntityIndex = exampleObj.examples.findIndex(
            (entity) => entity.uniqid === selectedEntityUniqid
        );
        let removedEntity = exampleObj.examples.splice(selectedEntityIndex, 1)[0];
        let { startTokenIndex, endTokenIndex, entityName } = removedEntity;

        let entityObj = findEntityInfo(entityName);

        const { children = [] } = entityObj;
        for (let index = 0; index < children.length; index++) {
            const child = children[index];
            const { children: children2 } = child;
            exampleObj = removeChildInsideParent(child, removedEntity, exampleObj);
            if (children2.length === 0) continue;
            for (let index = 0; index < children2.length; index++) {
                const child2 = children2[index];
                exampleObj = removeChildInsideParent(child2, removedEntity, exampleObj);
                const { children: children3 } = child2;
                if (children3.length === 0) continue;
                for (let index = 0; index < children3.length; index++) {
                    const child3 = children3[index];
                    exampleObj = removeChildInsideParent(child3, removedEntity, exampleObj);
                    const { children: children4 } = child3;
                    if (children4.length === 0) continue;
                    for (let index = 0; index < children4.length; index++) {
                        const child4 = children4[index];
                        exampleObj = removeChildInsideParent(child4, removedEntity, exampleObj);
                    }
                }
            }
        }

        //search for the same start and end token index in the exampleObj.examples
        let entityExists = exampleObj.examples.filter(
            (entity) =>
                entity.startTokenIndex === startTokenIndex && entity.endTokenIndex === endTokenIndex
        );
        if (entityExists.length > 0) {
            entityExists.forEach((entityExist) => {
                let existsEntityIndex = exampleObj.examples.findIndex(
                    (entity) => entity.uniqid === entityExist.uniqid
                );
                exampleObj.examples.splice(existsEntityIndex, 1);
            });
        }

        exampleListCopy[selectedExampleIndex] = exampleObj;
        createObjectToUpdate(exampleObj);
        setExampleList(exampleListCopy);
        setExamplesHasChanges(true);
        handleCloseEntityMenu();
    };

    const createEntityPhrase = (
        entityName,
        parents,
        level,
        selectedStartTokenIndex,
        selectedEndTokenIndex
    ) => {
        //console.log(entityName, selectedStartTokenIndex, selectedEndTokenIndex);
        let createIntentErrorHelperCopy = { ...createIntentErrorHelper };
        createIntentErrorHelperCopy["intentExample"] = errorHelper.removeError;
        let exampleListCopy = JSON.parse(JSON.stringify(exampleList));
        let exampleObj = exampleListCopy[selectedExampleIndex];
        let startTokenIndex =
            selectedWordsIds[0] > -1 ? selectedWordsIds[0] : selectedStartTokenIndex;
        let endTokenIndex =
            selectedWordsIds[selectedWordsIds.length - 1] > -1
                ? selectedWordsIds[selectedWordsIds.length - 1]
                : selectedEndTokenIndex;
        let parentsCopy = JSON.parse(JSON.stringify(parents));
        let selectedEntityIsRepeated = false;
        let noCreateParents = false;

        //Si la entidad seleccionada es hija y contiene padres, entonces verificamos si la entidad padre ya existe en el ejemplo
        if (parents.length > 0) {
            for (let index = 0; index < parents.length; index++) {
                const parent = parents[index];
                //Si el startIndex y el EndIndex de la entidad repetida es igual al startIndex y el endIndex de la entidad seleccionada, entonces mandamos a eliminar
                if (
                    parent.startTokenIndex === startTokenIndex &&
                    parent.endTokenIndex === endTokenIndex
                ) {
                    //Eliminamos la entidad repetida y no creamos la nueva entidad
                    selectedEntityIsRepeated = true;
                    removeEntityPhrase(parent.uniqid);
                    return;
                }

                let entityParentExistsArray = exampleObj.examples.filter(
                    (entity) => entity.entityName === parent.name && entity.type === "label"
                );
                //console.log("entityParentExistsArray :>> ", entityParentExistsArray);
                let entityParentExistsIndex = exampleObj.examples.findIndex(
                    (entity) => entity.entityName === parent.name
                );
                //console.log("entityParentExistsIndex :>> ", entityParentExistsIndex);
                //Obtener los entities del mismo nivel
                let entitySameLevel = exampleObj.examples.find((entity) => entity.level === level);
                if (entitySameLevel) {
                    //Si el startIndex y el EndIndex de la entidad repetida es igual al startIndex y el endIndex de la entidad seleccionada, entonces mandamos a eliminar
                    if (
                        entitySameLevel.startTokenIndex === startTokenIndex &&
                        entitySameLevel.endTokenIndex === endTokenIndex
                    ) {
                        //Eliminamos la entidad repetida y si creamos la nueva entidad
                        selectedEntityIsRepeated = true;
                        removeEntityPhrase(entitySameLevel.uniqid);
                        return;
                    }
                }

                if (entityParentExistsArray.length === 0) continue;

                //iteramos el array de entidades padres
                for (let index = 0; index < entityParentExistsArray.length; index++) {
                    const entityParentExists = entityParentExistsArray[index];

                    //Obtenemos el starIndex y el endIndex de cada padre para ver si la entidad seleccionada esta dentro del la entidad padre
                    let parentStartIndexIsHigher =
                        entityParentExists.startTokenIndex <= startTokenIndex &&
                        entityParentExists.endTokenIndex >= startTokenIndex;
                    let parentEndIndexIsLower =
                        entityParentExists.endTokenIndex >= endTokenIndex &&
                        entityParentExists.startTokenIndex <= endTokenIndex;

                    //Preguntamos si los padres son suggestion y si estan dentro del entity seleccionado
                    if (entityParentExists.type === "suggest") {
                        if (parentStartIndexIsHigher && parentEndIndexIsLower) {
                            exampleObj.examples[entityParentExistsIndex].type = "label";
                            noCreateParents = true;
                            continue;
                        }
                    }

                    let entityRepeatedInsideSelectedEntity =
                        entityParentExists.startTokenIndex >= startTokenIndex &&
                        entityParentExists.endTokenIndex <= endTokenIndex;
                    if (entityRepeatedInsideSelectedEntity) {
                        let selectedEntityIndex = exampleObj.examples.findIndex(
                            (entity) => entity.uniqid === entityParentExists.uniqid
                        );
                        let removedEntity = exampleObj.examples.splice(selectedEntityIndex, 1)[0];
                        continue;
                    }

                    //Si el start y end son falsos entonces el padre no esta dentro del entity y no hacemos nada
                    if (!parentStartIndexIsHigher && !parentEndIndexIsLower) {
                        continue;
                    }

                    //si el start y end son verdaderos entonces el padre esta dentro del entity y lo eliminamos del array de parents
                    parentsCopy.splice(
                        parentsCopy.findIndex(
                            (parent) => parent.name === entityParentExists.entityName
                        ),
                        1
                    );

                    //si uno es verdadero y el otro es falso, entonces el padre esta parcialmente dentro del entity y extendemos el padre al index del parent elegido
                    if (parentStartIndexIsHigher && !parentEndIndexIsLower) {
                        exampleObj.examples[entityParentExistsIndex].endTokenIndex = endTokenIndex;
                        const { startTokenIndex: parentStartIndex, endTokenIndex: parentEndIndex } =
                            exampleObj.examples[entityParentExistsIndex];
                        exampleObj.examples[entityParentExistsIndex].phrase =
                            exampleObj.tokenizedText
                                .slice(parentStartIndex, parentEndIndex + 1)
                                .join(" ");
                        continue;
                    }
                    if (!parentStartIndexIsHigher && parentEndIndexIsLower) {
                        exampleObj.examples[entityParentExistsIndex].startTokenIndex =
                            startTokenIndex;
                        const { startTokenIndex: parentStartIndex, endTokenIndex: parentEndIndex } =
                            exampleObj.examples[entityParentExistsIndex];
                        exampleObj.examples[entityParentExistsIndex].phrase =
                            exampleObj.tokenizedText
                                .slice(parentStartIndex, parentEndIndex + 1)
                                .join(" ");
                        continue;
                    }
                }
            }
        }

        //Buscamos todas las entidades con el mismo nombre en el ejemplo
        let entityPhraseRepeated = exampleObj.examples.filter(
            (entity) => entity.entityName === entityName
        );

        for (let index = 0; index < entityPhraseRepeated.length; index++) {
            const entityRepeated = entityPhraseRepeated[index];
            if (
                entityRepeated.type === "suggest" &&
                entityRepeated.startTokenIndex === startTokenIndex &&
                entityRepeated.endTokenIndex === endTokenIndex
            ) {
                let selectedEntityIndex = exampleObj.examples.findIndex(
                    (entity) => entity.uniqid === entityRepeated.uniqid
                );
                let removedEntity = exampleObj.examples.splice(selectedEntityIndex, 1)[0];
                if (parents.length > 0) {
                    parents.forEach((parent) => {
                        //splice parents
                        let parentsInExample = exampleObj.examples.filter(
                            (entity) => entity.entityName === parent.name
                        );
                        for (const parentExample of parentsInExample) {
                            if (
                                parentExample.type === "suggest" &&
                                parentExample.startTokenIndex === startTokenIndex &&
                                parentExample.endTokenIndex === endTokenIndex
                            ) {
                                let parentIndex = exampleObj.examples.findIndex(
                                    (entity) => entity.uniqid === parentExample.uniqid
                                );
                                let removedParents = exampleObj.examples.splice(parentIndex, 1);
                            }
                        }
                    });
                }
                continue;
            }

            if (entityRepeated.type === "suggest") continue;

            //Si el startIndex y el EndIndex de la entidad repetida es igual al startIndex y el endIndex de la entidad seleccionada, entonces mandamos a eliminar
            if (
                entityRepeated.startTokenIndex === startTokenIndex &&
                entityRepeated.endTokenIndex === endTokenIndex
            ) {
                //Eliminamos la entidad repetida y no creamos la nueva entidad
                selectedEntityIsRepeated = true;
                removeEntityPhrase(entityRepeated.uniqid);
                return;
            }

            //Obtenemos el starIndex y el endIndex de la entidad repetida
            let entityRepeatedIndexIsHigher =
                entityRepeated.startTokenIndex <= startTokenIndex &&
                entityRepeated.endTokenIndex >= startTokenIndex;
            let entityRepeatedIndexIsLower =
                entityRepeated.endTokenIndex >= endTokenIndex &&
                entityRepeated.startTokenIndex <= endTokenIndex;

            let entityRepeatedInsideSelectedEntity =
                entityRepeated.startTokenIndex >= startTokenIndex &&
                entityRepeated.endTokenIndex <= endTokenIndex;
            if (entityRepeatedInsideSelectedEntity) {
                let selectedEntityIndex = exampleObj.examples.findIndex(
                    (entity) => entity.uniqid === entityRepeated.uniqid
                );
                let removedEntity = exampleObj.examples.splice(selectedEntityIndex, 1)[0];
                continue;
            }

            if (entityRepeatedIndexIsHigher && entityRepeatedIndexIsLower) {
                selectedEntityIsRepeated = true;
                removeEntityPhrase(entityRepeated.uniqid);
                continue;
            }

            let entityRepeatedIndexInExamples = exampleObj.examples.findIndex(
                (entity) => entity.entityName === entityRepeated.entityName
            );
            //Si uno de los dos es mayor o menor, entonces la entidad repetida esta parcialmente dentro de la entidad seleccionada, entonces extendemos la entidad repetida
            if (entityRepeatedIndexIsHigher && !entityRepeatedIndexIsLower) {
                exampleObj.examples[entityRepeatedIndexInExamples].endTokenIndex = endTokenIndex;
                const { startTokenIndex: repeatedStartIndex, endTokenIndex: reapetedEndIndex } =
                    exampleObj.examples[entityRepeatedIndexInExamples];
                exampleObj.examples[entityRepeatedIndexInExamples].phrase = exampleObj.tokenizedText
                    .slice(repeatedStartIndex, reapetedEndIndex + 1)
                    .join(" ");
                selectedEntityIsRepeated = true;
                continue;
            }
            if (!entityRepeatedIndexIsHigher && entityRepeatedIndexIsLower) {
                exampleObj.examples[entityRepeatedIndexInExamples].startTokenIndex =
                    startTokenIndex;
                const { startTokenIndex: repeatedStartIndex, endTokenIndex: reapetedEndIndex } =
                    exampleObj.examples[entityRepeatedIndexInExamples];
                exampleObj.examples[entityRepeatedIndexInExamples].phrase = exampleObj.tokenizedText
                    .slice(repeatedStartIndex, reapetedEndIndex + 1)
                    .join(" ");
                selectedEntityIsRepeated = true;
                continue;
            }

            //Si ninguno de los dos es mayor o menor, entonces la entidad repetida esta fuera de la entidad seleccionada, entonces no hacemos nada
            if (!entityRepeatedIndexIsHigher && !entityRepeatedIndexIsLower) {
                selectedEntityIsRepeated = false;
                continue;
            }
        }

        //Creamos el objeto del ejemplo con cada padre de la entidad seleccionada
        if (!noCreateParents && parents.length > 0) {
            parentsCopy.forEach((parent, index) => {
                const { name, level } = parent;
                let uniqid = name.substring(0, 3) + index + Math.floor(Math.random() * 1000);
                //Creamos el objeto del ejemplo con la entidad seleccionada
                let entityPhrase = {
                    phrase: exampleObj.tokenizedText
                        .slice(startTokenIndex, endTokenIndex + 1)
                        .join(" "),
                    startTokenIndex: startTokenIndex,
                    endTokenIndex: endTokenIndex,
                    entityName: name,
                    entityId: exampleObj.examples.length + 1 - 1,
                    level: level,
                    uniqid: uniqid,
                    type: "label",
                };
                exampleObj.examples.push(entityPhrase);
            });
        }

        //Evitamos que se cree la entidad si ya existe
        if (!selectedEntityIsRepeated) {
            let newPhraseIndex = exampleObj.examples.length + 1 - 1;
            let uniqid =
                entityName.substring(0, 3) + newPhraseIndex + Math.floor(Math.random() * 1000);
            //Creamos el objeto del ejemplo con la entidad seleccionada
            let entityPhrase = {
                phrase: exampleObj.tokenizedText
                    .slice(startTokenIndex, endTokenIndex + 1)
                    .join(" "),
                startTokenIndex: startTokenIndex,
                endTokenIndex: endTokenIndex,
                entityName: entityName,
                entityId: newPhraseIndex,
                level: level,
                uniqid: uniqid,
                type: "label",
            };
            exampleObj.examples.push(entityPhrase);
        }

        //Guardamos el objeto para ser mandado al servicio de PLN
        exampleListCopy[selectedExampleIndex] = exampleObj;
        createObjectToUpdate(exampleObj);
        setExampleList(exampleListCopy);
        handleCloseEntityMenu();
        setExamplesHasChanges(true);
        setCreateIntentErrorHelper(createIntentErrorHelperCopy);
    };

    const getSuggestions = async (
        newExampleContent,
        tokenizedText,
        exampleListCopy,
        exampleObj
    ) => {
        updateContextAttribute("loadingDialog", true);
        updateContextAttribute("LoadingMessage", "Buscando predicciones...");
        let data = {
            query: newExampleContent,
        };
        if (storageData.exclusiveServices.processNaturalLenguage && storageData.appId) {
            data["appID"] = storageData.appId;
        }
        let res = await actionsPLNManagement(data, "getSuggestions");
        updateContextAttribute("loadingDialog", false);
        updateContextAttribute("LoadingMessage", "");
        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
        let createIntentErrorHelperCopy = { ...createIntentErrorHelper };
        switch (responseCode) {
            case 0:
                let alertSuccess = {
                    open: true,
                    severity: "success", //success, error, warning, info
                    message: "las predicciones se encontraron correctamente",
                };
                updateContextAttribute("alert", alertSuccess);
                let entities = res.data.data.entities;
                entities.forEach((entity, index) => {
                    let entityName = entity.entityName;
                    let uniqid =
                        entityName.substring(0, 3) + index + Math.floor(Math.random() * 1000);
                    entity.uniqid = uniqid;
                });
                exampleListCopy[0].examples = entities;
                createIntentErrorHelperCopy["intentExample"] = errorHelper.removeError;
                break;
            case 2:
                let alertDefault = {
                    open: true,
                    severity: "info", //success, error, warning, info
                    message: "No se pudo encontrar predicciones para el ejemplo.",
                };
                createIntentErrorHelperCopy["intentExample"] = errorHelper.predictionNotFound;
                updateContextAttribute("alert", alertDefault);
                createObjectToUpdate(exampleObj);
            default:
                let alertError = {
                    open: true,
                    severity: "info", //success, error, warning, info
                    message: "Hubo un error al buscar las predicciones del ejemplo.",
                };
                createIntentErrorHelperCopy["intentExample"] = errorHelper.predictionNotFound;
                updateContextAttribute("alert", alertError);
                createObjectToUpdate(exampleObj);
                break;
        }
        setCreateIntentErrorHelper(createIntentErrorHelperCopy);
        setExampleList(exampleListCopy);
        setNewExampleContent("");
        setExamplesHasChanges(true);
    };

    const createObjectToUpdate = (exampleObj) => {
        let examplesToUpdateCopy = [...examplesToUpdate];
        //Buscamos si el ejemplo ya existe en el arreglo de ejemplos a actualizar
        let index = examplesToUpdateCopy.findIndex(
            (exampleToUpdate) => exampleToUpdate.text === exampleObj.text
        );

        let dataToUpdate = {
            exampleId: exampleObj.exampleId,
            intentName: intentName,
            text: exampleObj.text,
            examples: exampleObj.examples,
        };
        //Si el ejemplo no existe en el arreglo de ejemplos a actualizar lo agregamos
        if (index === -1) {
            examplesToUpdateCopy.push(dataToUpdate);
        } else {
            examplesToUpdateCopy[index] = dataToUpdate;
        }
        setExamplesToUpdate(examplesToUpdateCopy);
    };

    const saveIntentChanges = async () => {
        let askConfirm = await Swal.fire({
            text: "¿Deseas guardar tus cambios?",
            showDenyButton: true,
            confirmButtonText: "Si",
            denyButtonText: `No`,
            confirmButtonColor: "#27315d",
            denyButtonColor: "#27315d",
        });
        //Cancelamos el guardado de cambios
        if (askConfirm.isDenied || askConfirm.isDismissed) {
            return;
        }
        updateContextAttribute("loadingDialog", true);
        updateContextAttribute("LoadingMessage", "Guardando cambios...");
        let responseCodeArray = [];
        //Actualizamos los ejemplos modificados
        if (examplesHasChanges) {
            for (let index = 0; index < examplesToUpdate.length; index++) {
                const dataToUpdate = examplesToUpdate[index];
                //console.log("dataToUpdate :>> ", JSON.stringify(dataToUpdate, null, 2));
                //Unicamente se mandan los ejemplos de tipo label
                dataToUpdate.examples = dataToUpdate.examples.filter(
                    (example) => example.type === "label"
                );
                if (storageData.exclusiveServices.processNaturalLenguage && storageData.appId) {
                    dataToUpdate["appID"] = storageData.appId;
                }
                //console.log(JSON.stringify(dataToUpdate, null, 2))
                let res = await actionsPLNManagement(dataToUpdate, "updateExample");
                let updateExampleResponseCode = res?.data?.code ? parseInt(res.data.code) : 99; //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
                responseCodeArray.push(updateExampleResponseCode);
            }
            if (examplesToDelete.length > 0) {
                for (let index = 0; index < examplesToDelete.length; index++) {
                    const dataToDelete = examplesToDelete[index];
                    //console.log("dataToDelete", JSON.stringify(dataToDelete, null, 2));
                    if (storageData.exclusiveServices.processNaturalLenguage && storageData.appId) {
                        dataToDelete["appID"] = storageData.appId;
                    }
                    let res = await actionsPLNManagement(dataToDelete, "deleteExample");
                    let deleteExampleResponseCode = res?.data?.code ? parseInt(res.data.code) : 99; //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
                    responseCodeArray.push(deleteExampleResponseCode);
                }
            }
        }

        if (intentNameHasChanges) {
            if (storageData.exclusiveServices.processNaturalLenguage && storageData.appId) {
                updateIntentName["appID"] = storageData.appId;
            }
            let res = await actionsPLNManagement(updateIntentName, "updateIntent");
            let updateIntentNameResponseCode = res?.data?.code ? parseInt(res.data.code) : 99; //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
            responseCodeArray.push(updateIntentNameResponseCode);
        }

        if (featuresHasChanges) {
            if (featuresToAdd.length > 0) {
                for (let index = 0; index < featuresToAdd.length; index++) {
                    const addFeature = featuresToAdd[index];
                    if (storageData.exclusiveServices.processNaturalLenguage && storageData.appId) {
                        addFeature["appID"] = storageData.appId;
                    }
                    let res = await actionsPLNManagement(addFeature, "addIntentFeature");
                    let addFeatureResponseCode = res?.data?.code ? parseInt(res.data.code) : 99; //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
                    responseCodeArray.push(addFeatureResponseCode);
                }
            }

            if (featuresToDelete.length > 0) {
                for (let index = 0; index < featuresToDelete.length; index++) {
                    const deleteFeature = featuresToDelete[index];
                    if (storageData.exclusiveServices.processNaturalLenguage && storageData.appId) {
                        deleteFeature["appID"] = storageData.appId;
                    }
                    let res = await actionsPLNManagement(deleteFeature, "deleteIntentFeature");
                    let deleteFeatureResponseCode = res?.data?.code ? parseInt(res.data.code) : 99; //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
                    responseCodeArray.push(deleteFeatureResponseCode);
                }
            }
        }

        updateContextAttribute("loadingDialog", false);
        updateContextAttribute("LoadingMessage", "");

        //Preguntamos si todos los cambios se aplicaron correctamente
        let allChangesApplied =
            responseCodeArray.every((code) => code === 0) && responseCodeArray.length > 0;
        //Preguntamos si alguno de los cambios tiene duplicados
        let someChangesHasDuplicate =
            responseCodeArray.some((code) => code === 2) && responseCodeArray.length > 0;

        switch (true) {
            case responseCodeArray.length === 0: {
                let alert = {
                    open: true,
                    severity: "info", //success, error, warning, info
                    message: "No se han realizado cambios, no hay nada que guardar.",
                };
                updateContextAttribute("alert", alert);
                setFeaturesHasChanges(false);
                setExamplesHasChanges(false);
                setIntentNameHasChanges(false);
                return;
            }
            case !allChangesApplied: {
                let alert = {
                    open: true,
                    severity: "error", //success, error, warning, info
                    message: "Ocurrió un error al guardar los datos",
                };
                updateContextAttribute("alert", alert);
                return;
            }
            case someChangesHasDuplicate: {
                let alert = {
                    open: true,
                    severity: "info", //success, error, warning, info
                    message:
                        "Hay entidades duplicadas, revisa que entidades hijas y padres no se repitan",
                };
                updateContextAttribute("alert", alert);
                return;
            }
            case allChangesApplied && !someChangesHasDuplicate: {
                let alert = {
                    open: true,
                    severity: "success", //success, error, warning, info
                    message: "Los datos se guardaron correctamente",
                };
                setOpenIntentModal(false);
                updateContextAttribute("alert", alert);
                clearState();
                refreshPLNData();
                return;
            }
            default:
                break;
        }
    };

    const removeExampleContent = async (exampleIndex) => {
        //Preguntar si quiere eliminar el ejemplo
        let alert = await Swal.fire({
            text: "¿Deseas eliminar este ejemplo?",
            showDenyButton: true,
            confirmButtonText: "Si",
            denyButtonText: `No`,
            confirmButtonColor: "#27315d",
            denyButtonColor: "#27315d",
        });
        //Cancelamos el eliminado del Par
        if (alert.isDenied || alert.isDismissed) {
            return;
        }

        //Lista de ejemplos del state
        let exampleListCopy = JSON.parse(JSON.stringify(exampleList));
        //Lista de ejemplos para el servcio
        let examplesToDeleteCopy = [...examplesToDelete];
        let examplesToUpdateCopy = [...examplesToUpdate];

        //Quitamos el ejemplo de la lista de ejemplos
        let removedExample = exampleListCopy.splice(exampleIndex, 1)[0];

        //Guardamos los cambios
        setExampleList(exampleListCopy);
        setExamplesHasChanges(true);

        if (removedExample.newExample) {
            //Si el ejemplo es nuevo, lo quitamos de la lista de ejemplos a actualizar
            let updateExampleIndex = examplesToUpdateCopy.findIndex(
                (example) => example.exampleId === removedExample.exampleId
            );
            examplesToUpdateCopy.splice(updateExampleIndex, 1);
            setExamplesToUpdate(examplesToUpdateCopy);
            return;
        }

        //Creamos el objeto para ser mandado al servicio
        let dataToDelete = {
            exampleId: removedExample.exampleId,
        };
        examplesToDeleteCopy.push(dataToDelete);
        setExamplesToDelete(examplesToDeleteCopy);
    };

    const changeEntityTag = (entityName) => {
        let exampleListCopy = [...exampleList];
        let exampleObj = exampleListCopy[selectedExampleIndex];
        let selectedEntityIndex = exampleObj.examples.findIndex(
            (entity) => entity.uniqid === selectedEntityUniqid
        );
        exampleObj.examples[selectedEntityIndex].entityName = entityName;
        //Validamos que no se pueda seleccionar una entidad con el mismo nombre
        // let entityPhraseExists = exampleObj.examples.find(entity => entity.entityName === entityName)
        // if(entityPhraseExists){
        //     removeEntityPhrase(entityPhraseExists.id)
        //     return
        // }
        createObjectToUpdate(exampleObj);
        setExampleList(exampleListCopy);
        setExamplesHasChanges(true);
        handleCloseEntityMenu();
    };

    const handleOpenClickEntityMenu = (
        event,
        exampleIndex,
        selectedEntityUniqid,
        selectedEntityType,
        selectedEntityName
    ) => {
        setMenuAnchor(event.currentTarget);
        setSelectedExampleIndex(exampleIndex);
        setSelectedEntityUniqid(selectedEntityUniqid);
        setSelectedExampleType(selectedEntityType);
        setSelectedEntityName(selectedEntityName);
    };

    const createSuggestionLabel = (selectedEntityName, selectedEntityUniqid) => {
        let exampleListCopy = JSON.parse(JSON.stringify(exampleList));
        let exampleObj = exampleListCopy[selectedExampleIndex];
        let selectedEntityIndex = exampleObj.examples.findIndex(
            (entity) => entity.uniqid === selectedEntityUniqid
        );
        const { startTokenIndex, endTokenIndex } = exampleObj.examples[selectedEntityIndex];
        //Obtenemos la info de la entidad seleccionada
        let entityObj = findEntityInfo(selectedEntityName);
        const { name, parents, level } = entityObj;
        createEntityPhrase(name, parents, level, startTokenIndex, endTokenIndex);
    };

    const createChildrenLabel = (children, exampleObj) => {
        children.forEach((child) => {
            //search for the child in the example
            let childIndex = exampleObj.examples.findIndex(
                (entity) => entity.entityName === child.name
            );
            //if the child is found, change the type to label
            if (childIndex !== -1) {
                //search for the grandchild in the example
                exampleObj.examples[childIndex].type = "label";
            }
            if (child.children.length > 0) {
                createChildrenLabel(child.children, exampleObj);
            }
        });
        return exampleObj;
    };

    const createSuggestionLabelAndChildren = (selectedEntityName, selectedEntityInfo) => {
        let exampleListCopy = JSON.parse(JSON.stringify(exampleList));
        let exampleObj = exampleListCopy[selectedExampleIndex];
        let selectedEntityIndex = exampleObj.examples.findIndex(
            (entity) => entity.entityName === selectedEntityName
        );
        exampleObj.examples[selectedEntityIndex].type = "label";
        const { children = [] } = selectedEntityInfo;
        if (children.length > 0) {
            exampleObj = createChildrenLabel(children, exampleObj);
        }
        //Guardamos el objeto para ser mandado al servicio de PLN
        exampleListCopy[selectedExampleIndex] = exampleObj;
        createObjectToUpdate(exampleObj);
        setExampleList(exampleListCopy);
        handleCloseEntityMenu();
        setExamplesHasChanges(true);
    };

    const copyExampleToClipboard = (index) => {
        let exampleListCopy = JSON.parse(JSON.stringify(exampleList));
        let exampleObj = exampleListCopy[index];
        let exampleText = exampleObj.text;
        navigator.clipboard.writeText(exampleText);
        let alert = {
            open: true,
            severity: "success", //success, error, warning, info
            message: "El ejemplo se copió al portapapeles",
        };
        updateContextAttribute("alert", alert);
    };

    const closeIntentModal = async () => {
        if (examplesHasChanges || featuresHasChanges || intentNameHasChanges) {
            let askConfirm = await Swal.fire({
                title: "¿Estás seguro que deseas salir?",
                text: "Los cambios realizados se perderán.",
                showDenyButton: true,
                confirmButtonText: "Si",
                denyButtonText: `No`,
                confirmButtonColor: "#27315d",
                denyButtonColor: "#27315d",
            });
            //Cancelamos el guardado de cambios
            if (askConfirm.isDenied || askConfirm.isDismissed) {
                return;
            }
        }
        clearState();
        setOpenIntentModal(false);
        setTempIntent(initialTempIntent.current);
    };

    const handleChangePage = (nextPage) => {
        let paginationCopy = { ...pagination };
        paginationCopy.currentPage = nextPage;
        getCurrentIntentExamples(paginationCopy);
        setPagination(paginationCopy);
    };

    const intentTableProps = {
        exampleList,
        handleSelection,
        addNewExampleContent,
        newExampleContent,
        setNewExampleContent,
        handleIntentForm,
        createIntentErrors,
        createIntentErrorHelper,
        selectedWordsIds,
        selectedExampleIndex,
        handleOpenEntityMenu,
        removeExampleContent,
        handleOpenClickEntityMenu,
        entityList,
        findEntityInfo,
        copyExampleToClipboard,
    };

    return (
        <React.Fragment>
            {/* Modals */}
            <Dialog open={openIntentModal} fullScreen>
                <DialogTitle disableTypography>
                    <h2>{"Editar intención"}</h2>
                    <IconButton onClick={() => closeIntentModal()}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Grid className="intent-modal-content" item xs={11} sm={8}>
                        <div>
                            <label className="top-label-field">Nombre de la intención</label>
                            <TextField
                                name="intentName"
                                value={intentName}
                                size="small"
                                type="small"
                                onChange={(event) =>
                                    handleIntentForm(event.target.name, event.target.value)
                                }
                                fullWidth
                                variant="outlined"
                                error={createIntentErrors["intentName"]}
                                helperText={createIntentErrorHelper["intentName"]}
                            />
                        </div>

                        <IntentExampleTableHeader
                            newExampleContent={newExampleContent}
                            handleIntentForm={handleIntentForm}
                            addNewExampleContent={addNewExampleContent}
                            createIntentErrors={createIntentErrors}
                            createIntentErrorHelper={createIntentErrorHelper}
                        />

                        <Grid container className="pagination-container">
                            <Pagination
                                siblingCount={4}
                                disabled={pagination.pages > 1 ? false : true}
                                count={pagination.pages}
                                page={pagination.currentPage}
                                onChange={(e, value) => handleChangePage(value)}
                            />
                            <RefreshButton
                                refreshFunction={() => getCurrentIntentExamples(pagination)}
                            />
                        </Grid>
                        <hr />
                        <SearchBox
                            disabled={errorInFetch}
                            searchFunction={setSearchQuery}
                            searchQuery={searchQuery}
                            placeholder="Buscar ejemplo en la lista."
                        />

                        {loadingExamples ? (
                            <div className="center-loading-icon">
                                <CircularProgress />
                            </div>
                        ) : errorInFetch ? (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <span>Hubo un error, intenta más tarde.</span>
                                <RefreshButton
                                    refreshFunction={getCurrentIntentExamples(pagination)}
                                />
                            </div>
                        ) : searchHasNoResults ? (
                            <SearchNoResults searchQuery={searchQuery} />
                        ) : (
                            <IntentExamplesTable {...intentTableProps} />
                        )}

                        {/* ========== Crear entidad utilizando el mouse ==========*/}
                        <IntentDragMouseMenu
                            //props
                            mousePosition={mousePosition}
                            handleCloseEntityMenu={handleCloseEntityMenu}
                            entityList={entityList}
                            createEntityPhrase={createEntityPhrase}
                        />
                        {/* Cambiar la entidad clickeando la etiqueta */}
                        <IntentClickTagMenu
                            //props
                            selectedEntityName={selectedEntityName}
                            selectedExampleType={selectedExampleType}
                            createSuggestionLabel={createSuggestionLabel}
                            selectedExampleIndex={selectedExampleIndex}
                            exampleList={exampleList}
                            menuAnchor={menuAnchor}
                            handleCloseEntityMenu={handleCloseEntityMenu}
                            entityList={entityList}
                            createEntityPhrase={createEntityPhrase}
                            selectedEntityUniqid={selectedEntityUniqid}
                            removeEntityPhrase={removeEntityPhrase}
                            changeEntityTag={changeEntityTag}
                            findEntityInfo={findEntityInfo}
                            createSuggestionLabelAndChildren={createSuggestionLabelAndChildren}
                        />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <div className={classes.root}>
                        <CustomButton
                            disabled={
                                (!examplesHasChanges &&
                                    !featuresHasChanges &&
                                    !intentNameHasChanges) ||
                                createIntentErrors["intentName"]
                            }
                            onClick={() => saveIntentChanges()}
                            variant="contained"
                        >
                            Guardar intención
                        </CustomButton>
                    </div>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default IntentModal;
