import React, { useState, useRef, useEffect, useContext } from "react";

//Material UI
import {
    TextField,
    MenuItem,
    CircularProgress,
    Button,
    Tooltip,
    Checkbox,
    FormControlLabel,
    Grid,
} from "@material-ui/core/";
import Pagination from "@material-ui/lab/Pagination";

//Custom portal components
import { actionsPLNManagement } from "../DataFunctions";
import RefreshButton from "../common/RefreshButton";
import ErrorInFetch from "../common/ErrorInFetch";
import IntentExample from "./Modals/IntentExample";
import IntentDragMouseMenu from "./Modals/IntentDragMouseMenu";
import IntentClickTagMenu from "./Modals/IntentClickTagMenu";
import { errorHelper } from "../IntebotCatalog";
import AppContext from "../../Context/AppContext";

//Icons
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { ReactComponent as TrashCan } from "../../Icons/trashcanIcon.svg";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import DoneIcon from "@material-ui/icons/Done";

//styles
import "./styles/ReviewEndpointUtterances.css";

//External libraries
import Swal from "sweetalert2"; //https://sweetalert2.github.io/

const ReviewEndpointUtterances = (props) => {
    const [filteredIntent, setFilteredIntent] = useState("");
    const [loading, setLoading] = useState(false);
    const [errorInFetch, setErrorInFetch] = useState(false);
    const [suggestionsList, setSuggestionsList] = useState([]);
    const [suggestionSelected, setSuggestionSelected] = useState([]);
    const [suggestionsConfirmed, setSuggestionsConfirmed] = useState(false);
    const [checkAllSuggestions, setCheckAllSuggestions] = useState(false);

    //Mouse selection state
    const [selectedWordsIds, setSelectedWordsIds] = useState([]); //Palabras seleccionadas con el mouse para darles el estilo de seleccionado
    const [selectedExampleIndex, setSelectedExampleIndex] = useState(null); //Indice del último ejemplo modificado
    const [mousePosition, setMousePosition] = useState({ mouseX: null, mouseY: null }); //Posición del mouse para abrir el menú de entidades
    const [menuAnchor, setMenuAnchor] = useState(null); //Anchor del menú de entidades

    //Examples
    const [examplesToUpdate, setExamplesToUpdate] = useState([]); //Ejemplos que se van a actualizar al servicio de PLN
    const [examplesToDelete, setExamplesToDelete] = useState([]); //Ejemplos que se van a eliminar al servicio de PLN
    const [examplesHasChanges, setExamplesHasChanges] = useState(false);

    //Click tag menu state
    const [selectedEntityName, setSelectedEntityName] = useState(""); //Nombre de la entidad seleccionada
    const [selectedEntityUniqid, setSelectedEntityUniqid] = useState(null); //Uniqid de la entidad seleccionada
    const [selectedExampleType, setSelectedExampleType] = useState(null); //Tipo de ejemplo modificado [suggest, label]

    //State for the entity menu
    const [createIntentErrorHelper, setCreateIntentErrorHelper] = useState({
        exampleContent: errorHelper.removeError,
        intentName: errorHelper.removeError,
        intentExample: "",
    });

    const completeSuggestionList = useRef([]);

    const { intentsList, entityList } = props;

    const { updateContextAttribute, getStorageData } = useContext(AppContext);
    const storageData = getStorageData(["exclusiveServices", "appId"]);

    const [pagination, setPagintation] = useState({
        pages: 0,
        currentPage: 1,
        itemsPerPage: 10,
    });
    const { pages, currentPage, itemsPerPage } = pagination;

    useEffect(() => {
        searchSuggestionConfirmed();
    }, [suggestionsList]);

    const calculatePagination = (arrayLength) => {
        //Dividimos las preguntas entre el numero de items por pagina y redondeamos al número mayor 3.1 -> 4
        return Math.ceil(arrayLength / itemsPerPage);
    };

    const handleChangePage = (targetValue) => {
        let endSlice = targetValue * itemsPerPage;
        let startSlice = endSlice - itemsPerPage;
        let suggestionsListCopy = [...completeSuggestionList.current];
        let renderSuggestions = suggestionsListCopy.slice(startSlice, endSlice);
        setSuggestionsList(renderSuggestions);
        setPagintation({
            ...pagination,
            currentPage: targetValue,
        });
    };

    const getSuggestions = async (intentId) => {
        setFilteredIntent(intentId);
        let data = {
            intentId: intentId,
            appID: storageData.appId,
        };
        setLoading(true);
        setErrorInFetch(false);
        let res = await actionsPLNManagement(data, "getSuggestionList");
        setLoading(false);
        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
        switch (responseCode) {
            case 0:
                let suggestionsListData = res.data.data;
                let suggestionsList = [];

                suggestionsListData.forEach((suggestion, index) => {
                    let { text, intent, intentId, entityPredictions, tokenizedText } = suggestion;

                    entityPredictions.forEach((entity, index) => {
                        let entityName = entity.entityName;
                        let uniqid =
                            entityName.substring(0, 3) + index + Math.floor(Math.random() * 1000);
                        entity.uniqid = uniqid;
                    });

                    //create a unique id for each suggestion suggestionId with 6 random characters
                    let suggestionId =
                        Math.random().toString(36).substring(2, 8) +
                        Math.random().toString(36).substring(2, 8);
                    let exampleObj = {
                        text: text,
                        tokenizedText: tokenizedText,
                        id: index,
                        suggestionId: suggestionId,
                        intent: intent,
                        examples: entityPredictions,
                        intentId: intentId,
                    };
                    suggestionsList.push(exampleObj);
                });
                completeSuggestionList.current = JSON.parse(JSON.stringify(suggestionsList));
                let pages = calculatePagination(completeSuggestionList.current.length);
                setPagintation({
                    ...pagination,
                    currentPage: 1,
                    pages: pages,
                });
                //Get the first 10 suggestions
                let slicedSuggestionsList = suggestionsList.slice(0, 10);
                setSuggestionsList(slicedSuggestionsList);
                break;

            default:
                setErrorInFetch(true);
                break;
        }
    };

    const findEntityInfo = (entityName) => {
        for (let index = 0; index < entityList.length; index++) {
            const entity = entityList[index];
            if (entity.name === entityName) {
                return entity;
            }
            if (!entity.hasOwnProperty("children")) continue;
            for (let index = 0; index < entity.children.length; index++) {
                const child = entity.children[index];
                if (child.name === entityName) {
                    return child;
                }
                for (let index = 0; index < child.children.length; index++) {
                    const child2 = child.children[index];
                    if (child2.name === entityName) {
                        return child2;
                    }
                    for (let index = 0; index < child2.children.length; index++) {
                        const child3 = child2.children[index];
                        if (child3.name === entityName) {
                            return child3;
                        }
                        for (let index = 0; index < child3.children.length; index++) {
                            const child4 = child3.children[index];
                            if (child4.name === entityName) {
                                return child4;
                            }
                        }
                    }
                }
            }
        }
        return {};
    };

    const confirmAllSuggestions = () => {
        setCheckAllSuggestions(false);
        let suggestionsListCopy = JSON.parse(JSON.stringify(suggestionsList));
        suggestionsListCopy.forEach((suggestion) => {
            let exampleList = suggestion.examples;
            for (let index = 0; index < exampleList.length; index++) {
                exampleList[index].type = "label";
            }
            suggestion.examples = exampleList;
        });
        setSuggestionsList(suggestionsListCopy);
    };

    const confirmSelectedSuggestion = (suggestionSelected) => {
        let suggestionsListCopy = JSON.parse(JSON.stringify(suggestionsList));
        suggestionSelected.forEach((suggestionId) => {
            //find suggestionId in suggestionsListCopy
            let suggestion = suggestionsListCopy.find(
                (suggestion) => suggestion.suggestionId === suggestionId
            );
            let exampleList = suggestion.examples;
            for (let index = 0; index < exampleList.length; index++) {
                exampleList[index].type = "label";
            }
            suggestion.examples = exampleList;
        });
        setSuggestionSelected([]);
        setCheckAllSuggestions(false);
        setSuggestionsList(suggestionsListCopy);
    };

    const handleSuggestionCheckbox = (suggestionChecked, suggestionId) => {
        if (suggestionChecked) {
            setSuggestionSelected([...suggestionSelected, suggestionId]);
            return;
        }
        let suggestionSelectedCopy = suggestionSelected.filter(
            (suggestionIdSelected) => suggestionIdSelected !== suggestionId
        );
        setSuggestionSelected(suggestionSelectedCopy);
    };

    const searchSuggestionConfirmed = () => {
        let suggestionsListCopy = JSON.parse(JSON.stringify(suggestionsList));
        let suggestionsListFiltered = suggestionsListCopy.filter((suggestion) =>
            suggestion.examples.some((example) => example.type === "label")
        );
        let suggestionsListFilteredIds = suggestionsListFiltered.map(
            (suggestion) => suggestion.suggestionId
        );
        setSuggestionsConfirmed(suggestionsListFilteredIds);
    };

    const decideDisableConfirmButton = () => {
        switch (true) {
            //Si ya se confirmaron las 10 sugerencias por página
            case suggestionsConfirmed.length === 10:
                return true;
            //Si no hay sugerencias por confirmar
            case suggestionsList.length === 0:
                return true;
            case suggestionSelected.length === 0:
                return false;
            //Si todas las sugerencias por selecionadas ya están confirmadas
            case suggestionSelected.every((suggestionId) =>
                suggestionsConfirmed.includes(suggestionId)
            ):
                return true;
            default:
                return false;
        }
    };

    const handleCheckAllSuggestions = (targetValue) => {
        if (targetValue) {
            let suggestionsListCopy = JSON.parse(JSON.stringify(suggestionsList));
            let suggestionsListIds = suggestionsListCopy.map(
                (suggestion) => suggestion.suggestionId
            );
            setSuggestionSelected(suggestionsListIds);
            setCheckAllSuggestions(true);
            return;
        }
        setSuggestionSelected([]);
        setCheckAllSuggestions(false);
    };

    const handleSelection = (exampleIndex, event) => {
        const selection = window.getSelection();
        let selectionString = selection.toString();
        //elimina todas las palabras de la seleccón que empiezan con [ y terminan con ] del array de selectionString y también las que empiezan con ":" y terminan con ":"
        selectionString = selectionString.replace(/\[.*?\]/g, "").replace(/:[^:]*:/g, "");
        //dividimos el selectionString por \n
        let selectionArray = selectionString.split(/[\r\n\s]+/);
        //eliminiamos los elementos vacios del array
        selectionArray = selectionArray.filter((word) => word !== "");

        //Creamos la selección de spans
        try {
            var range = selection.getRangeAt(0);
        } catch (error) {
            return;
        }
        //el primer token lo obtenemos el primer <span> de la selección
        const startTokenIndex = parseInt(range.startContainer.parentElement.id);
        //el endToken lo calculamos empezando por el primer token y sumando la cantidad de tokens seleccionados **Nota: anteriormente se obtenia del span final de la selección utilizando "selection.focusNode.parentElement.id" pero en chrome ocacionaba muchos bugs
        const endTokenIndex = startTokenIndex + selectionArray.length - 1;

        //si el token no fue seleccionado de manera correcta cancelamos la selección
        if (isNaN(endTokenIndex) || isNaN(startTokenIndex)) {
            selection.removeAllRanges();
            return;
        }
        let selectedWordsId = [];
        //buscamos los indices de las palabras seleccionadas
        for (let i = startTokenIndex; i <= endTokenIndex; i++) {
            selectedWordsId.push(i);
        }
        if (selectedWordsId.length === 0) {
            selection.removeAllRanges();
            return;
        }

        setSelectedWordsIds(selectedWordsId);
        setSelectedExampleIndex(exampleIndex);
        handleOpenEntityMenu(event, selection);
    };

    const createObjectToUpdate = (exampleObj) => {
        let examplesToUpdateCopy = [...examplesToUpdate];
        //Buscamos si el ejemplo ya existe en el arreglo de ejemplos a actualizar
        let index = examplesToUpdateCopy.findIndex(
            (exampleToUpdate) => exampleToUpdate.text === exampleObj.text
        );
        let intentName = intentsList.find(
            (intent) => intent.intentId === filteredIntent
        ).intentName;

        let dataToUpdate = {
            id: exampleObj.id,
            intentName: intentName,
            text: exampleObj.text,
            examples: exampleObj.examples,
        };
        //Si el ejemplo no existe en el arreglo de ejemplos a actualizar lo agregamos
        if (index === -1) {
            examplesToUpdateCopy.push(dataToUpdate);
        } else {
            examplesToUpdateCopy[index] = dataToUpdate;
        }
        setExamplesToUpdate(examplesToUpdateCopy);
    };

    const removeChildInsideParent = (child, parent, exampleObj) => {
        const { name } = child;
        const { startTokenIndex, endTokenIndex } = parent;
        let childTagExists = exampleObj.examples.filter((entity) => entity.entityName === name);
        for (let index = 0; index < childTagExists.length; index++) {
            const childTag = childTagExists[index];
            let childInsideParent =
                startTokenIndex <= childTag.startTokenIndex &&
                endTokenIndex >= childTag.endTokenIndex;
            if (childInsideParent) {
                let childTagIndex = exampleObj.examples.findIndex(
                    (entity) => entity.uniqid === childTag.uniqid
                );
                exampleObj.examples.splice(childTagIndex, 1);
            }
        }
        return exampleObj;
    };

    const removeEntityPhrase = (selectedEntityUniqid) => {
        let exampleListCopy = JSON.parse(JSON.stringify(suggestionsList));
        let exampleObj = exampleListCopy[selectedExampleIndex];
        let selectedEntityIndex = exampleObj.examples.findIndex(
            (entity) => entity.uniqid === selectedEntityUniqid
        );
        let removedEntity = exampleObj.examples.splice(selectedEntityIndex, 1)[0];
        let { startTokenIndex, endTokenIndex, entityName } = removedEntity;

        let entityObj = findEntityInfo(entityName);

        const { children = [] } = entityObj;
        for (let index = 0; index < children.length; index++) {
            const child = children[index];
            const { children: children2 } = child;
            exampleObj = removeChildInsideParent(child, removedEntity, exampleObj);
            if (children2.length === 0) continue;
            for (let index = 0; index < children2.length; index++) {
                const child2 = children2[index];
                exampleObj = removeChildInsideParent(child2, removedEntity, exampleObj);
                const { children: children3 } = child2;
                if (children3.length === 0) continue;
                for (let index = 0; index < children3.length; index++) {
                    const child3 = children3[index];
                    exampleObj = removeChildInsideParent(child3, removedEntity, exampleObj);
                    const { children: children4 } = child3;
                    if (children4.length === 0) continue;
                    for (let index = 0; index < children4.length; index++) {
                        const child4 = children4[index];
                        exampleObj = removeChildInsideParent(child4, removedEntity, exampleObj);
                    }
                }
            }
        }

        //search for the same start and end token index in the exampleObj.examples
        let entityExists = exampleObj.examples.filter(
            (entity) =>
                entity.startTokenIndex === startTokenIndex && entity.endTokenIndex === endTokenIndex
        );
        if (entityExists.length > 0) {
            entityExists.forEach((entityExist) => {
                let existsEntityIndex = exampleObj.examples.findIndex(
                    (entity) => entity.uniqid === entityExist.uniqid
                );
                exampleObj.examples.splice(existsEntityIndex, 1);
            });
        }

        exampleListCopy[selectedExampleIndex] = exampleObj;
        createObjectToUpdate(exampleObj);
        setSuggestionsList(exampleListCopy);
        setExamplesHasChanges(true);
        handleCloseEntityMenu();
    };

    const createEntityPhrase = (
        entityName,
        parents,
        level,
        selectedStartTokenIndex,
        selectedEndTokenIndex
    ) => {
        //console.log(entityName, parents, level, selectedStartTokenIndex, selectedEndTokenIndex);
        let createIntentErrorHelperCopy = { ...createIntentErrorHelper };
        createIntentErrorHelperCopy["intentExample"] = errorHelper.removeError;
        let exampleListCopy = JSON.parse(JSON.stringify(suggestionsList));
        let exampleObj = exampleListCopy[selectedExampleIndex];
        let startTokenIndex =
            selectedWordsIds[0] > -1 ? selectedWordsIds[0] : selectedStartTokenIndex;
        let endTokenIndex =
            selectedWordsIds[selectedWordsIds.length - 1] > -1
                ? selectedWordsIds[selectedWordsIds.length - 1]
                : selectedEndTokenIndex;
        let parentsCopy = JSON.parse(JSON.stringify(parents));
        let selectedEntityIsRepeated = false;
        let noCreateParents = false;

        //Si la entidad seleccionada es hija y contiene padres, entonces verificamos si la entidad padre ya existe en el ejemplo
        if (parents.length > 0) {
            for (let index = 0; index < parents.length; index++) {
                const parent = parents[index];
                //Si el startIndex y el EndIndex de la entidad repetida es igual al startIndex y el endIndex de la entidad seleccionada, entonces mandamos a eliminar
                if (
                    parent.startTokenIndex === startTokenIndex &&
                    parent.endTokenIndex === endTokenIndex
                ) {
                    //Eliminamos la entidad repetida y no creamos la nueva entidad
                    selectedEntityIsRepeated = true;
                    removeEntityPhrase(parent.uniqid);
                    return;
                }

                let entityParentExists = exampleObj.examples.find(
                    (entity) => entity.entityName === parent.name && entity.type === "label"
                );
                let entityParentExistsIndex = exampleObj.examples.findIndex(
                    (entity) => entity.entityName === parent.name
                );
                //Obtener los entities del mismo nivel
                let entitySameLevel = exampleObj.examples.find((entity) => entity.level === level);
                if (entitySameLevel) {
                    //Si el startIndex y el EndIndex de la entidad repetida es igual al startIndex y el endIndex de la entidad seleccionada, entonces mandamos a eliminar
                    if (
                        entitySameLevel.startTokenIndex === startTokenIndex &&
                        entitySameLevel.endTokenIndex === endTokenIndex
                    ) {
                        //Eliminamos la entidad repetida y si creamos la nueva entidad
                        selectedEntityIsRepeated = true;
                        removeEntityPhrase(entitySameLevel.uniqid);
                        return;
                    }
                }

                if (!entityParentExists) continue;

                //Obtenemos el starIndex y el endIndex de cada padre para ver si la entidad seleccionada esta dentro del la entidad padre
                let parentStartIndexIsHigher =
                    entityParentExists.startTokenIndex <= startTokenIndex &&
                    entityParentExists.endTokenIndex >= startTokenIndex;
                let parentEndIndexIsLower =
                    entityParentExists.endTokenIndex >= endTokenIndex &&
                    entityParentExists.startTokenIndex <= endTokenIndex;

                //Preguntamos si los padres son suggestion y si estan dentro del entity seleccionado
                if (entityParentExists.type === "suggest") {
                    if (parentStartIndexIsHigher && parentEndIndexIsLower) {
                        exampleObj.examples[entityParentExistsIndex].type = "label";
                        noCreateParents = true;
                        continue;
                    }
                }

                let entityRepeatedInsideSelectedEntity =
                    entityParentExists.startTokenIndex >= startTokenIndex &&
                    entityParentExists.endTokenIndex <= endTokenIndex;
                if (entityRepeatedInsideSelectedEntity) {
                    let selectedEntityIndex = exampleObj.examples.findIndex(
                        (entity) => entity.uniqid === entityParentExists.uniqid
                    );
                    let removedEntity = exampleObj.examples.splice(selectedEntityIndex, 1)[0];
                    continue;
                }

                //Si el start y end son falsos entonces el padre no esta dentro del entity y no hacemos nada
                if (!parentStartIndexIsHigher && !parentEndIndexIsLower) {
                    continue;
                }

                //si el start y end son verdaderos entonces el padre esta dentro del entity y lo eliminamos del array de parents
                parentsCopy.splice(
                    parentsCopy.findIndex(
                        (parent) => parent.name === entityParentExists.entityName
                    ),
                    1
                );

                //si uno es verdadero y el otro es falso, entonces el padre esta parcialmente dentro del entity y extendemos el padre al index del parent elegido
                if (parentStartIndexIsHigher && !parentEndIndexIsLower) {
                    exampleObj.examples[entityParentExistsIndex].endTokenIndex = endTokenIndex;
                    const { startTokenIndex: parentStartIndex, endTokenIndex: parentEndIndex } =
                        exampleObj.examples[entityParentExistsIndex];
                    exampleObj.examples[entityParentExistsIndex].phrase = exampleObj.tokenizedText
                        .slice(parentStartIndex, parentEndIndex + 1)
                        .join(" ");
                    continue;
                }
                if (!parentStartIndexIsHigher && parentEndIndexIsLower) {
                    exampleObj.examples[entityParentExistsIndex].startTokenIndex = startTokenIndex;
                    const { startTokenIndex: parentStartIndex, endTokenIndex: parentEndIndex } =
                        exampleObj.examples[entityParentExistsIndex];
                    exampleObj.examples[entityParentExistsIndex].phrase = exampleObj.tokenizedText
                        .slice(parentStartIndex, parentEndIndex + 1)
                        .join(" ");
                    continue;
                }
            }
        }

        //Buscamos todas las entidades con el mismo nombre en el ejemplo
        let entityPhraseRepeated = exampleObj.examples.filter(
            (entity) => entity.entityName === entityName
        );

        for (let index = 0; index < entityPhraseRepeated.length; index++) {
            const entityRepeated = entityPhraseRepeated[index];
            if (
                entityRepeated.type === "suggest" &&
                entityRepeated.startTokenIndex === startTokenIndex &&
                entityRepeated.endTokenIndex === endTokenIndex
            ) {
                let selectedEntityIndex = exampleObj.examples.findIndex(
                    (entity) => entity.uniqid === entityRepeated.uniqid
                );
                let removedEntity = exampleObj.examples.splice(selectedEntityIndex, 1)[0];
                if (parents.length > 0) {
                    parents.forEach((parent) => {
                        //splice parents
                        let parentsInExample = exampleObj.examples.filter(
                            (entity) => entity.entityName === parent.name
                        );
                        for (const parentExample of parentsInExample) {
                            if (
                                parentExample.type === "suggest" &&
                                parentExample.startTokenIndex === startTokenIndex &&
                                parentExample.endTokenIndex === endTokenIndex
                            ) {
                                let parentIndex = exampleObj.examples.findIndex(
                                    (entity) => entity.uniqid === parentExample.uniqid
                                );
                                let removedParents = exampleObj.examples.splice(parentIndex, 1);
                            }
                        }
                    });
                }
                continue;
            }

            if (entityRepeated.type === "suggest") continue;

            //Si el startIndex y el EndIndex de la entidad repetida es igual al startIndex y el endIndex de la entidad seleccionada, entonces mandamos a eliminar
            if (
                entityRepeated.startTokenIndex === startTokenIndex &&
                entityRepeated.endTokenIndex === endTokenIndex
            ) {
                //Eliminamos la entidad repetida y no creamos la nueva entidad
                selectedEntityIsRepeated = true;
                removeEntityPhrase(entityRepeated.uniqid);
                return;
            }

            //Obtenemos el starIndex y el endIndex de la entidad repetida
            let entityRepeatedIndexIsHigher =
                entityRepeated.startTokenIndex <= startTokenIndex &&
                entityRepeated.endTokenIndex >= startTokenIndex;
            let entityRepeatedIndexIsLower =
                entityRepeated.endTokenIndex >= endTokenIndex &&
                entityRepeated.startTokenIndex <= endTokenIndex;

            let entityRepeatedInsideSelectedEntity =
                entityRepeated.startTokenIndex >= startTokenIndex &&
                entityRepeated.endTokenIndex <= endTokenIndex;
            if (entityRepeatedInsideSelectedEntity) {
                let selectedEntityIndex = exampleObj.examples.findIndex(
                    (entity) => entity.uniqid === entityRepeated.uniqid
                );
                let removedEntity = exampleObj.examples.splice(selectedEntityIndex, 1)[0];
                continue;
            }

            if (entityRepeatedIndexIsHigher && entityRepeatedIndexIsLower) {
                selectedEntityIsRepeated = true;
                removeEntityPhrase(entityRepeated.uniqid);
                continue;
            }

            let entityRepeatedIndexInExamples = exampleObj.examples.findIndex(
                (entity) => entity.entityName === entityRepeated.entityName
            );
            //Si uno de los dos es mayor o menor, entonces la entidad repetida esta parcialmente dentro de la entidad seleccionada, entonces extendemos la entidad repetida
            if (entityRepeatedIndexIsHigher && !entityRepeatedIndexIsLower) {
                exampleObj.examples[entityRepeatedIndexInExamples].endTokenIndex = endTokenIndex;
                const { startTokenIndex: repeatedStartIndex, endTokenIndex: reapetedEndIndex } =
                    exampleObj.examples[entityRepeatedIndexInExamples];
                exampleObj.examples[entityRepeatedIndexInExamples].phrase = exampleObj.tokenizedText
                    .slice(repeatedStartIndex, reapetedEndIndex + 1)
                    .join(" ");
                selectedEntityIsRepeated = true;
                continue;
            }
            if (!entityRepeatedIndexIsHigher && entityRepeatedIndexIsLower) {
                exampleObj.examples[entityRepeatedIndexInExamples].startTokenIndex =
                    startTokenIndex;
                const { startTokenIndex: repeatedStartIndex, endTokenIndex: reapetedEndIndex } =
                    exampleObj.examples[entityRepeatedIndexInExamples];
                exampleObj.examples[entityRepeatedIndexInExamples].phrase = exampleObj.tokenizedText
                    .slice(repeatedStartIndex, reapetedEndIndex + 1)
                    .join(" ");
                selectedEntityIsRepeated = true;
                continue;
            }

            //Si ninguno de los dos es mayor o menor, entonces la entidad repetida esta fuera de la entidad seleccionada, entonces no hacemos nada
            if (!entityRepeatedIndexIsHigher && !entityRepeatedIndexIsLower) {
                selectedEntityIsRepeated = false;
                continue;
            }
        }

        //Creamos el objeto del ejemplo con cada padre de la entidad seleccionada
        if (!noCreateParents && parents.length > 0) {
            parentsCopy.forEach((parent, index) => {
                const { name, level } = parent;
                let uniqid = name.substring(0, 3) + index + Math.floor(Math.random() * 1000);
                //Creamos el objeto del ejemplo con la entidad seleccionada
                let entityPhrase = {
                    phrase: exampleObj.tokenizedText
                        .slice(startTokenIndex, endTokenIndex + 1)
                        .join(" "),
                    startTokenIndex: startTokenIndex,
                    endTokenIndex: endTokenIndex,
                    entityName: name,
                    entityId: exampleObj.examples.length + 1 - 1,
                    level: level,
                    uniqid: uniqid,
                    type: "label",
                };
                exampleObj.examples.push(entityPhrase);
            });
        }

        //Evitamos que se cree la entidad si ya existe
        if (!selectedEntityIsRepeated) {
            let newPhraseIndex = exampleObj.examples.length + 1 - 1;
            let uniqid =
                entityName.substring(0, 3) + newPhraseIndex + Math.floor(Math.random() * 1000);
            //Creamos el objeto del ejemplo con la entidad seleccionada
            let entityPhrase = {
                phrase: exampleObj.tokenizedText
                    .slice(startTokenIndex, endTokenIndex + 1)
                    .join(" "),
                startTokenIndex: startTokenIndex,
                endTokenIndex: endTokenIndex,
                entityName: entityName,
                entityId: newPhraseIndex,
                level: level,
                uniqid: uniqid,
                type: "label",
            };
            exampleObj.examples.push(entityPhrase);
        }

        //     //Guardamos el objeto para ser mandado al servicio de PLN
        exampleListCopy[selectedExampleIndex] = exampleObj;
        createObjectToUpdate(exampleObj);
        setSuggestionsList(exampleListCopy);
        handleCloseEntityMenu();
        setExamplesHasChanges(true);
        setCreateIntentErrorHelper(createIntentErrorHelperCopy);
    };

    // const createEntityPhrase = (
    //     entityName,
    //     parents,
    //     level,
    //     selectedStartTokenIndex,
    //     selectedEndTokenIndex
    // ) => {
    //     let createIntentErrorHelperCopy = { ...createIntentErrorHelper };
    //     createIntentErrorHelperCopy["intentExample"] = errorHelper.removeError;
    //     let exampleListCopy = JSON.parse(JSON.stringify(suggestionsList));
    //     let exampleObj = exampleListCopy[selectedExampleIndex];
    //     let startTokenIndex =
    //         selectedWordsIds[0] > -1 ? selectedWordsIds[0] : selectedStartTokenIndex;
    //     let endTokenIndex =
    //         selectedWordsIds[selectedWordsIds.length - 1] > -1
    //             ? selectedWordsIds[selectedWordsIds.length - 1]
    //             : selectedEndTokenIndex;
    //     let parentsCopy = JSON.parse(JSON.stringify(parents));
    //     let selectedEntityIsRepeated = false;
    //     let noCreateParents = false;

    //     //Si la entidad seleccionada es hija y contiene padres, entonces verificamos si la entidad padre ya existe en el ejemplo
    //     if (parents.length > 0) {
    //         for (let index = 0; index < parents.length; index++) {
    //             const parent = parents[index];
    //             //Si el startIndex y el EndIndex de la entidad repetida es igual al startIndex y el endIndex de la entidad seleccionada, entonces mandamos a eliminar
    //             if (
    //                 parent.startTokenIndex === startTokenIndex &&
    //                 parent.endTokenIndex === endTokenIndex
    //             ) {
    //                 //Eliminamos la entidad repetida y no creamos la nueva entidad
    //                 selectedEntityIsRepeated = true;
    //                 removeEntityPhrase(parent.uniqid);
    //                 return;
    //             }

    //             let entityParentExists = exampleObj.examples.find(
    //                 (entity) => entity.entityName === parent.name
    //             );
    //             let entityParentExistsIndex = exampleObj.examples.findIndex(
    //                 (entity) => entity.entityName === parent.name
    //             );
    //             //Obtener los entities del mismo nivel
    //             let entitySameLevel = exampleObj.examples.find((entity) => entity.level === level);
    //             if (entitySameLevel) {
    //                 //Si el startIndex y el EndIndex de la entidad repetida es igual al startIndex y el endIndex de la entidad seleccionada, entonces mandamos a eliminar
    //                 if (
    //                     entitySameLevel.startTokenIndex === startTokenIndex &&
    //                     entitySameLevel.endTokenIndex === endTokenIndex
    //                 ) {
    //                     //Eliminamos la entidad repetida y si creamos la nueva entidad
    //                     selectedEntityIsRepeated = true;
    //                     removeEntityPhrase(entitySameLevel.uniqid);
    //                     return;
    //                 }
    //             }

    //             if (!entityParentExists) continue;

    //             //Obtenemos el starIndex y el endIndex de cada padre para ver si la entidad seleccionada esta dentro del la entidad padre
    //             let parentStartIndexIsHigher =
    //                 entityParentExists.startTokenIndex <= startTokenIndex &&
    //                 entityParentExists.endTokenIndex >= startTokenIndex;
    //             let parentEndIndexIsLower =
    //                 entityParentExists.endTokenIndex >= endTokenIndex &&
    //                 entityParentExists.startTokenIndex <= endTokenIndex;

    //             //Preguntamos si los padres son suggestion y si estan dentro del entity seleccionado
    //             if (entityParentExists.type === "suggest") {
    //                 if (parentStartIndexIsHigher && parentEndIndexIsLower) {
    //                     exampleObj.examples[entityParentExistsIndex].type = "label";
    //                     noCreateParents = true;
    //                     continue;
    //                 }
    //             }

    //             let entityRepeatedInsideSelectedEntity =
    //                 entityParentExists.startTokenIndex >= startTokenIndex &&
    //                 entityParentExists.endTokenIndex <= endTokenIndex;
    //             if (entityRepeatedInsideSelectedEntity) {
    //                 let selectedEntityIndex = exampleObj.examples.findIndex(
    //                     (entity) => entity.uniqid === entityParentExists.uniqid
    //                 );
    //                 let removedEntity = exampleObj.examples.splice(selectedEntityIndex, 1)[0];
    //                 continue;
    //             }

    //             //Si el start y end son falsos entonces el padre no esta dentro del entity y no hacemos nada
    //             if (!parentStartIndexIsHigher && !parentEndIndexIsLower) {
    //                 continue;
    //             }

    //             //si el start y end son verdaderos entonces el padre esta dentro del entity y lo eliminamos del array de parents
    //             parentsCopy.splice(
    //                 parentsCopy.findIndex(
    //                     (parent) => parent.name === entityParentExists.entityName
    //                 ),
    //                 1
    //             );

    //             //si uno es verdadero y el otro es falso, entonces el padre esta parcialmente dentro del entity y extendemos el padre al index del parent elegido
    //             if (parentStartIndexIsHigher && !parentEndIndexIsLower) {
    //                 exampleObj.examples[entityParentExistsIndex].endTokenIndex = endTokenIndex;
    //                 const { startTokenIndex: parentStartIndex, endTokenIndex: parentEndIndex } =
    //                     exampleObj.examples[entityParentExistsIndex];
    //                 exampleObj.examples[entityParentExistsIndex].phrase = exampleObj.tokenizedText
    //                     .slice(parentStartIndex, parentEndIndex + 1)
    //                     .join(" ");
    //                 continue;
    //             }
    //             if (!parentStartIndexIsHigher && parentEndIndexIsLower) {
    //                 exampleObj.examples[entityParentExistsIndex].startTokenIndex = startTokenIndex;
    //                 const { startTokenIndex: parentStartIndex, endTokenIndex: parentEndIndex } =
    //                     exampleObj.examples[entityParentExistsIndex];
    //                 exampleObj.examples[entityParentExistsIndex].phrase = exampleObj.tokenizedText
    //                     .slice(parentStartIndex, parentEndIndex + 1)
    //                     .join(" ");
    //                 continue;
    //             }
    //         }
    //     }

    //     //Buscamos todas las entidades con el mismo nombre en el ejemplo
    //     let entityPhraseRepeated = exampleObj.examples.filter(
    //         (entity) => entity.entityName === entityName
    //     );

    //     for (let index = 0; index < entityPhraseRepeated.length; index++) {
    //         const entityRepeated = entityPhraseRepeated[index];
    //         if (
    //             entityRepeated.type === "suggest" &&
    //             entityRepeated.startTokenIndex === startTokenIndex &&
    //             entityRepeated.endTokenIndex === endTokenIndex
    //         ) {
    //             let selectedEntityIndex = exampleObj.examples.findIndex(
    //                 (entity) => entity.uniqid === entityRepeated.uniqid
    //             );
    //             let removedEntity = exampleObj.examples.splice(selectedEntityIndex, 1)[0];
    //             if (parents.length > 0) {
    //                 parents.forEach((parent) => {
    //                     //splice parents
    //                     let parentsInExample = exampleObj.examples.filter(
    //                         (entity) => entity.entityName === parent.name
    //                     );
    //                     for (const parentExample of parentsInExample) {
    //                         if (
    //                             parentExample.type === "suggest" &&
    //                             parentExample.startTokenIndex === startTokenIndex &&
    //                             parentExample.endTokenIndex === endTokenIndex
    //                         ) {
    //                             let parentIndex = exampleObj.examples.findIndex(
    //                                 (entity) => entity.uniqid === parentExample.uniqid
    //                             );
    //                             let removedParents = exampleObj.examples.splice(parentIndex, 1);
    //                         }
    //                     }
    //                 });
    //             }
    //             continue;
    //         }

    //         if (entityRepeated.type === "suggest") continue;

    //         //Si el startIndex y el EndIndex de la entidad repetida es igual al startIndex y el endIndex de la entidad seleccionada, entonces mandamos a eliminar
    //         if (
    //             entityRepeated.startTokenIndex === startTokenIndex &&
    //             entityRepeated.endTokenIndex === endTokenIndex
    //         ) {
    //             //Eliminamos la entidad repetida y no creamos la nueva entidad
    //             selectedEntityIsRepeated = true;
    //             removeEntityPhrase(entityRepeated.uniqid);
    //             return;
    //         }

    //         //Obtenemos el starIndex y el endIndex de la entidad repetida
    //         let entityRepeatedIndexIsHigher =
    //             entityRepeated.startTokenIndex <= startTokenIndex &&
    //             entityRepeated.endTokenIndex >= startTokenIndex;
    //         let entityRepeatedIndexIsLower =
    //             entityRepeated.endTokenIndex >= endTokenIndex &&
    //             entityRepeated.startTokenIndex <= endTokenIndex;

    //         let entityRepeatedInsideSelectedEntity =
    //             entityRepeated.startTokenIndex >= startTokenIndex &&
    //             entityRepeated.endTokenIndex <= endTokenIndex;
    //         if (entityRepeatedInsideSelectedEntity) {
    //             let selectedEntityIndex = exampleObj.examples.findIndex(
    //                 (entity) => entity.uniqid === entityRepeated.uniqid
    //             );
    //             let removedEntity = exampleObj.examples.splice(selectedEntityIndex, 1)[0];
    //             continue;
    //         }

    //         if (entityRepeatedIndexIsHigher && entityRepeatedIndexIsLower) {
    //             selectedEntityIsRepeated = true;
    //             removeEntityPhrase(entityRepeated.uniqid);
    //             continue;
    //         }

    //         let entityRepeatedIndexInExamples = exampleObj.examples.findIndex(
    //             (entity) => entity.entityName === entityRepeated.entityName
    //         );
    //         //Si uno de los dos es mayor o menor, entonces la entidad repetida esta parcialmente dentro de la entidad seleccionada, entonces extendemos la entidad repetida
    //         if (entityRepeatedIndexIsHigher && !entityRepeatedIndexIsLower) {
    //             exampleObj.examples[entityRepeatedIndexInExamples].endTokenIndex = endTokenIndex;
    //             const { startTokenIndex: repeatedStartIndex, endTokenIndex: reapetedEndIndex } =
    //                 exampleObj.examples[entityRepeatedIndexInExamples];
    //             exampleObj.examples[entityRepeatedIndexInExamples].phrase = exampleObj.tokenizedText
    //                 .slice(repeatedStartIndex, reapetedEndIndex + 1)
    //                 .join(" ");
    //             selectedEntityIsRepeated = true;
    //             continue;
    //         }
    //         if (!entityRepeatedIndexIsHigher && entityRepeatedIndexIsLower) {
    //             exampleObj.examples[entityRepeatedIndexInExamples].startTokenIndex =
    //                 startTokenIndex;
    //             const { startTokenIndex: repeatedStartIndex, endTokenIndex: reapetedEndIndex } =
    //                 exampleObj.examples[entityRepeatedIndexInExamples];
    //             exampleObj.examples[entityRepeatedIndexInExamples].phrase = exampleObj.tokenizedText
    //                 .slice(repeatedStartIndex, reapetedEndIndex + 1)
    //                 .join(" ");
    //             selectedEntityIsRepeated = true;
    //             continue;
    //         }

    //         //Si ninguno de los dos es mayor o menor, entonces la entidad repetida esta fuera de la entidad seleccionada, entonces no hacemos nada
    //         if (!entityRepeatedIndexIsHigher && !entityRepeatedIndexIsLower) {
    //             selectedEntityIsRepeated = false;
    //             continue;
    //         }
    //     }

    //     //Creamos el objeto del ejemplo con cada padre de la entidad seleccionada
    //     if (!noCreateParents && parents.length > 0) {
    //         parentsCopy.forEach((parent, index) => {
    //             const { name, level } = parent;
    //             let uniqid = name.substring(0, 3) + index + Math.floor(Math.random() * 1000);
    //             //Creamos el objeto del ejemplo con la entidad seleccionada
    //             let entityPhrase = {
    //                 phrase: exampleObj.tokenizedText
    //                     .slice(startTokenIndex, endTokenIndex + 1)
    //                     .join(" "),
    //                 startTokenIndex: startTokenIndex,
    //                 endTokenIndex: endTokenIndex,
    //                 entityName: name,
    //                 entityId: exampleObj.examples.length + 1 - 1,
    //                 level: level,
    //                 uniqid: uniqid,
    //                 type: "label",
    //             };
    //             exampleObj.examples.push(entityPhrase);
    //         });
    //     }

    //     //Evitamos que se cree la entidad si ya existe
    //     if (!selectedEntityIsRepeated) {
    //         let newPhraseIndex = exampleObj.examples.length + 1 - 1;
    //         let uniqid =
    //             entityName.substring(0, 3) + newPhraseIndex + Math.floor(Math.random() * 1000);
    //         //Creamos el objeto del ejemplo con la entidad seleccionada
    //         let entityPhrase = {
    //             phrase: exampleObj.tokenizedText
    //                 .slice(startTokenIndex, endTokenIndex + 1)
    //                 .join(" "),
    //             startTokenIndex: startTokenIndex,
    //             endTokenIndex: endTokenIndex,
    //             entityName: entityName,
    //             entityId: newPhraseIndex,
    //             level: level,
    //             uniqid: uniqid,
    //             type: "label",
    //         };
    //         exampleObj.examples.push(entityPhrase);
    //     }

    //     //Guardamos el objeto para ser mandado al servicio de PLN
    //     exampleListCopy[selectedExampleIndex] = exampleObj;
    //     createObjectToUpdate(exampleObj);
    //     setSuggestionsList(exampleListCopy);
    //     handleCloseEntityMenu();
    //     setExamplesHasChanges(true);
    //     setCreateIntentErrorHelper(createIntentErrorHelperCopy);
    // };

    const createSuggestionLabel = (selectedEntityName, selectedEntityUniqid) => {
        //console.log(selectedEntityName, selectedEntityUniqid);
        let exampleListCopy = JSON.parse(JSON.stringify(suggestionsList));
        let exampleObj = exampleListCopy[selectedExampleIndex];
        let selectedEntityIndex = exampleObj.examples.findIndex(
            (entity) => entity.uniqid === selectedEntityUniqid
        );
        const { startTokenIndex, endTokenIndex } = exampleObj.examples[selectedEntityIndex];
        //Obtenemos la info de la entidad seleccionada
        let entityObj = findEntityInfo(selectedEntityName);
        const { name, parents, level } = entityObj;
        createEntityPhrase(name, parents, level, startTokenIndex, endTokenIndex);
    };

    const changeEntityTag = (entityName) => {
        let exampleListCopy = [...suggestionsList];
        let exampleObj = exampleListCopy[selectedExampleIndex];
        let selectedEntityIndex = exampleObj.examples.findIndex(
            (entity) => entity.uniqid === selectedEntityUniqid
        );
        exampleObj.examples[selectedEntityIndex].entityName = entityName;
        //Validamos que no se pueda seleccionar una entidad con el mismo nombre
        // let entityPhraseExists = exampleObj.examples.find(entity => entity.entityName === entityName)
        // if(entityPhraseExists){
        //     removeEntityPhrase(entityPhraseExists.id)
        //     return
        // }
        createObjectToUpdate(exampleObj);
        setSuggestionsList(exampleListCopy);
        setExamplesHasChanges(true);
        handleCloseEntityMenu();
    };

    const createSuggestionLabelAndChildren = (selectedEntityName, selectedEntityInfo) => {
        let exampleListCopy = JSON.parse(JSON.stringify(suggestionsList));
        let exampleObj = exampleListCopy[selectedExampleIndex];
        let selectedEntityIndex = exampleObj.examples.findIndex(
            (entity) => entity.entityName === selectedEntityName
        );
        exampleObj.examples[selectedEntityIndex].type = "label";
        const { children = [] } = selectedEntityInfo;
        if (children.length > 0) {
            exampleObj = createChildrenLabel(children, exampleObj);
        }
        //Guardamos el objeto para ser mandado al servicio de PLN
        exampleListCopy[selectedExampleIndex] = exampleObj;
        createObjectToUpdate(exampleObj);
        setSuggestionsList(exampleListCopy);
        handleCloseEntityMenu();
        setExamplesHasChanges(true);
    };

    const createChildrenLabel = (children, exampleObj) => {
        children.forEach((child) => {
            //search for the child in the example
            let childIndex = exampleObj.examples.findIndex(
                (entity) => entity.entityName === child.name
            );
            //if the child is found, change the type to label
            if (childIndex !== -1) {
                //search for the grandchild in the example
                exampleObj.examples[childIndex].type = "label";
            }
            if (child.children.length > 0) {
                createChildrenLabel(child.children, exampleObj);
            }
        });
        return exampleObj;
    };

    const handleOpenEntityMenu = (event, selection) => {
        event.preventDefault();
        selection.removeAllRanges();
        setMousePosition({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
        });
    };

    const handleCloseEntityMenu = () => {
        setMousePosition({ mouseX: null, mouseY: null });
        setMenuAnchor(null);
        setSelectedWordsIds([]);
    };

    const handleOpenClickEntityMenu = (
        event,
        exampleIndex,
        selectedEntityUniqid,
        selectedEntityType,
        selectedEntityName
    ) => {
        setMenuAnchor(event.currentTarget);
        setSelectedExampleIndex(exampleIndex);
        setSelectedEntityUniqid(selectedEntityUniqid);
        setSelectedExampleType(selectedEntityType);
        setSelectedEntityName(selectedEntityName);
    };

    const saveSelectedSuggestions = async () => {
        let alert = await Swal.fire({
            text: "¿Deseas guardar los cambios?",
            showDenyButton: true,
            confirmButtonText: "Si",
            denyButtonText: `No`,
            confirmButtonColor: "#27315d",
            denyButtonColor: "#27315d",
        });
        //Cancelamos el eliminado del usuario
        if (alert.isDenied || alert.isDismissed) {
            return;
        }
        updateContextAttribute("loadingDialog", true);
        updateContextAttribute("LoadingMessage", "Guardando cambios...");
        let exampleListCopy = JSON.parse(JSON.stringify(suggestionsList));
        let responseCodeArray = [];
        //Iteramos sobre los ejemplos seleccionados de suggestionSelected para obtener los ejemplos que se van a guardar
        for (let i = 0; i < suggestionSelected.length; i++) {
            const selectedExample = suggestionSelected[i];
            let selectedExamplesObj = exampleListCopy.filter(
                (example) => example.suggestionId === selectedExample
            );
            const { examples = [], intent, text, id } = selectedExamplesObj[0];
            let dataToUpdate = {
                examples: examples.filter((example) => example.type === "label"),
                intentName: intent,
                text: text,
                id: id,
            };
            if (storageData.exclusiveServices.processNaturalLenguage && storageData.appId) {
                dataToUpdate["appID"] = storageData.appId;
            }
            //console.log(JSON.stringify(dataToUpdate, null, 2))
            let res = await actionsPLNManagement(dataToUpdate, "updateExample");
            let updateExampleResponseCode = res?.data?.code ? parseInt(res.data.code) : 99; //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
            responseCodeArray.push(updateExampleResponseCode);
        }
        //Si todos los ejemplos se guardaron correctamente, se actualiza el estado de los ejemplos
        updateContextAttribute("loadingDialog", false);
        updateContextAttribute("LoadingMessage", "");

        //Preguntamos si todos los cambios se aplicaron correctamente
        let allChangesApplied =
            responseCodeArray.every((code) => code === 0) && responseCodeArray.length > 0;
        //Preguntamos si alguno de los cambios tiene duplicados
        let someChangesHasDuplicate =
            responseCodeArray.some((code) => code === 2) && responseCodeArray.length > 0;

        switch (true) {
            case responseCodeArray.length === 0: {
                let alert = {
                    open: true,
                    severity: "info", //success, error, warning, info
                    message: "No se han realizado cambios, no hay nada que guardar.",
                };
                updateContextAttribute("alert", alert);
                return;
            }
            case !allChangesApplied: {
                let alert = {
                    open: true,
                    severity: "error", //success, error, warning, info
                    message: "Ocurrió un error al guardar los datos",
                };
                updateContextAttribute("alert", alert);
                return;
            }
            case someChangesHasDuplicate: {
                let alert = {
                    open: true,
                    severity: "info", //success, error, warning, info
                    message:
                        "Hay entidades duplicadas, revisa que entidades hijas y padres no se repitan",
                };
                updateContextAttribute("alert", alert);
                return;
            }
            case allChangesApplied && !someChangesHasDuplicate: {
                let alert = {
                    open: true,
                    severity: "success", //success, error, warning, info
                    message: "Los datos se guardaron correctamente",
                };
                updateContextAttribute("alert", alert);
                setExamplesHasChanges(false);
                setSuggestionSelected([]);
                getSuggestions(filteredIntent);
                return;
            }
            default:
                break;
        }
    };

    const deleteSelectedSuggestions = async () => {
        let alert = await Swal.fire({
            text: "¿Deseas eliminar los ejemplos seleccionados?",
            showDenyButton: true,
            confirmButtonText: "Si",
            denyButtonText: `No`,
            confirmButtonColor: "#27315d",
            denyButtonColor: "#27315d",
        });
        //Cancelamos el eliminado del usuario
        if (alert.isDenied || alert.isDismissed) {
            return;
        }
        updateContextAttribute("loadingDialog", true);
        updateContextAttribute("LoadingMessage", "Eliminando ejemplos...");
        let exampleListCopy = JSON.parse(JSON.stringify(suggestionsList));
        let responseCodeArray = [];
        //Iteramos sobre los ejemplos seleccionados de suggestionSelected para obtener los ejemplos que se van a guardar
        for (let i = 0; i < suggestionSelected.length; i++) {
            const selectedExample = suggestionSelected[i];
            let selectedExamplesObj = exampleListCopy.filter(
                (example) => example.suggestionId === selectedExample
            );
            const { text } = selectedExamplesObj[0];
            let dataToDelete = {
                text: text,
            };
            if (storageData.exclusiveServices.processNaturalLenguage && storageData.appId) {
                dataToDelete["appID"] = storageData.appId;
            }
            let res = await actionsPLNManagement(dataToDelete, "deleteSuggest");
            let deleteExampleResponseCode = res?.data?.code ? parseInt(res.data.code) : 99; //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
            responseCodeArray.push(deleteExampleResponseCode);
        }
        updateContextAttribute("loadingDialog", false);
        updateContextAttribute("LoadingMessage", "");
        //Preguntamos si todos los cambios se aplicaron correctamente
        let allChangesApplied =
            responseCodeArray.every((code) => code === 0) && responseCodeArray.length > 0;
        //Preguntamos si alguno de los cambios tiene duplicados
        let someChangesHasDuplicate =
            responseCodeArray.some((code) => code === 2) && responseCodeArray.length > 0;

        switch (true) {
            case responseCodeArray.length === 0: {
                let alert = {
                    open: true,
                    severity: "info", //success, error, warning, info
                    message: "No se han realizado cambios, no hay nada que guardar.",
                };
                updateContextAttribute("alert", alert);
                return;
            }
            case !allChangesApplied: {
                let alert = {
                    open: true,
                    severity: "error", //success, error, warning, info
                    message: "Ocurrió un error al guardar los datos",
                };
                updateContextAttribute("alert", alert);
                return;
            }
            case someChangesHasDuplicate: {
                let alert = {
                    open: true,
                    severity: "info", //success, error, warning, info
                    message:
                        "Hay entidades duplicadas, revisa que entidades hijas y padres no se repitan",
                };
                updateContextAttribute("alert", alert);
                return;
            }
            case allChangesApplied && !someChangesHasDuplicate: {
                let alert = {
                    open: true,
                    severity: "success", //success, error, warning, info
                    message: "Los datos se guardaron correctamente",
                };
                updateContextAttribute("alert", alert);
                setExamplesHasChanges(false);
                setSuggestionSelected([]);
                getSuggestions(filteredIntent);
                return;
            }
            default:
                let alert = {
                    open: true,
                    severity: "error", //success, error, warning, info
                    message: "Ocurrió un error al guardar los datos",
                };
                updateContextAttribute("alert", alert);
                return;
        }
    };

    return (
        <div>
            <h1>Sugerencias para el entrenamiento</h1>
            <header className="review-suggestions-header">
                <label>Filtro: </label>
                <TextField
                    select
                    name={"selectFilterIntent"}
                    value={filteredIntent}
                    fullWidth
                    variant="outlined"
                    size="small"
                    onChange={(event) => getSuggestions(event.target.value)}
                >
                    {intentsList.map((intent, i) => (
                        <MenuItem key={i} value={intent.intentId}>
                            {intent.intentName} {/* replace setOption with option */}
                        </MenuItem>
                    ))}
                </TextField>
                <RefreshButton refreshFunction={() => getSuggestions(filteredIntent)} />
            </header>
            <header className="review-suggestions-header">
                <FormControlLabel
                    disabled={suggestionsList.length === 0}
                    control={
                        <Checkbox
                            name={"checkAllSuggestions"}
                            color="primary"
                            checked={checkAllSuggestions}
                            onChange={(event) => handleCheckAllSuggestions(event.target.checked)}
                        />
                    }
                    label="Seleccionar"
                    classes={{ label: "review-suggestions-header-label" }}
                />
                <Button
                    disabled={decideDisableConfirmButton()}
                    onClick={() => {
                        if (suggestionSelected.length === 0) {
                            confirmAllSuggestions();
                            return;
                        }
                        confirmSelectedSuggestion(suggestionSelected);
                    }}
                    startIcon={<DoneIcon width="20px" height="20px" />}
                    size="small"
                >
                    {suggestionSelected.length === 0 ? "Confirmar todo" : `Confirmar`}
                </Button>
                <Button
                    disabled={suggestionsList.length === 0 || suggestionSelected.length === 0}
                    onClick={() => {
                        saveSelectedSuggestions();
                    }}
                    startIcon={<SaveAltIcon width="20px" height="20px" />}
                    size="small"
                >
                    {`Guardar`}
                </Button>
                <Button
                    disabled={suggestionsList.length === 0 || suggestionSelected.length === 0}
                    onClick={() => {
                        deleteSelectedSuggestions();
                    }}
                    startIcon={<TrashCan width="20px" height="20px" />}
                    size="small"
                >
                    Eliminar
                </Button>
            </header>
            <Grid container className="pagination-container">
                <Pagination
                    siblingCount={4}
                    disabled={!pages > 1 || loading}
                    count={pages}
                    page={currentPage}
                    onChange={(e, value) => handleChangePage(value)}
                />
            </Grid>
            <main>
                <h2>Lista de sugerencias</h2>
                {loading ? (
                    <div className="center-loading-icon">
                        <CircularProgress />
                    </div>
                ) : errorInFetch ? (
                    <ErrorInFetch
                        errorInFetch={errorInFetch}
                        refreshFunction={() => getSuggestions(filteredIntent)}
                    />
                ) : (
                    <div className="test-pln-container">
                        {suggestionsList.length === 0 ? (
                            <div className="center-loading-icon">
                                No hay sugerencias para mostrar, cambia la intención seleccionada en
                                el selector
                            </div>
                        ) : null}
                        {suggestionsList.map((exampleObj, index) => (
                            <div>
                                <div className="intent-example-main">
                                    <div className="test-intent-result">
                                        <label className="top-label-field">
                                            Intención de mayor puntuación:{" "}
                                            <strong>
                                                {exampleObj.intent ?? "No se encontró un resultado"}
                                            </strong>
                                        </label>
                                    </div>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <Checkbox
                                        name={"suggestionCheckbox_" + exampleObj.suggestionId}
                                        color="primary"
                                        checked={suggestionSelected.includes(
                                            exampleObj.suggestionId
                                        )}
                                        onChange={(event) =>
                                            handleSuggestionCheckbox(
                                                event.target.checked,
                                                exampleObj.suggestionId
                                            )
                                        }
                                    />
                                    <IntentExample
                                        findEntityInfo={findEntityInfo}
                                        createIntentErrorHelper={[]}
                                        exampleList={suggestionsList}
                                        exampleObj={exampleObj}
                                        exampleIndex={index}
                                        entityList={entityList}
                                        handleSelection={handleSelection}
                                        selectedWordsIds={selectedWordsIds}
                                        selectedExampleIndex={selectedExampleIndex}
                                        handleOpenClickEntityMenu={handleOpenClickEntityMenu}
                                    />
                                    {/* Crear entidad utilizando el mouse */}
                                    <IntentDragMouseMenu
                                        //props
                                        mousePosition={mousePosition}
                                        handleCloseEntityMenu={handleCloseEntityMenu}
                                        entityList={entityList}
                                        createEntityPhrase={createEntityPhrase}
                                    />
                                    {/* Cambiar la entidad clickeando la etiqueta */}
                                    <IntentClickTagMenu
                                        //props
                                        selectedEntityName={selectedEntityName}
                                        selectedExampleType={selectedExampleType}
                                        createSuggestionLabel={createSuggestionLabel}
                                        selectedExampleIndex={selectedExampleIndex}
                                        exampleList={suggestionsList}
                                        menuAnchor={menuAnchor}
                                        handleCloseEntityMenu={handleCloseEntityMenu}
                                        entityList={entityList}
                                        createEntityPhrase={createEntityPhrase}
                                        selectedEntityUniqid={selectedEntityUniqid}
                                        removeEntityPhrase={removeEntityPhrase}
                                        changeEntityTag={changeEntityTag}
                                        findEntityInfo={findEntityInfo}
                                        createSuggestionLabelAndChildren={
                                            createSuggestionLabelAndChildren
                                        }
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </main>
        </div>
    );
};

export default ReviewEndpointUtterances;
