import React from "react";

//Custom Portal Components
import LoadingDialog from "../components/common/Modals/LoadingDialog";
import {
    blobStorage,
    authActions,
    actionsQnAmaker,
    intebotDataActions,
} from "../components/DataFunctions";
import CustomAlert from "../components/common/Alerts/CustomAlert";

//Catalogs
import IntebotCatalog from "../components/IntebotCatalog";

//External Libraries
import Swal from "sweetalert2"; //https://sweetalert2.github.io
import Cookies from "js-cookie"; //https://www.npmjs.com/package/js-cookie
import { enable as enableDarkMode, disable as disableDarkMode } from "darkreader"; //https://github.com/darkreader/darkreader

const AppContext = React.createContext();

const { GlobalConsts, intebotConstants } = IntebotCatalog;

export class AppProvider extends React.Component {
    //Context State
    state = {
        //State for section content
        processes: [],
        processesTemp: [],
        processesHasChanges: false,
        processesHasSavedChanges: false,
        messages: {},
        messagesTemp: {},
        messagesHasChanges: false,
        messagesHasSavedChanges: false,
        regEx: [],
        regExTemp: [],
        regExHasChanges: false,
        regExHasSavedChanges: false,
        catalogs: [],
        catalogsTemp: [],
        catalogsHasChanges: false,
        catalogsHasSavedChanges: false,
        settings: {},
        settingsTemp: {},
        settingsHasChanges: false,
        settingsHasSavedChanges: false,
        QnAmaker: {},
        QnAmakerTemp: {},
        QnAmakerHasChanges: false,
        QnAmakerHasSavedChanges: false,
        globalValues: [],
        newMultiMediaMessages: [],
        existingFileObjects: [],
        fileObjects: [],
        fileDataToSend: [],
        fileDataToSendHasSavedChanges: false,
        fileDataToDelete: [],
        fileDataToDeleteHasSavedChanges: false,
        clearMultiMediaFields: false,
        contextReady: false,
        //State for user feedback
        alert: {
            open: false,
            severity: "", //success, error, warning, info
            message: "",
        },
        loadingDialog: false,
        LoadingMessage: "",
        userLoggedIn: false,
        remeberAuthCredentials: false,
        countflowsInWhatsApp: 0,
        fileFAQToDeleteHasSavedChanges: false,
        fileFAQToSaveHasSavedChanges: false,
        fileDataFAQToSend: [],
        fileDataFAQToDelete: [],
        kbFileSources: [],
        kbFileSourcesOriginal: [],
        isDarkModeEnabled: false,
        sendCampaingsHasChanges: false,
        FAQpageHasChanges: false,
        faqHasSavedChanges: false,
        matchesUpMd: false,
        readOnly: false,
    };

    componentDidMount() {
        const { getAuthTokenValidation, handleDarkMode, askCloseConfirmation } = this;
        getAuthTokenValidation();
        let isDarkModeEnabled = Cookies.get("darkMode") === "true";
        handleDarkMode(isDarkModeEnabled);
        window.addEventListener("beforeunload", askCloseConfirmation);
    }

    componentWillUnmount() {
        const { askCloseConfirmation } = this;
        window.removeEventListener("beforeunload", askCloseConfirmation);
    }

    askCloseConfirmation = (e) => {
        const {
            processesHasChanges,
            messagesHasChanges,
            regExHasChanges,
            catalogsHasChanges,
            settingsHasChanges,
            sendCampaingsHasChanges,
            FAQpageHasChanges,
        } = this.state;

        const hasUnPublishedChanges =
            processesHasChanges ||
            messagesHasChanges ||
            regExHasChanges ||
            catalogsHasChanges ||
            settingsHasChanges ||
            sendCampaingsHasChanges ||
            FAQpageHasChanges;

        if (hasUnPublishedChanges) {
            e.preventDefault();
            return (e.returnValue =
                "Tienes cambios sin publicar, ¿Estás seguro de que deseas salir?");
        }
    };

    getAuthTokenValidation = async () => {
        const {
            getFlowsData,
            logOutUser,
            getStorageData,
            getSuscriptionData,
            updateSubscriptionData,
        } = this;
        //buscamos si el token se guardo en local/session
        let storageData = getStorageData([
            "token",
            "remeberAuthCredentials",
            "exclusiveServices",
            "currentSubscription",
        ]);

        let authData = getStorageData([
            "email",
            "name",
            "lastName",
            "organizationName",
            "subscriptionId",
            "token",
        ]);

        let token = storageData.token;
        let tokenExpired = false;
        let userLoggedIn = true;
        if (token) {
            //si existe el token significa que el user esta loggeado
            this.setState({
                userLoggedIn: userLoggedIn,
                remeberAuthCredentials: storageData.remeberAuthCredentials,
            });
            this.setState({
                loadingDialog: true,
                LoadingMessage: "Estamos realizando la conexión con el servicio.",
            });
            //Preguntamos al ws si el token es valido
            let data = {
                subscriptionId: storageData.currentSubscription.subscriptionId,
            };
            let response = await authActions(data, `ValidateToken&token=${token}`, "LogIn");
            let tokenValidationCode = parseInt(response?.data?.response?.code) ?? 99;
            switch (tokenValidationCode) {
                case 0:
                    //Token válido, Dejamos el pase al usuario
                    break;
                case 1:
                    Swal.fire({
                        icon: "error",
                        title: "Tu sesión ha cadudado.",
                        text: "Inicia sesión de nuevo.",
                    });
                    logOutUser((tokenExpired = true));
                    break;
                case 2:
                    {
                        //Obtenemos otra suscripción para el usuario
                        let suscriptionDataCopy = JSON.parse(
                            JSON.stringify(authData.subscriptionId)
                        );
                        let fallbackSuscription = suscriptionDataCopy.shift();
                        if (fallbackSuscription.name === storageData.currentSubscription.name) {
                            fallbackSuscription = suscriptionDataCopy.shift();
                        }
                        let alert2 = null;
                        let isLogOutUser = false;

                        if (fallbackSuscription) {
                            alert2 = await Swal.fire({
                                icon: "info",
                                title: `Tu cuenta ya no pertenece a la suscripción ${storageData.currentSubscription.name}.`,
                                text: `Si deseas hacer ediciones ponte en contacto con el administrador de la suscripción. Serás redirigido a la suscripción ${fallbackSuscription.name}.`,
                            });
                        } else {
                            alert2 = await Swal.fire({
                                icon: "info",
                                title: `Tu cuenta ya no pertenece a la suscripción ${storageData.currentSubscription.name}.`,
                                text: `Si deseas hacer ediciones ponte en contacto con el administrador de la suscripción. Ya no tienes suscripciones más activas tu sesión será cerrada.`,
                            });
                            logOutUser = true;
                        }

                        //Esperamos a que el usuario cierre el alert
                        if (alert2 && !isLogOutUser) {
                            updateSubscriptionData(authData, fallbackSuscription);
                            tokenExpired = true;
                        }
                        if (isLogOutUser) {
                            logOutUser((tokenExpired = true));
                        }
                    }
                    break;
                case 10:
                    {
                        //Obtenemos otra suscripción para el usuario
                        let suscriptionDataCopy = JSON.parse(
                            JSON.stringify(authData.subscriptionId)
                        );
                        let fallbackSuscription = suscriptionDataCopy.shift();
                        if (fallbackSuscription.name === storageData.currentSubscription.name) {
                            fallbackSuscription = suscriptionDataCopy.shift();
                        }
                        let alert2 = null;
                        let isLogOutUser = false;

                        if (fallbackSuscription) {
                            alert2 = await Swal.fire({
                                icon: "info",
                                title: `La suscripción ${storageData.currentSubscription.name} ya no se encuentra activa.`,
                                text: `Para volver a activar la subscripción ponte en contacto con el soporte al cliente. Serás redirigido a la suscripción ${fallbackSuscription.name}.`,
                            });
                        } else {
                            alert2 = await Swal.fire({
                                icon: "info",
                                title: `La suscripción ${storageData.currentSubscription.name} ya no se encuentra activa.`,
                                text: `Para volver a activar la subscripción ponte en contacto con el soporte al cliente. Tu sesión será cerrada.`,
                            });
                            isLogOutUser = true;
                        }

                        //Esperamos a que el usuario cierre el alert
                        if (alert2 && !isLogOutUser) {
                            updateSubscriptionData(authData, fallbackSuscription);
                            tokenExpired = true;
                        }
                        if (isLogOutUser) {
                            logOutUser((tokenExpired = true));
                        }
                    }
                    break;
                default:
                    Swal.fire({
                        icon: "error",
                        title: "Error de autenticación.",
                        text: "Inicia sesión de nuevo.",
                    });
                    logOutUser((tokenExpired = true));
                    break;
            }
        }
        //Si el token no expiro, entonces llamamos a los servicios del get data
        if (!tokenExpired && token) {
            await getSuscriptionData();
            await getFlowsData();
        }

        return tokenExpired;
    };

    getFlowsData = async () => {
        const { getStorageData, sleep } = this;
        let storageData = getStorageData(["exclusiveServices"]);
        if (storageData.exclusiveServices.processNaturalLenguage) return;
        this.setState({
            loadingDialog: true,
            LoadingMessage: "Estamos realizando la conexión con el servicio.",
        });
        try {
            let [processesData, configData, regExData, catalogsData, getIntebotStatus] =
                await Promise.all([
                    intebotDataActions(
                        {},
                        "getConfigurationProcesses&enviroment=Test",
                        "ModelConfigurationOperations"
                    ),
                    intebotDataActions(
                        {},
                        "getConfigurationVariables&enviroment=Test",
                        "BotConfigurationOperations"
                    ),
                    intebotDataActions(
                        {},
                        "getConfigurationDataTypes&enviroment=Test",
                        "ModelConfigurationOperations"
                    ),
                    intebotDataActions(
                        {},
                        "getConfigurationCatalogs&enviroment=Test",
                        "ModelConfigurationOperations"
                    ),
                    intebotDataActions({}, "getStatus", "BotConfigurationOperations"),
                ]);

            let processes = processesData.data.processes;
            let config = configData.data.botConfiguration;
            let regEx = regExData.data.dataTypes;
            let catalogs = catalogsData.data.catalogs;
            let constIntebot = intebotConstants;
            let unpublishedData = getIntebotStatus.data.unpublishedData ?? [];

            //guardamos todos los campos constantes
            let globalValuesList = [];
            GlobalConsts.map((constant) => {
                globalValuesList.push({
                    id: constant,
                    display: constant,
                    list: "Constante",
                });
                config[constant] = constant;
            });

            //Cada paso que tenga activada el checkbox de "Valor global", se almacena en la lista de globales
            processes.map((process) => {
                process["steps"].map((step) => {
                    if (step.globalValue) {
                        globalValuesList.push({
                            flow: process.processName,
                            id: step.description,
                            display: `${step.description}`,
                            list: "Global",
                        });
                    }
                    return globalValuesList;
                });
                return globalValuesList;
            });

            //Cada respuesta del servicio que tenga activado el checkbox de "Guardar respuesta"
            processes.map((process) => {
                //buscamos si el proceso tiene un responseBranches
                if (process.responseBranches) {
                    let responseBranches = process.responseBranches;
                    //mapeamos el branch y sacamos la tabla de response Attributes
                    responseBranches.map((branch) => {
                        let responseAttributes = branch.responseTable.responseAttributes;
                        responseAttributes.forEach((attribute) => {
                            //Si tiene activado el checkbox de saveattribute lo mandamos a la lista de globales
                            if (attribute.saveAttribute) {
                                globalValuesList.push({
                                    flow: process.processName,
                                    id: attribute.description,
                                    display: `${attribute.description}`,
                                    list: "Global",
                                });
                            }
                        });
                        return globalValuesList;
                    });
                }
                return globalValuesList;
            });

            //filtramos la lista de valores globales para que no haya nombres repetidos
            let uniqGlobalValues = globalValuesList.filter((item, index) => {
                //comparamos ambas listas y devolvemos la posición del id
                let position = globalValuesList.findIndex((element) => element.id === item.id);
                //si hay un valor duplicado, este tendrá otro index diferente al que está siendo iterado y se quita
                return position === index;
            });

            let processesHasSavedChanges = false;
            let messagesHasSavedChanges = false;
            let regExHasSavedChanges = false;
            let catalogsHasSavedChanges = false;

            unpublishedData.forEach((pendingData) => {
                switch (pendingData) {
                    case "process":
                        processesHasSavedChanges = true;
                        break;
                    case "botConfiguration":
                        messagesHasSavedChanges = true;
                        break;
                    case "dataTypes":
                        regExHasSavedChanges = true;
                        break;
                    case "catalogs":
                        catalogsHasSavedChanges = true;
                        break;
                    default:
                        break;
                }
            });

            //Guardamos valores en el contexto
            this.setState({
                globalValues: uniqGlobalValues,
                processes: processes,
                processesTemp: processes,
                messages: { ...config, ...constIntebot },
                messagesTemp: { ...config, ...constIntebot },
                regEx: regEx,
                regExTemp: regEx,
                catalogs: catalogs,
                catalogsTemp: catalogs,
                loadingDialog: false,
                LoadingMessage: "",
                //Hasta que terminemos guardar la info de los flujos del bot, damos el acceso al portal
                contextReady: true,
                processesHasSavedChanges,
                messagesHasSavedChanges,
                regExHasSavedChanges,
                catalogsHasSavedChanges,
            });
        } catch (error) {
            this.setState({
                loadingDialog: false,
                LoadingMessage: "",
            });
            let alert = await Swal.fire({
                icon: "error",
                title: "No se pudo obtener la información del portal.",
                text: "¿Deseas reintentar?",
                showDenyButton: true,
                confirmButtonText: "Reintentar",
                denyButtonText: `Cerrar Sesión`,
                confirmButtonColor: "#27315d",
                denyButtonColor: "#27315d",
                allowOutsideClick: false,
            });
            let { isConfirmed, isDenied } = alert;
            switch (true) {
                case isConfirmed:
                    window.location.reload();
                    break;
                case isDenied:
                    this.logOutUser(true);
                    break;
                default:
                    window.location.reload();
                    break;
            }
        }
    };

    getSuscriptionData = async (authData) => {
        this.setState({
            loadingDialog: true,
            LoadingMessage: "Estamos realizando la conexión con el servicio.",
        });
        const { getStorageData, storeLoginInformation } = this;
        let { currentSubscription } = getStorageData(["currentSubscription"]);
        if (!authData) {
            authData = getStorageData([
                "email",
                "name",
                "lastName",
                "organizationName",
                "subscriptionId",
                "token",
            ]);
        }
        let storageIsReady;
        let currentSubscriptionObj = currentSubscription || authData?.subscriptionId?.[0];
        if (!currentSubscriptionObj) {
            return (storageIsReady = false);
        }
        let currentSubscriptionId = currentSubscriptionObj.subscriptionId;
        let data = {
            subscriptionId: currentSubscriptionId,
            email: authData.email,
        };
        let resSubsData = await authActions(data, "GetSubscription", "SubscriptionOperations");
        let responseCode = resSubsData?.data?.response?.code
            ? parseInt(resSubsData.data.response.code)
            : 99; //Si no hay un código de respuesta lo mandamos al default
        this.setState({
            loadingDialog: false,
            LoadingMessage: "",
        });
        switch (responseCode) {
            case 0:
                let subscriptionData = resSubsData.data.response.data;
                subscriptionData["currentSubscription"] = currentSubscriptionObj;
                storageIsReady = await storeLoginInformation(authData, subscriptionData);
                return storageIsReady;
            default:
                return (storageIsReady = false);
        }
    };

    //Method to update state
    updateContextAttribute = (key, value) => {
        this.setState({
            [key]: value,
        });
    };

    storeRateLimit = (rateLimit) => {
        return new Promise((resolve) => {
            let remeberAuthCredentials = localStorage.getItem("remeberAuthCredentials");
            //Actualizamos el rate limit en el storage
            const {
                incomingMessageLimit,
                incomingMessageCounter,
                outgoingMessageLimit,
                outgoingMessageCounter,
                outgoingMessageDayLimit,
                outgoingMessageDayCounter,
            } = rateLimit;
            let rateLimitStorage = [
                { key: "incomingMessageLimit", value: incomingMessageLimit }, //limite máximo de mensajes recibidos por intebot
                { key: "incomingMessageCounter", value: incomingMessageCounter }, //contador real de mensajes restantes por ser recibidos por intebot
                { key: "outgoingMessageLimit", value: outgoingMessageLimit }, //limite máximo de mensajes a ser enviados por intebot
                { key: "outgoingMessageCounter", value: outgoingMessageCounter }, //contador real de mensajes que pueden ser enviados por intebot
                { key: "outgoingMessageDayLimit", value: outgoingMessageDayLimit }, //Limite máximo diario de mensajes a ser enviador por intebot
                { key: "outgoingMessageDayCounter", value: outgoingMessageDayCounter }, //contador real diario de mensajes a ser enviados
            ];
            if (remeberAuthCredentials) {
                for (let index = 0; index < rateLimitStorage.length; index++) {
                    const keyToStore = rateLimitStorage[index].key;
                    const valueToStore = rateLimitStorage[index].value;
                    //nunca expiran los datos
                    localStorage.setItem(keyToStore, valueToStore);
                }
            } else {
                for (let index = 0; index < rateLimitStorage.length; index++) {
                    const keyToStore = rateLimitStorage[index].key;
                    const valueToStore = rateLimitStorage[index].value;
                    //expiran al cerrar el navegador
                    sessionStorage.setItem(keyToStore, valueToStore);
                }
            }
            let rateLimitHasStored = true;
            resolve(rateLimitHasStored);
        });
    };

    storeLoginInformation = (authData, subscriptionData, updateSubscriptionData) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { storeRateLimit, getStorageData } = this;
                const { remeberAuthCredentials } = this.state;
                //extraemos la info del login ser guardada en el storage
                const { email, name, lastName, organizationName, token } = authData;

                const {
                    whatsappMessageService,
                    subscriptionTier,
                    subscriptionChannel,
                    subscriptionModule,
                    coreResources,
                    audioProcessingService,
                    botConfiguration,
                    currentSubscription,
                    permission,
                    exclusiveServices,
                    PLNModule,
                    subscriptionsArray,
                    reportService,
                } = subscriptionData;
                let serviceKey = whatsappMessageService.serviceKey;
                let coreResourceServiceKey = coreResources.serviceKey;
                let audioProcessingServiceKey = audioProcessingService.serviceKey;
                let femaleVoice = audioProcessingService.femaleVoice;
                let reportServiceKey = reportService.serviceKey;
                let { powerBIReport, botSecret } = botConfiguration;
                let availableChannels = {};
                let appId = null;
                let appName = null;
                for (const channelName in subscriptionChannel) {
                    const isChannelAvailable = subscriptionChannel[channelName];
                    if (isChannelAvailable) {
                        availableChannels[channelName] = isChannelAvailable;
                    }
                }
                const storageData = getStorageData(["appName", "appId"]);
                if (exclusiveServices.processNaturalLenguage && !updateSubscriptionData) {
                    appId = storageData.appId;
                    appName = storageData.appName;
                }

                //Hacemos el arrelgo para ser guardado en el storage
                let authStorage = [
                    { key: "serviceKey", value: serviceKey }, //clave de acceso al conteo de mensajes de whats
                    { key: "token", value: token }, //token de acceso al portal
                    { key: "remeberAuthCredentials", value: remeberAuthCredentials },
                    { key: "organizationName", value: organizationName }, //organización a la que pertence el cliente
                    { key: "email", value: email }, //correo del cliente al que se le mandarán los correos pertinentes del portal
                    { key: "name", value: name },
                    { key: "lastName", value: lastName },
                    { key: "subscriptionId", value: JSON.stringify(subscriptionsArray) },
                    { key: "coreResourceServiceKey", value: coreResourceServiceKey },
                    //Los objects hay que guardarlos en formato de string
                    { key: "subscriptionTier", value: JSON.stringify(subscriptionTier) },
                    { key: "subscriptionChannel", value: JSON.stringify(subscriptionChannel) },
                    { key: "subscriptionModule", value: JSON.stringify(subscriptionModule) },
                    { key: "audioProcessingServiceKey", value: audioProcessingServiceKey },
                    { key: "availableChannels", value: JSON.stringify(availableChannels) },
                    { key: "femaleVoice", value: femaleVoice },
                    { key: "powerBIReport", value: powerBIReport },
                    { key: "currentSubscription", value: JSON.stringify(currentSubscription) },
                    { key: "permission", value: JSON.stringify(permission) },
                    { key: "exclusiveServices", value: JSON.stringify(exclusiveServices) },
                    { key: "appId", value: JSON.stringify(appId) },
                    { key: "appName", value: JSON.stringify(appName) },
                    { key: "botSecret", value: botSecret },
                    { key: "PLNModule", value: JSON.stringify(PLNModule) },
                    { key: "reportServiceKey", value: reportServiceKey },
                ];
                //guardamos el la info dependiendo si el usuario quiere ser recordado o no
                if (remeberAuthCredentials) {
                    for (let index = 0; index < authStorage.length; index++) {
                        const keyToStore = authStorage[index].key;
                        const valueToStore = authStorage[index].value;
                        //nunca expiran los datos
                        localStorage.setItem(keyToStore, valueToStore);
                    }
                } else {
                    for (let index = 0; index < authStorage.length; index++) {
                        const keyToStore = authStorage[index].key;
                        const valueToStore = authStorage[index].value;
                        //expiran al cerrar el navegador
                        sessionStorage.setItem(keyToStore, valueToStore);
                    }
                }
                let rateLimitHasStored = await storeRateLimit(whatsappMessageService);
                let storageIsReady = true;
                this.setState({
                    readOnly: permission.readOnly,
                });
                resolve(storageIsReady && rateLimitHasStored);
            } catch (error) {
                console.error("error", error);
                reject(false);
            }
        });
    };

    updateSubscriptionData = async (authData, selectedSub) => {
        this.setState({
            loadingDialog: true,
            LoadingMessage: "Cambiando suscripción...",
        });
        const { storeLoginInformation } = this;
        let data = {
            subscriptionId: selectedSub.subscriptionId,
            email: authData.email,
        };
        let resSubsData = await authActions(data, "GetSubscription", "SubscriptionOperations");
        let subscriptionDataCode = resSubsData?.data?.response?.code
            ? parseInt(resSubsData.data.response.code)
            : 99;
        switch (subscriptionDataCode) {
            case 0:
                let subscriptionData = resSubsData.data.response.data;
                subscriptionData["currentSubscription"] = selectedSub;
                let updateSubscriptionData = true;
                let storageIsReady = await storeLoginInformation(
                    authData,
                    subscriptionData,
                    updateSubscriptionData
                );
                if (storageIsReady) {
                    window.location.reload();
                    break;
                }
                Swal.fire({
                    icon: "error",
                    title: "Hubo un error al conectarte al servicio de Suscripciones",
                    text: "Intenta de nuevo más tarde.",
                });
                this.setState({
                    loadingDialog: false,
                    LoadingMessage: "",
                });
                break;
            default:
                Swal.fire({
                    icon: "error",
                    title: "Hubo un error al conectarte al servicio de Suscripciones",
                    text: "Intenta de nuevo más tarde.",
                });
                this.setState({
                    loadingDialog: false,
                    LoadingMessage: "",
                });
                break;
        }
    };

    authenticateUser = async (loginForm) => {
        const { getFlowsData, getSuscriptionData, updateContextAttribute, getAuthTokenValidation } =
            this;
        let auth = await authActions(loginForm, "LogIn", "LogIn");
        let responseCode = auth?.data?.response?.code ? parseInt(auth.data.response.code) : 99; //Si no hay un código de respuesta lo mandamos al default
        let userLoggedIn;
        switch (responseCode) {
            case 0:
                let authData = auth.data.response.data;
                let storageIsReady = await getSuscriptionData(authData);
                if (!storageIsReady) {
                    updateContextAttribute("loadingDialog", false);
                    updateContextAttribute("LoadingMessage", "");
                    return (responseCode = 98);
                }
                let tokenExpired = await getAuthTokenValidation(authData);
                if (!tokenExpired) {
                    getFlowsData();
                }
                userLoggedIn = true;
                this.setState({
                    userLoggedIn: userLoggedIn,
                });
                return responseCode;
            case 1:
                userLoggedIn = false;
                updateContextAttribute("loadingDialog", false);
                updateContextAttribute("LoadingMessage", "");
                return responseCode;
            default:
                userLoggedIn = false;
                updateContextAttribute("loadingDialog", false);
                updateContextAttribute("LoadingMessage", "");
                return responseCode;
        }
    };

    getStorageData = (storageValuesToGet) => {
        //prguntamos si estamso en el local o session storage
        let storageData = {};
        if (!storageValuesToGet || storageValuesToGet.length === 0)
            throw new Error("Can't get empty values from storage");
        const remeberAuthCredentials = localStorage.getItem("remeberAuthCredentials");
        if (remeberAuthCredentials) {
            for (let index = 0; index < storageValuesToGet.length; index++) {
                const keyToGet = storageValuesToGet[index];
                let storageDataToSave = localStorage.getItem(keyToGet);
                //Si es un [object Object], intentamos parsearlo, si falla, entonces regresamos el valor en string
                try {
                    storageData[keyToGet] = JSON.parse(storageDataToSave);
                } catch (error) {
                    storageData[keyToGet] = storageDataToSave;
                }
            }
            //Regresamos la info pedida del local storage
            return storageData;
        }
        //Buscamos la info en el session storage
        for (let index = 0; index < storageValuesToGet.length; index++) {
            const keyToGet = storageValuesToGet[index];
            let storageDataToSave = sessionStorage.getItem(keyToGet);
            //Regresamos la info pedida del session storage
            try {
                storageData[keyToGet] = JSON.parse(storageDataToSave);
            } catch (error) {
                storageData[keyToGet] = storageDataToSave;
            }
        }
        return storageData;
    };

    clearContext = () => {
        this.setState({
            //State for section content
            processes: [],
            processesTemp: [],
            processesHasChanges: false,
            processesHasSavedChanges: false,
            messages: {},
            messagesTemp: {},
            messagesHasChanges: false,
            messagesHasSavedChanges: false,
            regEx: [],
            regExTemp: [],
            regExHasChanges: false,
            regExHasSavedChanges: false,
            catalogs: [],
            catalogsTemp: [],
            catalogsHasChanges: false,
            catalogsHasSavedChanges: false,
            settings: {},
            settingsTemp: {},
            settingsHasChanges: false,
            settingsHasSavedChanges: false,
            QnAmaker: {},
            QnAmakerTemp: {},
            QnAmakerHasChanges: false,
            QnAmakerHasSavedChanges: false,
            globalValues: [],
            newMultiMediaMessages: [],
            existingFileObjects: [],
            fileObjects: [],
            fileDataToSend: [],
            fileDataToSendHasSavedChanges: false,
            fileDataToDelete: [],
            fileDataToDeleteHasSavedChanges: false,
            clearMultiMediaFields: false,
            contextReady: false,
            //State for user feedback
            alert: {
                open: false,
                severity: "", //success, error, warning, info
                message: "",
            },
            loadingDialog: false,
            LoadingMessage: "",
            userLoggedIn: false,
            remeberAuthCredentials: false,
            countflowsInWhatsApp: 0,
            fileFAQToDeleteHasSavedChanges: false,
            fileFAQToSaveHasSavedChanges: false,
            fileDataFAQToSend: [],
            fileDataFAQToDelete: [],
            kbFileSources: [],
            kbFileSourcesOriginal: [],
        });
    };

    sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    logOutUser = async (tokenExpired) => {
        const { updateContextAttribute, clearContext, sleep } = this;
        //Preguntamos al usuario si quiere cerrar sesión
        if (!tokenExpired) {
            let result = await Swal.fire({
                text: "¿Deseas cerrar sesión?",
                showDenyButton: true,
                confirmButtonText: "Si",
                denyButtonText: `No`,
                confirmButtonColor: "#27315d",
                denyButtonColor: "#27315d",
            });
            //Cancelamos el cierre de sesión
            if (result.isDenied || result.isDismissed) {
                return;
            }
        }
        updateContextAttribute("userLoggedIn", false);
        //timeout para evitar que se intenten a acceder a datos eliminados del storage
        await sleep(10); // 10 milisegundos
        let remeberAuthCredentials = localStorage.getItem("remeberAuthCredentials");
        //Limpiamos el context al momento de salir
        clearContext();
        //Eliminamos el storage
        if (remeberAuthCredentials) {
            localStorage.clear();
        } else {
            sessionStorage.clear();
        }
    };

    sendFilesToBlob = async (
        fileDataToSendHasSavedChanges,
        fileDataToDeleteHasSavedChanges,
        fileDataToSend,
        fileDataToDelete,
        messagesCopy,
        newMultiMediaMessages
    ) => {
        const { updateContextAttribute } = this;
        const {
            fileFAQToSaveHasSavedChanges,
            fileFAQToDeleteHasSavedChanges,
            fileDataFAQToSend,
            fileDataFAQToDelete,
        } = this.state;
        this.setState({
            loadingDialog: true,
        });

        const stateData = {
            alert: {
                open: true,
                severity: "", //success, error, warning, info
                message: "",
            },
            loadingDialog: false,
            LoadingMessage: "",
        };

        //iteramos sobre el arreglo de archivos por mandar
        if (fileDataToSendHasSavedChanges) {
            for (let index = 0; index < fileDataToSend.length; index++) {
                try {
                    let indexOfFile = fileDataToSend[index].index;
                    //eliminamos el index para evitar mandar info innecesaria al WS
                    delete fileDataToSend[index].index;
                    let data = fileDataToSend[index];
                    let messageToAddFileURL = {};
                    //si el index of file es null, significa que este es mensaje nuevo
                    if (indexOfFile === null) {
                        messageToAddFileURL = newMultiMediaMessages[index];
                    } else {
                        messageToAddFileURL = messagesCopy.MULTIMEDIA_MESSAGES[indexOfFile];
                    }
                    this.setState({
                        LoadingMessage: `Cargando archivos multimedia... ${index + 1} de ${
                            fileDataToSend.length
                        }`,
                    });
                    //por cada objeto de archivos, esperamos a que recibamos el URL
                    let res = await blobStorage(data, "uploadFile");
                    let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; //-> si no hay codigo de respuesta lo mandamos al default
                    switch (responseCode) {
                        case 0:
                            let fileLink = res.data.imageURL;
                            //le agregamos el URL faltante al respectivo mensaje
                            messageToAddFileURL.fileLink = fileLink;
                            if (indexOfFile === null) {
                                messagesCopy.MULTIMEDIA_MESSAGES.push(messageToAddFileURL);
                            } else {
                                messagesCopy.MULTIMEDIA_MESSAGES[indexOfFile] = messageToAddFileURL;
                            }
                            break;
                        default:
                            console.error("error sendFiles 01 function 01");
                            stateData.alert.message =
                                "Ocurrió un error al guardar los archivos multimedia.";
                            stateData.alert.severity = "error";
                            this.setState(stateData);
                            return;
                    }
                } catch (error) {
                    console.error("error sendFiles 01", error);
                    stateData.alert.message =
                        "Ocurrió un error al guardar los archivos multimedia.";
                    stateData.alert.severity = "error";
                    this.setState(stateData);
                }
            }
            //Limpiamos el context
            this.setState({
                newMultiMediaMessages: [],
                fileDataToSend: [],
                fileObjects: [],
                clearMultiMediaFields: true,
                fileDataToSendHasSavedChanges: false,
            });
        }
        if (fileDataToDeleteHasSavedChanges) {
            for (let index = 0; index < fileDataToDelete.length; index++) {
                const data = fileDataToDelete[index];
                this.setState({
                    LoadingMessage: `Eliminando archivos multimedia... ${index + 1} de ${
                        fileDataToDelete.length
                    }`,
                });
                try {
                    let res = await blobStorage(data, "deleteFile");
                } catch (error) {
                    console.error("error sendFiles 02", error);
                    stateData.alert.message =
                        "Ocurrió un error al eliminar los archivos multimedia.";
                    stateData.alert.severity = "error";
                    this.setState(stateData);
                }
            }
            updateContextAttribute("fileDataToDelete", []);
            updateContextAttribute("fileDataToDeleteHasSavedChanges", false);
            updateContextAttribute("clearMultiMediaFields", true);
        }

        //Guardamos los mensajes
        let res = await intebotDataActions(
            messagesCopy,
            "updateConfigurationVariables&enviroment=Test",
            "BotConfigurationOperations"
        );
        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
        switch (responseCode) {
            case 0:
                updateContextAttribute("messagesHasChanges", false);
                updateContextAttribute("messagesHasSavedChanges", true);
                this.setState({
                    alert: {
                        open: true,
                        severity: "success",
                        message: "Mensajes Guardados Correctamente",
                    },
                });
                break;
            case 2:
                //El usuario no tiene permisos para realizar esta acción
                this.setState({
                    alert: {
                        open: true,
                        severity: "info", //success, error, warning, info
                        message:
                            "Tu suscripción ha caducado, por favor ponte en contacto con el soporte para actualizar tu plan.",
                    },
                });
                break;

            default:
                break;
        }
        updateContextAttribute("messages", messagesCopy);
        updateContextAttribute("messagesTemp", messagesCopy);

        /**
         * @description función para eliminar archivos del qna maker
         */
        if (fileFAQToDeleteHasSavedChanges) {
            let objToSend = {
                fileType: "file",
                files: fileDataFAQToDelete,
            };

            //  eliminar archivos de la lista de fuentes del QnA
            try {
                let res = await actionsQnAmaker(objToSend, "deleteFile");
                let responseCode = res?.data?.code ? parseInt(res.data.code) : 99;

                switch (responseCode) {
                    case 0:
                        break;
                    case 1:
                    case 2:
                        // caso 1: error al guardar
                        // caso 2: la base de conocimientos se actualizó recientemente
                        stateData.alert.message =
                            "Ocurrió un error al eliminar los archivos, vuelve a intentarlo más tarde.";
                        stateData.alert.severity = responseCode === 1 ? "error" : "warning";
                        this.setState(stateData);
                        return { status: "Failed" };

                    default:
                        stateData.alert.message =
                            "Ocurrió un error en el servicio, vuelve a intentarlo más tarde.";
                        stateData.alert.severity = "error";
                        this.setState(stateData);
                        return { status: "Failed" };
                }
            } catch (error) {
                stateData.alert.message =
                    "Ocurrió un error al eliminar los archivos de preguntas frecuentes.";
                stateData.alert.severity = "error";
                this.setState(stateData);
            }

            // eliminamos los archivos del blobStorage
            for (let index = 0; index < fileDataFAQToDelete.length; index++) {
                const fileName = fileDataFAQToDelete[index].fileName;
                this.setState({
                    LoadingMessage: `Eliminando archivos multimedia... ${index + 1} de ${
                        fileDataFAQToDelete.length
                    }`,
                });

                try {
                    if (fileName !== "" || fileName !== undefined) {
                        // eliminar archivos del blobStorage
                        let res = await blobStorage({ name: fileName }, "deleteFile");
                        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99;

                        switch (responseCode) {
                            case 0:
                                stateData.alert.message =
                                    "Los archivos se han eliminado correctamente.";
                                stateData.alert.severity = "success";

                                if (!fileFAQToSaveHasSavedChanges) {
                                    // si no se almacenaran archivos, return
                                    this.setState(stateData);
                                    return res.data;
                                }
                                break;

                            default:
                                stateData.alert.message =
                                    "Ocurrió un error al eliminar los archivos, intentalo más tarde.";
                                stateData.alert.severity = "error";
                                this.setState(stateData);
                                return { status: "Failed" };
                        }
                    }
                } catch (error) {
                    console.error("error sendFiles 03 function 02: ", error);
                    stateData.alert.message =
                        "Ocurrió un error al eliminar los archivos de preguntas frecuentes.";
                    stateData.alert.severity = "error";
                    this.setState(stateData);
                }
            }

            updateContextAttribute("fileDataFAQToDelete", []);
            updateContextAttribute("fileFAQToDeleteHasSavedChanges", false);
        }

        // subir archivos del qna maker al blob storage
        if (fileFAQToSaveHasSavedChanges) {
            let objToSend = {
                fileType: "file",
                files: [],
            };

            for (let index = 0; index < fileDataFAQToSend.length; index++) {
                this.setState({
                    LoadingMessage: `Cargando archivos... ${index + 1} de ${
                        fileDataFAQToSend.length
                    }`,
                });

                const data = fileDataFAQToSend[index];

                try {
                    if (data !== "" || data !== undefined) {
                        //por cada objeto de archivos, esperamos a que recibamos el URL
                        let res = await blobStorage(data, "uploadFile");
                        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99;

                        switch (responseCode) {
                            case 0:
                                //objeto que se envía para subir el archivo a como fuente en la base de conocimientos
                                let fileToSend = {
                                    fileName: data.name,
                                    fileUrl: res.data.imageURL,
                                };

                                objToSend.files.push(fileToSend);
                                break;

                            default:
                                stateData.alert.message =
                                    "Ocurrió un error en el servicio, vuelve a intentarlo más tarde.";
                                stateData.alert.severity = "error";
                                this.setState(stateData);
                                return { status: "Failed" };
                        }
                    }
                } catch (error) {
                    console.error("error sendFiles 04 function 01: ", error);
                    stateData.alert.message =
                        "Ocurrió un error al guardar los archivos de preguntas frecuentes.";
                    stateData.alert.severity = "error";
                    this.setState(stateData);
                }
            }

            if (objToSend.files.length > 0) {
                try {
                    // agregar documentos de la lista de fuentes del QnA
                    let res = await actionsQnAmaker(objToSend, "uploadFile");
                    let responseCode = res?.data?.code ? parseInt(res.data.code) : 99;

                    switch (responseCode) {
                        case 0:
                            stateData.alert.message = "Los archivos se han agregado correctamente.";
                            stateData.alert.severity = "success";
                            this.setState(stateData);
                            return res.data;

                        case 1:
                            // caso 1: error al guardar
                            stateData.alert.message =
                                "Ocurrió un error al guardar los archivos, vuelve a intentarlo más tarde.";
                            stateData.alert.severity = "error";
                            this.setState(stateData);
                            return { status: "Failed" };

                        case 2:
                            // caso 2: la base de conocimientos se actualizó recientemente y debe esperar
                            // eliminar archivos que no se cargaron del blobStorage
                            for (let i = 0; i < fileDataFAQToSend.length; i++) {
                                const data = fileDataFAQToSend[i];

                                let res = await blobStorage({ name: data.name }, "deleteFile");
                                // let responseCode = res?.data?.code ? parseInt(res.data.code) : 99;
                            }

                            stateData.alert.message =
                                "No fue posible guardar los archivos, vuelve a intentarlo más tarde.";
                            stateData.alert.severity = "warning";
                            this.setState(stateData);
                            return { status: "Failed" };

                        default:
                            stateData.alert.message =
                                "Ocurrió un error en el servicio, vuelve a intentarlo más tarde.";
                            stateData.alert.severity = "error";
                            this.setState(stateData);
                            break;
                    }
                } catch (error) {
                    console.error("error sendFiles 04 function 02: ", error);
                    stateData.alert.message =
                        "Ocurrió un error al guardar los archivos de preguntas frecuentes.";
                    stateData.alert.severity = "error";
                    this.setState(stateData);
                }
            }

            updateContextAttribute("fileDataFAQToSend", []);
            updateContextAttribute("fileFAQToSaveHasSavedChanges", false);
        }

        this.setState({
            loadingDialog: false,
            LoadingMessage: "",
        });
    };

    publishQnaKB = async () => {
        const { updateContextAttribute } = this;
        updateContextAttribute("loadingDialog", true);
        updateContextAttribute("LoadingMessage", "Publicando la base de conocimientos...");
        let res = await actionsQnAmaker(null, "publishKb");
        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; //si no hay respuesta correcta del serivico, mandamos el codigo a 99 para indicar el error
        updateContextAttribute("loadingDialog", false);
        updateContextAttribute("LoadingMessage", "");
        updateContextAttribute("faqHasSavedChanges", false);
        updateContextAttribute("publishQnA", false);

        switch (responseCode) {
            case 0:
                updateContextAttribute("alert", {
                    open: true,
                    severity: "success", //success, error, warning, info
                    message: "La base de conocimientos fue publicada exitosamente",
                });
                break;
            default:
                updateContextAttribute("alert", {
                    open: true,
                    severity: "error", //success, error, warning, info
                    message: "Hubo un error al publicar la base, intente más tarde.",
                });
                break;
        }
    };

    fetchKnowledgeBaseSources = async () => {
        const stateData = {
            alert: {
                open: true,
                severity: "", //success, error, warning, info
                message: "",
            },
            loadingDialog: false,
            LoadingMessage: "",
        };

        try {
            let res = await actionsQnAmaker(null, "getSourcesList");
            let responseCode = res?.data?.code ? parseInt(res.data.code) : 99;

            let sources = res.data.data.map((elem) => {
                if (!elem.displayName) {
                    elem.displayName = elem.source;
                }
                return elem;
            });

            switch (responseCode) {
                case 0:
                    this.updateContextAttribute("kbFileSources", sources);
                    this.updateContextAttribute("kbFileSourcesOriginal", sources);
                    delete stateData.alert;
                    this.setState(stateData);
                    return sources;

                default:
                    stateData.alert.message =
                        "Ocurrió un error al obtener los archivos de preguntas frecuentes, intentalo más tarde.";
                    stateData.alert.severity = "error";
                    this.setState(stateData);
                    break;
            }
        } catch (error) {
            console.error("error fetchKB", error);
            stateData.alert.message =
                "Ocurrió un error al obtener los archivos de preguntas frecuentes.";
            stateData.alert.severity = "error";
            this.setState(stateData);
        }
    };

    /**
     * @description función para actualizar la personalidad y voz del bot
     * @param {object} customizingChatbot
     * @param {boolean} hasChangesPersonality
     * @param {boolean} hasChangesVoice
     */
    updateCustomization = async (customizingChatbot, hasChangesPersonality, hasChangesVoice) => {
        const { sleep } = this;
        // Vars
        const THIS = this;

        let isUpdateDone = {
            voice: false, //false, true, code_error
            personality: false, //false, true, code_error
        };

        const stateData = {
            alert: {
                open: true,
                severity: "", //success, error, warning, info
                message: "",
            },
            loadingDialog: false,
            LoadingMessage: "",
        };

        // Activar loader
        this.setState({
            loadingDialog: true,
            LoadingMessage: "Estamos realizando la conexión con el servicio.",
        });

        // Comienza proceso para realizar los cambios
        let voiceChangeDone = await changeVoice();
        let personalityChangeDone = await changePersonality();

        /**
         * @name changeVoice
         * @description Función para actualizar tono de voz de InteBot
         */
        async function changeVoice() {
            try {
                if (hasChangesVoice) {
                    let update = { femaleVoice: customizingChatbot.femaleVoice };

                    let response = await actionsQnAmaker(update, "updateVoice");
                    let responseCode = response?.data?.code ?? "99";

                    switch (responseCode) {
                        case "00":
                            // Razón 0: Caso de éxito
                            if (!hasChangesPersonality) {
                                // Si no hay cambios en la personalidad, notificamos que se actualizó con éxito
                                stateData.alert.message = "El tono de voz se guardó correctamente.";
                                stateData.alert.severity = "success";
                                THIS.setState(stateData);
                            }
                            isUpdateDone.voice = true;
                            return true;

                        case "01":
                            // Razón 1: No se pudieron realizar los cambios
                            if (!hasChangesPersonality) {
                                // Si no hay cambios en la personalidad, notificamos que no se pudo actualizar la información
                                stateData.alert.message =
                                    "Ocurrió un error al guardar el tono de voz, vuelve a intentarlo más tarde.";
                                stateData.alert.severity = "error";
                                THIS.setState(stateData);
                            }
                            isUpdateDone.voice = true;
                            return true;

                        default:
                            // Cualquier otro error
                            if (!hasChangesPersonality) {
                                stateData.alert.message =
                                    "Ocurrió un error al guardar el tono de voz, vuelve a intentarlo más tarde.";
                                stateData.alert.severity = "error";
                                THIS.setState(stateData);
                                console.error("error updateCustomization 01 function 01");
                            }
                            return false;
                    }
                }
                return false;
            } catch (error) {
                console.error("error updateCustomization 01: ", error);
                stateData.alert.message = "Ocurrió un error al guardar el tono de voz.";
                stateData.alert.severity = "error";
                THIS.setState(stateData);
            }
        }

        /**
         * @name changePersonality
         * @description El proceso de eliminar sucede 1 vez y toma tiempo en aplicarse, por lo que,
         * al cargar una nueva personalidad, se toman 4 intentos con un intervalo de 800ms antes de enviar un error al usuario.
         */
        async function changePersonality() {
            try {
                if (hasChangesPersonality) {
                    // Vars
                    let objReturn = {
                        deleteDone: false,
                        updateDone: false,
                    };

                    if (customizingChatbot.lastPersonality) {
                        // Eliminamos el archivo actual de personalidad
                        let data = { personality: customizingChatbot.lastPersonality };
                        const personality = await deletePersonality(data);

                        if (personality) {
                            // si se borro correctamente regresar true, de lo contrario, regresar el status del error
                            objReturn.deleteDone = personality.deleted ? true : personality.status;
                        } else {
                            return false;
                        }
                    }

                    await sleep(2500);

                    if (customizingChatbot.personality) {
                        // Agregamos el archivo de personalidad a las fuentes
                        let data = { personality: customizingChatbot.personality };
                        let personality = await uploadPersonality(data);

                        if (personality) {
                            // La personalidad se actualizó correctamente regresa true, si no, regresar el status del erro
                            objReturn.updateDone = personality.updated ? true : personality.status;
                        } else {
                            return false;
                        }
                    }
                    return objReturn;
                }
                return false;
            } catch (error) {
                console.error("error updateCustomization 02: ", error);
                stateData.alert.message = "Ocurrió un error al cambiar la personalidad.";
                stateData.alert.severity = "error";
                THIS.setState(stateData);
            }
        }

        /**
         * @name deletePersonality
         * @description Función para solicitar la eliminación del archivo de personalidad.
         * @param {Object} personality Personalidad a eliminar.
         * @return { status: string, deleted: boolean } Objeto con el status de eliminación.
         */
        async function deletePersonality(personality) {
            // Vars
            const result = {
                status: "",
                deleted: false,
            };

            // Eliminar personalidad
            try {
                let res = await actionsQnAmaker(personality, "deletePersonality");
                let responseCode = res?.data?.code ? parseInt(res.data.code) : 99;

                switch (responseCode) {
                    case 0:
                        result.deleted = true;
                        result.status = true;
                        // Personalidad eliminada correctamente.
                        if (!customizingChatbot.personality) {
                            // Si no se cargará otra personalidad
                            if (!hasChangesVoice) {
                                // Si no hay cambios en la voz lanzar, notificar éxito
                                stateData.alert.message =
                                    "La personalidad se eliminó correctamente.";
                                stateData.alert.severity = "success";
                                THIS.setState(stateData);
                            }
                        }
                        break;

                    case 1:
                    case 2:
                        result.status = res ?? res.data.status;
                        // Razón 1: Actualizaciones simultaneas
                        // Razón 2: Actualizaciones simultaneas
                        if (!customizingChatbot.personality && !hasChangesVoice) {
                            // Si no hay cambios en la voz, notificar error
                            stateData.alert.message =
                                "No fue posible eliminar la personalidad, inténtalo más tarde.";
                            stateData.alert.severity = "info";
                            THIS.setState(stateData);
                        }

                        break;

                    default:
                        // Razón: Cualquier otro error
                        result.status = res ?? res.data.status;
                        if (!customizingChatbot.personality && !hasChangesVoice) {
                            // Si no hay cambios en la voz, notificar error
                            stateData.alert.message =
                                "Hubo un error al eliminar la personalidad, inténtalo más tarde.";
                            stateData.alert.severity = "error";
                            THIS.setState(stateData);
                        }
                        break;
                }

                return result;
            } catch (error) {
                console.error("error updateCustomization 02 function 02: ", error);
                stateData.alert.message = "Ocurrió un error al cambiar la personalidad.";
                stateData.alert.severity = "error";
                THIS.setState(stateData);
            }
        }

        /**
         * @name uploadPersonality
         * @description Función para solicitar añadir una nueva personalidad.
         * @param {Object} personality Personalidad por agregar.
         * @return { status: string, updated: boolean } Objeto con el status.
         */
        async function uploadPersonality(personality) {
            // Vars
            const result = {
                status: "",
                updated: false,
            };

            try {
                // Subir personalidad
                let res = await actionsQnAmaker(personality, "uploadPersonality");
                let responseCode = res?.data?.code ? parseInt(res.data.code) : 99;

                switch (responseCode) {
                    case 0:
                        // Si fue exitoso
                        result.updated = true;
                        result.status = true;

                        if (!hasChangesVoice) {
                            // Si no hay cambios en la voz, notificar éxito
                            stateData.alert.message = "La personalidad se guardó correctamente.";
                            stateData.alert.severity = "success";
                            THIS.setState(stateData);
                        }
                        break;

                    case "01":
                    case "02":
                        // Razón 1: Error al subir
                        // Razon 2: Actualizaciones simultaneas
                        result.status = res ?? res.data.status;
                        if (!hasChangesVoice) {
                            // Si no hay cambios en la voz, notificar error
                            stateData.alert.message =
                                "No fue posible guardar la personalidad, inténtalo más tarde.";
                            stateData.alert.severity = responseCode === "01" ? "warning" : "info";
                            THIS.setState(stateData);
                        }
                        break;

                    default:
                        // Razón 2: Cualquier otro error
                        result.status = res ?? res.data.status;
                        if (!hasChangesVoice) {
                            // Si no hay cambios en la voz, notificar error
                            stateData.alert.message =
                                "Hubo un error al guardar la personalidad, inténtalo más tarde.";
                            stateData.alert.severity = "error";
                            THIS.setState(stateData);
                        }
                        break;
                }
                return result;
            } catch (error) {
                console.error("error updateCustomization 02 function 03: ", error);
                stateData.alert.message = "Ocurrió un error al cambiar la personalidad.";
                stateData.alert.severity = "error";
                THIS.setState(stateData);
            }
        }

        // Revisar si se completo el cambio de personalidad
        if (customizingChatbot.lastPersonality && customizingChatbot.personality) {
            // si se elimino y cargó la personalidad, pasar status de ambos
            isUpdateDone.personality =
                personalityChangeDone.deleteDone && personalityChangeDone.updateDone;
        } else if (customizingChatbot.lastPersonality) {
            // si solo se elimino la personalidad, pasar status
            isUpdateDone.personality = personalityChangeDone.deleteDone;
        } else if (customizingChatbot.personality) {
            // si solo se cargó la personalidad, pasar status
            isUpdateDone.personality = personalityChangeDone.updateDone;
        }

        // Manejar notificación si se hizo cambio en personalidad y voz
        if (hasChangesVoice && hasChangesPersonality) {
            switch (true) {
                case isUpdateDone.voice && isUpdateDone.personality === true:
                    // si el cambio en personalidad y voz fue correcto, notificar éxito
                    stateData.alert.message =
                        "Los cambios de voz y personalidad se guardaron correctamente.";
                    stateData.alert.severity = "success";
                    this.setState(stateData);
                    return true;

                case isUpdateDone.voice !== true && isUpdateDone.personality === true:
                    // si el cambio en personalidad correcto, pero no en voz, notificar error
                    stateData.alert.message =
                        "Ocurrió un error al actualizar el tono de voz, inténtalo más tarde.";
                    stateData.alert.severity = "error";
                    this.setState(stateData);
                    console.error("error updateCustomization 03 function 01");
                    return false;

                case isUpdateDone.voice && isUpdateDone.personality !== true:
                    // si el cambio en voz correcto, pero no en personalidad, notificar error
                    stateData.alert.message =
                        isUpdateDone.personality === "01"
                            ? "Por ahora no fue posible cargar la personalidad, inténtalo más tarde."
                            : "Hubo un error al cargar la personalidad, inténtalo más tarde.";
                    stateData.alert.severity = isUpdateDone.personality === "01" ? "info" : "error";
                    this.setState(stateData);
                    console.error("error updateCustomization 03 function 02");
                    return false;

                default:
                    // si ningún cambio fue correcto, notificar error
                    stateData.alert.message =
                        "Ocurrió un error al guardar los cambios de voz y personalidad, inténtalo más tarde.";
                    stateData.alert.severity = "error";
                    this.setState(stateData);
                    console.error("error updateCustomization 03 function 03");
                    return false;
            }
        } else if (
            (hasChangesVoice && isUpdateDone.voice) ||
            (hasChangesPersonality && isUpdateDone.personality === true)
        ) {
            // si no hubo errores en la actualización de voz o personalidad
            return true;
        }
    };

    applyAllChanges = async () => {
        this.setState({
            loadingDialog: true,
            LoadingMessage: "Estamos realizando la conexión con el servicio.",
        });

        //Destructuraciones de variables del estado
        const {
            processesHasSavedChanges,
            messagesHasSavedChanges,
            regExHasSavedChanges,
            catalogsHasSavedChanges,
        } = this.state;
        const { getStorageData } = this;

        let responseCodes = [];
        let { token } = getStorageData(["token"]);

        if (processesHasSavedChanges) {
            let res = await intebotDataActions(
                { token },
                "updateConfigurationProcesses&enviroment=Prod",
                "ModelConfigurationOperations"
            );
            responseCodes.push(res?.data?.code ?? "99");
        }

        if (messagesHasSavedChanges) {
            let res = await intebotDataActions(
                { token },
                "updateConfigurationVariables&enviroment=Prod",
                "BotConfigurationOperations"
            );
            responseCodes.push(res?.data?.code ?? "99");
        }

        if (regExHasSavedChanges) {
            let res = await intebotDataActions(
                { token },
                "updateConfigurationDataTypes&enviroment=Prod",
                "ModelConfigurationOperations"
            );
            responseCodes.push(res?.data?.code ?? "99");
        }

        if (catalogsHasSavedChanges) {
            let res = await intebotDataActions(
                { token },
                "updateConfigurationCatalogs&enviroment=Prod",
                "ModelConfigurationOperations"
            );
            responseCodes.push(res?.data?.code ?? "99");
        }

        let isSuscriptionInactive = responseCodes.some((code) => code === "02");
        let allChangesApplied = responseCodes.every((code) => code === "00");

        if (isSuscriptionInactive) {
            this.setState({
                alert: {
                    open: true,
                    severity: "info", //success, error, warning, info
                    message:
                        "Tu suscripción ha caducado, por favor ponte en contacto con el soporte para actualizar tu plan.",
                },
                loadingDialog: false,
                LoadingMessage: "",
            });
            return;
        }

        if (allChangesApplied) {
            this.setState({
                alert: {
                    open: true,
                    severity: "success", //success, error, warning, info
                    message: "Los datos se guardaron correctamente",
                },
                loadingDialog: false,
                LoadingMessage: "",
                processesHasSavedChanges: false,
                messagesHasSavedChanges: false,
                regExHasSavedChanges: false,
                catalogsHasSavedChanges: false,
                settingsHasSavedChanges: false,
            });
        } else {
            this.setState({
                alert: {
                    open: true,
                    severity: "error", //success, error, warning, info
                    message:
                        "Ocurrió un error al guardar los datos, puede que la información se haya guardado parcialmente.",
                },
                loadingDialog: false,
                LoadingMessage: "",
            });
        }
    };

    checkUserPermission = async () => {
        const { getStorageData } = this;
        this.setState({
            loadingDialog: true,
            LoadingMessage: "Estamos realizando la conexión con el servicio.",
        });
        const { currentSubscription, permission } = getStorageData([
            "currentSubscription",
            "permission",
        ]);
        let data = {
            subscriptionId: currentSubscription.subscriptionId,
            role: permission.role,
        };
        let res = await authActions(data, "ValidateTokenRole", "LogIn");
        let responseCode = res?.data?.response?.code ? parseInt(res.data.response.code) : 99; //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
        this.setState({
            loadingDialog: false,
            LoadingMessage: "",
        });
        switch (responseCode) {
            case 0:
                //Token Valido && tiene permiso
                break;
            case 1:
                Swal.fire({
                    icon: "info",
                    title: "Tu cuenta no tiene permisos para editar esta suscripción",
                    text: "Si deseas hacer ediciones ponte en contacto con el administrador de la suscripción.",
                });
                break;
            case 2:
                Swal.fire({
                    icon: "info",
                    title: "Tu suscripción ha caducado.",
                    text: "Si deseas hacer ediciones ponte en contacto con el soporte al cliente para actualizar tu suscripción.",
                });
                break;
            default:
                Swal.fire({
                    icon: "error",
                    title: "No se pudo autenticar tu sesión.",
                    text: "Intenta de nuevo más tarde.",
                });
                break;
        }
        return responseCode;
    };

    //Cerrar alerta
    handleCloseAlert = (reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.setState({
            alert: {
                open: false,
                message: "",
            },
        });
    };

    handleDarkMode = (targetValue) => {
        this.setState({
            isDarkModeEnabled: targetValue,
        });
        Cookies.set("darkMode", targetValue, { expires: 365 });
        if (targetValue) {
            enableDarkMode(
                {
                    brightness: 120,
                    contrast: 100,
                    sepia: 10,
                },
                {
                    css: `
                /* Custom Dark Mode Fixes || Re-Writte .css file */
                
                    .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
                        background-color: #acbdd5;
                    }
                    
                    .MuiSwitch-track{
                        background-color: #54595a;
                    }

                    .agregar-icono-circle{
                        fill: #27315d !important;
                    }

                    .agregar-icono-cross {
                        fill: #ffffff !important;
                    }

                    .icon-primary-color{
                        fill: #acbdd5 !important;
                    }

                    .intent-example-main{
                       
                    }

                    
                    .intent-example-utility label {
                        border-bottom: 1px solid #acbdd5;
                    }

                    .MuiPaginationItem-page.Mui-selected{
                        background-color: #27315d !important;
                    }

                    .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover{
                        background-color: #27315d !important;
                    }
                    
                    .MuiListItem-button:hover{
                        background-color: #3d3f47 !important;
                    }

                    .MuiTreeItem-label:hover{
                        background-color: #3d3f47 !important;
                    }

                    .entity-label:hover{
                        background-color: #3d3f47 !important;
                    }
                    
                    .accordion-container{
                        background-color: #54595a !important;
                    }

                    .purple-tag-color{
                        background-color: rgb(138, 109, 239);
                    }

                    .green-tag-color {
                        background-color: rgb(34, 159, 89);
                    }
                    
                    .drawer-paper{
                        background-color: #222324 !important;
                    }
                `,
                }
            );
            return;
        }
        disableDarkMode();
    };

    render() {
        const { children } = this.props;
        const {
            processes,
            processesTemp,
            processesHasChanges,
            processesHasSavedChanges,
            messages,
            messagesTemp,
            messagesHasChanges,
            messagesHasSavedChanges,
            regEx,
            regExTemp,
            regExHasChanges,
            regExHasSavedChanges,
            catalogs,
            catalogsTemp,
            catalogsHasChanges,
            catalogsHasSavedChanges,
            settings,
            settingsTemp,
            settingsHasChanges,
            settingsHasSavedChanges,
            QnAmaker,
            QnAmakerTemp,
            QnAmakerHasChanges,
            QnAmakerHasSavedChanges,
            globalValues,
            fileDataToSend,
            fileDataToSendHasSavedChanges,
            // fileDataToDelete,
            fileDataToDeleteHasSavedChanges,
            newMultiMediaMessages,
            clearMultiMediaFields,
            userLoggedIn,
            remeberAuthCredentials,
            loadingDialog,
            LoadingMessage,
            countflowsInWhatsApp,
            contextReady,
            existingFileObjects,
            fileObjects,
            fileFAQToDeleteHasSavedChanges,
            fileFAQToSaveHasSavedChanges,
            fileDataFAQToSend,
            fileDataFAQToDelete,
            kbFileSources,
            kbFileSourcesOriginal,
            isDarkModeEnabled,
            faqHasSavedChanges,
            matchesUpMd,
            readOnly,
        } = this.state;

        const {
            updateContextAttribute,
            applyAllChanges,
            publishQnaKB,
            sendFilesToBlob,
            authenticateUser,
            logOutUser,
            storeRateLimit,
            handleDarkMode,
            getStorageData,
            sleep,
            fetchKnowledgeBaseSources,
            updateCustomization,
            storeLoginInformation,
            updateSubscriptionData,
            checkUserPermission,
        } = this;

        return (
            <React.Fragment>
                <CustomAlert
                    open={this.state.alert.open}
                    severity={this.state.alert.severity}
                    message={this.state.alert.message}
                    handleCloseAlert={() => this.handleCloseAlert()}
                />
                <LoadingDialog open={loadingDialog} LoadingMessage={LoadingMessage} />
                <AppContext.Provider
                    value={{
                        processes,
                        processesTemp,
                        processesHasChanges,
                        processesHasSavedChanges,
                        messages,
                        messagesTemp,
                        messagesHasChanges,
                        messagesHasSavedChanges,
                        regEx,
                        regExTemp,
                        regExHasChanges,
                        regExHasSavedChanges,
                        catalogs,
                        catalogsTemp,
                        catalogsHasChanges,
                        catalogsHasSavedChanges,
                        settings,
                        settingsTemp,
                        settingsHasChanges,
                        settingsHasSavedChanges,
                        QnAmaker,
                        QnAmakerTemp,
                        QnAmakerHasChanges,
                        QnAmakerHasSavedChanges,
                        updateContextAttribute,
                        applyAllChanges,
                        publishQnaKB,
                        sendFilesToBlob,
                        authenticateUser,
                        logOutUser,
                        storeRateLimit,
                        globalValues,
                        fileDataToSend,
                        fileDataToSendHasSavedChanges,
                        fileDataToDeleteHasSavedChanges,
                        newMultiMediaMessages,
                        clearMultiMediaFields,
                        userLoggedIn,
                        remeberAuthCredentials,
                        countflowsInWhatsApp,
                        getStorageData,
                        contextReady,
                        fileObjects,
                        existingFileObjects,
                        sleep,
                        fetchKnowledgeBaseSources,
                        fileFAQToDeleteHasSavedChanges,
                        fileFAQToSaveHasSavedChanges,
                        fileDataFAQToSend,
                        fileDataFAQToDelete,
                        kbFileSources,
                        kbFileSourcesOriginal,
                        updateCustomization,
                        handleDarkMode,
                        isDarkModeEnabled,
                        storeLoginInformation,
                        updateSubscriptionData,
                        checkUserPermission,
                        faqHasSavedChanges,
                        matchesUpMd,
                        readOnly,
                    }}
                >
                    {children}
                </AppContext.Provider>
            </React.Fragment>
        );
    }
}

export default AppContext;
